import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import { useLocation } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


function HsnSummaryDetails() {
  const location = useLocation();
  const [selectedReportBy, setSelectedReportBy] = useState("hsn/sac_wise");
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  
const toggleRow = (index) => {
  // Log the current state before and after toggling
  console.log("Before toggle:", expandedRows);

  setExpandedRows(prevState => {
    const newState = {
      ...prevState,
      [index]: !prevState[index], // Toggle the expansion for the row
    };
    
    // Log the new state after toggling
    console.log("After toggle:", newState);
    return newState;
  });
};


  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const handleExportExcel = () => {
    axios.post(`/hsn_summary_excel`,{report_id:reportData})
    .then((response)=>{
      const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const base64Data = response.data.file;
    if (base64Data) {
      downloadBase64File(contentType, base64Data, "HSNSummaryReport.xlsx");
    } else {
      console.error("Base64 data is missing in the response");
    }
    })
    .catch((error)=>{
      console.log(error)
    })
  };

  useEffect(() => {
    const item = location.state?.items || {};
    setReportData(item);
    console.log("Passed item:", item);
  }, [location]);

  useEffect(() => {
    if (reportData) {
      setLoading(true);
      axios
        .get(`/summary_fetched_from_hsn/${reportData}`)
        .then((response) => {
          setSummaryDetails(response.data);
          
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching API data:", err);
          setError("Failed to fetch data");
          setLoading(false);
        });
    }
  }, [reportData]);


  const numberFormat = (value) => {
       
    if (value === "(Blank)" || value === null) {
        return "₹ 0";
      }
  
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  };

  

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "14%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            HSN SUMMARY REPORT{" "}
            <SummarizeIcon style={{ marginBottom: "4px", color: "#043010" }} />
          </Typography>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            style={{ textAlign: "center", marginBottom: "2%" }}
          ></Typography>
        </div>
        <FormControl
          style={{ flex: "1", minWidth: 250, maxWidth: "20%" }}
          variant="outlined"
        >
          <InputLabel>Report By</InputLabel>
          <Select
            value={selectedReportBy}
            onChange={(e) => setSelectedReportBy(e.target.value)}
            label="Report By"
            fullWidth
            style={{ height: "44px" }}
          >
            <MenuItem value="hsn/sac_wise">HSN/SAC Wise Report-as per GSTR1</MenuItem>
            <MenuItem value="hsn/comparison">HSN Comparison Report-Books vs GSTR1</MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={handleExportExcel}
          variant="contained"
          color="primary"
          style={{ marginLeft: "2%" }}
          endIcon={<FileDownloadIcon />}
        >
          Export
        </Button>
      </div>
      <BoxWrapper
  boxTitle={selectedReportBy === "hsn/sac_wise" ? "HSN/SAC Wise Report-as per GSTR1" : "HSN Comparison Report-Books vs GSTR1"}
>
  {loading ? (
    <tr>
      <td
        colSpan={5}
        style={{
          color: "black",
          textAlign: "center",
          display:"flex",
          alignItems:"center",
          fontWeight: "bold",
          padding: "20px",
          fontSize: "16px",
        }}
      >
        Loading… Please wait
      </td>
    </tr>
  ) : error ? (
    <p>{error}</p>
  ) : !summaryDetails || !summaryDetails.data ? (
    <tr>
    <td colSpan={8} style={{ color: "red", textAlign: "center", fontSize: "16px" }}>
      No data found!
    </td>
  </tr>
  ) : selectedReportBy === "hsn/sac_wise" && summaryDetails.data.hsn_processed ? (
    <div className="responsive-table" style={{maxHeight:'500px'}}>
      <table className="custome-table">
        <thead>
          <tr>
            <th style={{fontSize: "15px"}}>HSN Code</th>
            <th style={{fontSize: "15px"}}>Rate</th>
            <th style={{fontSize: "15px"}}>UQC</th>
            <th style={{fontSize: "15px"}}>Quantity</th>
            <th style={{fontSize: "15px",whiteSpace:'nowrap'}}>TaxableValue</th>
            <th style={{fontSize: "15px"}}>SGST</th>
            <th style={{fontSize: "15px"}}>CGST</th>
            <th style={{fontSize: "15px"}}>IGST</th>
            <th style={{fontSize: "15px"}}>Cess</th>
            <th style={{fontSize: "15px"}}>FP</th>
          </tr>
        </thead>
        <tbody>
        {summaryDetails.data.hsn_processed.length === 0 ? (
  <tr>
    <td colSpan="10" style={{ textAlign: "center",color: "red" }}>Data Not Found</td>
  </tr>
) : (
  summaryDetails.data.hsn_processed.map((item, index) => (
    <tr key={index}>
      <td>{item.hsn_code}</td>
      <td>{item.rate}</td>
      <td>{item.uqc}</td>
      <td>{item.quantity}</td>
      <td>{numberFormat(item.taxable_value)}</td>
      <td>{numberFormat(item.sgst)}</td>
      <td>{numberFormat(item.cgst)}</td>
      <td>{numberFormat(item.igst)}</td>
      <td>{numberFormat(item.cess)}</td>
      <td>{item.fp}</td>
    </tr>
  ))
)}
        </tbody>
      </table>
    </div>
  ) : selectedReportBy === "hsn/comparison" && summaryDetails.data.hsn_comparison ? (
    <div className="responsive-table" style={{maxHeight:'500px'}}>
      <table className="summary-tab-single-year-table-css" style={{ tableLayout: 'auto', width: '120%' }}>
        <thead>
        <tr>
          <th colSpan={2}  style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#d6712d',color:'white'}} >FILING PERIOD</th>
            <th colSpan={7} style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#3065d1',color:'white'}} 
            >AS PER BOOKS</th>
          <th colSpan={10} style={{position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#1ed457',color:'white'}} 
          >AS PER GSTR1</th>
     
                      </tr>
          <tr>
            
            <th></th>
            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",  }}>Month</th>
            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",}}>HSN Code</th>
            <th style={{ fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap'}}> Quantity</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap',whiteSpace:'nowrap' }}>Taxable Value</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap'}}> CGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> SGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> IGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}> CESS</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>HSN Code</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>Quantity</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap',whiteSpace:'nowrap' }}>Taxable Value</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>CGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>SGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' }}>IGST</th>
            <th style={{  fontSize: "15px",  position: "sticky", top: '40px', zIndex: 9 , backgroundColor: "#fff",whiteSpace:'nowrap' , paddingRight: "4px"}}>CESS</th>
          </tr>
        </thead>
        <tbody>
    {summaryDetails.data?.hsn_comparison.map((item, index) => {
      const months = Object.keys(item).filter((key) => key !== "hsn_code");
      return (
        <React.Fragment key={index}>
          <tr>
            <td>
              <button onClick={() => toggleRow(index)}
                  style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
              >
                {expandedRows[index] ?
                               <ArrowDropUpIcon style={{ color: '#2dcc42' }} /> : 
                               < ArrowDropDownIcon style={{ color: '#2b3ec2' }} />}
              </button>
            </td>
            {months.map((month) => {
              const monthData = item[month]?.total_value || null;
              return (
                <React.Fragment key={month}>
                  <td>{month}</td>
                  {monthData ? (
                    <>
                    <td></td>
                      <td>{monthData.books_total_quantity}</td>
                      <td>{numberFormat(monthData.books_total_taxable_value)}</td>
                      <td>{numberFormat(monthData.books_total_cgst)}</td>
                      <td>{numberFormat(monthData.books_total_sgst)}</td>
                      <td>{numberFormat(monthData.books_total_igst)}</td>
                      <td>{numberFormat(monthData.books_total_cess)}</td>
                       <td></td>
                       <td>{monthData.processed_total_quantity}</td>
                       <td>{numberFormat(monthData.processed_total_taxable_value)}</td>
                      <td>{numberFormat(monthData.processed_total_cgst)}</td>
                      <td>{numberFormat(monthData.processed_total_sgst)}</td>
                      <td>{numberFormat(monthData.processed_total_igst)}</td>
                      <td>{numberFormat(monthData.processed_total_cess)}</td> 
                    </>
                  ) : (
                    <td colSpan={12} style={{ textAlign: 'center' }}>No Data</td>
                  )}
                </React.Fragment>
              );
            })}
          </tr>
          {expandedRows[index] && (
            <React.Fragment>
              {months.map((month) => {
                const dividedData = item[month]?.[`divided_value_${month.toLowerCase()}`] || [];
                return (
                  <React.Fragment key={month}>
                    {dividedData.length > 0 ? (
                      dividedData.map((data, subIndex) => (
                        <tr key={`sub-${subIndex}`}>
                          <td></td>
                          <td></td>
                          <td>{data.books_hsn_code}</td>
                          <td>{data.books_total_quantity}</td>
                          <td>{numberFormat(data.books_taxable_value)}</td>
                          <td>{numberFormat(data.books_total_cgst)}</td>
                          <td>{numberFormat(data.books_total_sgst)}</td>
                          <td>{numberFormat(data.books_total_igst)}</td>
                          <td>{numberFormat(data.books_total_cess)}</td>
                          <td>{data.hsn_code}</td>
                          <td>{data.quantity}</td>
                          <td>{numberFormat(data.taxable_value)}</td>
                          <td>{numberFormat(data.cgst)}</td>
                          <td>{numberFormat(data.sgst)}</td>
                          <td>{numberFormat(data.igst)}</td>
                          <td>{numberFormat(data.cess)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="16" style={{ textAlign: 'center' }}>
                          No Divided Data Available for {month}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    })}
  </tbody>






      </table>
    </div>
  ) : (
    <tr>
    <td colSpan={8} style={{ color: "red", textAlign: "center", fontSize: "16px" }}>
      No data found!
    </td>
  </tr>
  )}
</BoxWrapper>

    </Container>
  );
}

export default HsnSummaryDetails;


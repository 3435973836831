import React, { Component } from "react";
import "./home.css";
import axios from "../../axios-order";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "../../components/ui/card";
import Grid from "@material-ui/core/Grid";
import Appbar from "../../components/appBar/appBar";
import Footer from "../../components/footer/footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import usersIcon from "../../asserts/images/team.png";
import pendingIcon from "../../asserts/images/waitingList.png";
import entityIcon from "../../asserts/images/office-building.png";
// import PanTable from "./PanTable";
import WelcomeImage from "../../asserts/images/homeImage.jpg";
import BoxWrapper from "../../components/ui/boxWrapper";
import OutlinedInput from '@mui/material/OutlinedInput';

import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from '../../components/ui/inputField'
import SelectField from "../../components/ui/select";
import ValidatedDatePicker from "../../components/ui/DatePicker";
import TextField from "@material-ui/core/TextField";
import Groups2Icon from '@mui/icons-material/Groups2';


import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import SelectInput from "../../components/ui/basicSelectInput";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

let trigger = 1;

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});





class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      business_entity_data: [],
      adminOriginalData: [],
      adminData: [],
      champOriginalData: [],
      userOriginalData: [],
      userData: [],
      role: localStorage.getItem("role"),
      showDataloader: false,
      panWiseData: [],
      entityDetails: [],
      isGroupEntityClicked: false,
      addEntityData: {
        entity_name: "",
        entity_address: "",
        contact_person: "",
        contact_number: "",
        contact_email_id: "",
        pan: "",
        entities: [],
        is_uploads: ''
      },
      entityOptions: [],
      isLoadingPanValidation: false,
      panValidationError: "",
      message: null,
      variant: 'error'
    };


  }

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    if (this.state.role === "ADMIN") {
      let searchAdminData = this.state.adminOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0
          
        );
      });
      this.setState({ adminData: searchAdminData });
    } else if (this.state.role === "CHAMP") {
      let searchAdminData = this.state.champOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0 ||
          value.gst.toLowerCase().indexOf(query) >= 0 ||
          String(value.service_id).toLowerCase().indexOf(query) >= 0
        );
      });
      this.setState({
        List_of_Business_entities_with_Services_assigned: searchAdminData,
      });
    } else {
      let searchAdminData = this.state.userOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0 ||
          value.gst.toLowerCase().indexOf(query) >= 0 ||
          String(value.service_id).toLowerCase().indexOf(query) >= 0
        );
      });
      this.setState({
        userData: searchAdminData,
      });
    }
  };

  // ---End---

  toSetTheValue = (data) => {
    data?.map((v, i) => {
      this.setState(prevState => (
        {
          entityDetails: [
            ...prevState.entityDetails,
            {
              entity_name: v.entity_name,
              business_entity_id: v.business_entity_id,
              date_incorporation: v.date_incorporation
          }]
        }
      ))
    })
  }

  getResultApi = () => {
    if (this.state.role === "ADMIN") {
      axios
        .get("/get_admin_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            adminOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            adminData:
              res.data.List_of_Business_entities_with_Services_assigned,
            Total_number_of_active_business_users:
              res.data.Total_number_of_active_business_users,
            Total_number_of_active_Business_entities:
              res.data.Total_number_of_active_Business_entities,
            Total_number_of_pending_Business_entities:
              res.data.Total_number_of_pending_Business_entities,
            showDataloader: false,
          }, () => this.toSetTheValue(res.data.List_of_Business_entities_with_Services_assigned));

          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list', JSON.stringify(entityListData))

        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error(error?.response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    } else if (this.state.role === "CHAMP") {
      axios
        .get("/get_champ_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            Total_number_of_active_Business_users:
              res.data.Total_number_of_active_Business_users,
            Total_number_of_active_Business_entities:
              res.data.Total_number_of_active_Business_entities,
            List_of_Business_entities_with_Services_assigned:
              res.data.List_of_Business_entities_with_Services_assigned,
            champOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            showDataloader: false,
          });
          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list', JSON.stringify(entityListData))
        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error(error?.response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    } else {
      axios
        .get("/get_user_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            userData: res.data.List_of_Business_entities_with_Services_assigned,
            userOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            showDataloader: false,
          });
          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list', JSON.stringify(entityListData))
        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error("No Data Found!!", {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    }
  };

  componentDidMount = () => {
    toast.configure();
    this.setState({ showDataloader: true });

    this.getResultApi();
    
    if (window.location.pathname === "/home") {
      localStorage.removeItem("PanNumber");
      localStorage.removeItem("business_entity_id");
      localStorage.removeItem("entity_name");
      localStorage.removeItem("gst");
      localStorage.setItem("TriggerPanDashBoard", "false");
      localStorage.removeItem("Client_ID");
      localStorage.removeItem("Trigger_Effitrac/Tally_Button");
      localStorage.setItem("UploadTallyStatus", "false");
      localStorage.setItem("urlForTally", "");
      localStorage.removeItem("EmailId");
    }
    // if (process.env.REACT_APP_ENV === "local") {
    //   axios
    //     .get("/get_panwise_home_status")
    //     .then((res) => {
    //       this.setState({ panWiseData: res.data.pan_wise_details });
    //       // console.log(res);
    //     })
    //     .catch((err) => console.log(err));
    // }
  };
  // ---All Data[Entity]
  getListEntity = () => {
    toast.configure();
    axios
      .get("/get_list_entities")
      .then((res) => {
        this.setState({ business_entity_data: res.data.records });
      })
      .catch((error) => {
        this.setState({ showDataloader: false });
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };
  //---End--
  //   ---Work funtion---
  workAction = (business_entity_id, entity_name, gst, contactEmailId, is_uploads, entity_type, show_recon, branch_name) => {
    localStorage.setItem("business_entity_id", business_entity_id);
    localStorage.setItem("entity_name", entity_name);
    localStorage.setItem("gst", gst);
    localStorage.removeItem("PanNumber");
    localStorage.setItem("TriggerPanDashBoard", "false");
    localStorage.setItem("EmailId", contactEmailId);
    localStorage.setItem("is_uploads", is_uploads);
    localStorage.setItem("entity_type", entity_type);
    localStorage.setItem("show_recon", show_recon)
    localStorage.setItem("branch_name", branch_name)
    this.props.history.replace(`/landingcws`);
  };
  // ---End---

  // handlePushFunction = (pan) => {
  //   // alert(pan);
  //   localStorage.setItem("TriggerPanDashBoard", "true");
  //   localStorage.setItem("PanNumber", pan);
  //   localStorage.removeItem("business_entity_id");
  //   localStorage.removeItem("entity_name");
  //   localStorage.removeItem("gst");

  //   this.props.history.replace(`/landingcws`);
  // };

  //---Assigned business entity table---
  entityTableHeader() {
    const tableHeader = ["Entity Name", "GSTIN", "service", "Action"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  entityTableData() {
    if (this.state.role === "ADMIN") {
      if (this.state.adminData?.length >= 1) {
        return this.state.adminData.map((tableData, index) => {
          const { business_entity_id, entity_name, gst, service_id, contact_email_id, is_uploads, entity_type, show_recon, branch_name } =
            tableData; //destructuring

          return (
            <tr key={index}>
              <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

              <td>{gst}</td>

              <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${service_id[1] ? service_id[1] : ""
                }${service_id[2] ? "," : ""}${service_id[2] ? service_id[2] : ""
                }`}</td>

              <td>
                <Button
                  size="small"
                  onClick={() =>
                    this.workAction(business_entity_id, entity_name, gst, contact_email_id, is_uploads, entity_type, show_recon, branch_name)
                  }
                  style={{
                    color: "#ba4242",
                    fontFamily: "Dosis",
                    fontWeight: "550",
                  }}
                >
                  select
                </Button>
              </td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    } else if (this.state.role === "CHAMP") {
      if (this.state.List_of_Business_entities_with_Services_assigned) {
        return this.state.List_of_Business_entities_with_Services_assigned.map(
          (tableData, index) => {
            const { business_entity_id, entity_name, gst, contact_email_id, service_id, is_uploads, entity_type, show_recon, branch_name } =
              tableData; //destructuring
            return (
              <tr key={index}>
                <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

                <td>{gst}</td>

                <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${service_id[1] ? service_id[1] : ""
                  }${service_id[2] ? "," : ""}${service_id[2] ? service_id[2] : ""
                  }`}</td>

                <td>
                  <Button
                    size="small"
                    onClick={() =>
                      this.workAction(business_entity_id, entity_name, gst, contact_email_id, is_uploads, entity_type, show_recon, branch_name)
                    }
                    variant="outlined"
                    style={{
                      color: "#ba4242",
                      fontFamily: "Dosis",
                      fontWeight: "550",
                    }}
                  >
                    select
                  </Button>
                </td>
              </tr>
            );
          }
        );
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    } else {
      if (this.state.userData?.length > 1) {
        return this.state.userData.map((tableData, index) => {
          const { business_entity_id, entity_name, gst, contact_email_id, service_id, is_uploads, entity_type, show_recon, branch_name } =
            tableData; //destructuring
          return (
            <tr key={index}>
              <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

              <td>{gst}</td>

              <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${service_id[1] ? service_id[1] : ""
                }${service_id[2] ? "," : ""}${service_id[2] ? service_id[2] : ""
                }`}</td>

              <td>
                <Button
                  size="small"
                  onClick={() =>
                    this.workAction(business_entity_id, entity_name, gst, contact_email_id, is_uploads, entity_type, show_recon, branch_name)
                  }
                  variant="outlined"
                >
                  select
                </Button>
              </td>
            </tr>
          );
        });
      } else if (this.state.userData?.length === 1) {
        return this.workAction(
          this.state.userData[0].business_entity_id,
          this.state.userData[0].entity_name,
          this.state.userData[0].gst,
          this.state.userData[0].contact_email_id,
          this.state.userData[0].is_uploads,
          this.state.userData[0].entity_type,
          this.state.userData[0].show_recon,
          this.state.userData[0].branch_name
        );
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    }
  };
  //   ---End---
  pendingRequestHandler = () => {
    this.props.history.push({
      pathname: `/pendingRequest`,
      //   data: `/${business_entity_id}`,
    });
  };

  businessEntityMgmtHandler = () => {
    this.props.history.push({
      pathname: `/businessEntityMgmt`,
      //   data: `/${business_entity_id}`,
    });
  };

  activeUserHandler = () => {
    this.props.history.push({
      pathname: `/userManagement`,
      //   data: `/${business_entity_id}`,
    });
  };

  //  ---Card data---
  cardData = () => {
    let role = localStorage.getItem("role");
    if (role === "ADMIN") {
      return (
        <div className="CardSection">
          <Grid container spacing={3}>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_active_Business_entities
                    ? this.state.Total_number_of_active_Business_entities
                    : 0
                }
                card_content="Active Business Entities"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.businessEntityMgmtHandler}
                iconForCard={entityIcon}
              />
            </Grid>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_pending_Business_entities
                    ? this.state.Total_number_of_pending_Business_entities
                    : 0
                }
                card_content="Pending Business Entities "
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.pendingRequestHandler}
                iconForCard={pendingIcon}
              />
            </Grid>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_active_business_users
                    ? this.state.Total_number_of_active_business_users
                    : 0
                }
                card_content="Active Business Users"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.activeUserHandler}
                iconForCard={usersIcon}
              />
            </Grid>
            {/* <Grid item xs>
              <Card
                card_title="864"
                card_content="Total number of emapanelled Buyers"
              />
            </Grid> */}
          </Grid>
        </div>
      );
    } else if (role === "CHAMP") {
      return (
        <div className="CardSection">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                card_title={
                  this.state.Total_number_of_active_Business_entities
                    ? this.state.Total_number_of_active_Business_entities
                    : 0
                }
                card_content="Total number of active Business entities"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                iconForCard={entityIcon}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  TdsPayableButtonHandler = () => {
    this.props.history.push("/enviromentTdsPayable");
  };

  creditEvalutionButtonHandler = () => {
    this.props.history.push("/enviromentCreditEvalution");
  };

  handleGroupEntityClick = () => {
    this.setState({ isGroupEntityClicked: true });
  };

  handleModalClose = () => {
    this.setState({
      isGroupEntityClicked: false,
      addEntityData: {

        entity_name: '',
        entity_address: "",
        contact_person: "",
        contact_number: "",
        contact_email_id: "",
        pan: '',
        entities: [],
        is_uploads: ''
      },
      entityOptions: [],
      panValidationError: '',
    });
  };


  handleAddEntityInputChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      addEntityData: {
        ...prevState.addEntityData,
        [name]: value,
      },
    }));

    if (name === "pan" && /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value)) {
      this.setState({ isLoadingPanValidation: true });

      axios.post('/check_group_entity_eligibility', { pan: value })
        .then((response) => {
          const data = response.data;
          if (data.status) {
            console.log(data.entity_names)
            this.setState({ entityOptions: data.entity_names })
          } else {
            this.setState({ panValidationError: "Invalid PAN Number" });
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || error.message
          this.setState({ message: errorMessage, variant: 'error' });
          this.setState({ entityOptions: [] })
          this.setState({ entities: [] })
        })
        .finally(() => {
          this.setState({ isLoadingPanValidation: false });
        });
    }
  };


  handleDateChange = (date) => {
    this.setState((prevState) => ({
      addEntityData: {
        ...prevState.addEntityData,
        date_incorporation: date,
      },
    }));
  }

  addEntitySubmitHandler = (event) => {
    console.log('this.state.entities......', this.state.addEntityData.entities)
    if (this.state.addEntityData.entities.length <= 1) {
      this.setState({ message: 'You need to select more than one entity.' });
      return;
    }
    event.preventDefault();
    axios.post('/create_group_entity', { form_data: this.state.addEntityData })
      .then((res) => {
        this.setState({ message: res?.data?.message, variant: 'success' });
        this.handleModalClose();
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || error.message
        this.setState({ message: errorMessage, variant: 'error' });
      })

  }

  handleEntityChange = (event) => {
    console.log(event.target.value)
    this.setState({
      addEntityData: {
        ...this.state.addEntityData,
        entities: event.target.value,
      },
    });
  };

  handleCloseSnackbar = () => {
    this.setState({ message: null, variant: 'error' });
  };

  getEntityName = (id) => {
    const { entityOptions } = this.state;
    const entity = entityOptions.find(entity => entity.id === id);

    return entity ? entity.name : '';
  }




  render() {
    return (
      <>
        <div className="home">
          <div className="left">
            {/* <img
              src={WelcomeImage}
              width="100%"
              height="100%"
              arc="welcome_image"
            /> */}
          </div>

          <div className="right">
            <Appbar data={this.state.entityDetails}
              isGroupEntityClicked={this.state.isGroupEntityClicked}
              onGroupEntityClick={this.handleGroupEntityClick}
            />

            <div style={{ padding: "20px", marginTop: "60px", width: "100%" }}>
              {this.state.showDataloader === false ? (
                <>
                  <div style={{ marginTop: "10px" }}>{this.cardData()}</div>
                  {/* {process.env.REACT_APP_ENV === "local" &&
              this.state.panWiseData &&
              this.state.panWiseData.length >= 1 && (
                <div className={classes.businessEntityTableForPan}>
                  <div className={classes.businessEntityTable_header}>
                    PAN Wise entities
                  </div>

                  <PanTable
                    panData={this.state.panWiseData}
                    handleClickFunction={this.handlePushFunction}
                  />
                </div>
              )} */}

                  <BoxWrapper
                    boxTitle="Business Entities"
                    // search input
                    enableSearchInput={true}
                    searchInputPlaceholder="Search..."
                    searchInputOnchange={this.handleSearchInputChange}
                  >
                    <div className="responsive-table">
                      <table className="custome-table">
                        <thead>
                          <tr>{this.entityTableHeader()}</tr>
                        </thead>
                        <tbody>{this.entityTableData()}</tbody>
                      </table>
                    </div>
                  </BoxWrapper>
                  {this.state.role === "ADMIN" && (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="button-secondary"
                        onClick={this.TdsPayableButtonHandler}
                      >
                        TDS PAYABLE
                      </button>
                      <button
                        className="button-primary"
                        style={{ marginRight: "0px" }}
                        onClick={this.creditEvalutionButtonHandler}
                      >
                        CREDIT EVALUATION
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="fullPageloader">
                    <div>
                      {" "}
                      <CircularProgress size={40} thickness={3.5} />
                      <br></br>
                      Loading.Please wait...
                    </div>
                  </div>
                </>
              )}
            </div>
            <Dialog open={this.state.isGroupEntityClicked} onClose={this.handleModalClose}
              PaperProps={{
                style: {
                  width: '60%',
                  maxWidth: '90%',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#e3f2fd',
                }
              }}>
              {/* style={{ textAlign: 'center', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd', width:'100%' }} */}
              <DialogTitle>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000080', textAlign: 'center' }}>
                  CREATE GROUP ENTITY
                  <Groups2Icon style={{ marginBottom: '4px', color: '#000080' }} />
                </Typography>
              </DialogTitle>
              <DialogContent className="dialog-scrollable">

                <ValidatorForm
                  ref="form"
                  onSubmit={this.addEntitySubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="Entity Name"
                        name="entity_name"
                        value={this.state.addEntityData.entity_name}
                        requiredField="*"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="Entity Address"
                        name="entity_address"
                        value={this.state.addEntityData.entity_address}
                        requiredField="*"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="Contact Person"
                        name="contact_person"
                        value={this.state.addEntityData.contact_person}
                        requiredField="*"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="Contact Number"
                        name="contact_number"
                        value={this.state.addEntityData.contact_number}
                        requiredField="*"
                        validator={["required", "matchRegexp:^[0-9]{10}$"]}
                        errorMsg={["This field is required", "Invalid contact number"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="Contact Email Id"
                        name="contact_email_id"
                        value={this.state.addEntityData.contact_email_id}
                        requiredField="*"
                        validator={["required", "isEmail"]}
                        errorMsg={["This field is required", "Email is not valid"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                    </Grid>



                    <Grid item xs={12} sm={4}>
                      <InputField
                        inputType="text"
                        textLabel="PAN"
                        name="pan"
                        value={this.state.addEntityData.pan}
                        requiredField="*"
                        validator={["required", "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                        errorMsg={["This field is required", "Invalid PAN Number"]}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                      />
                      {this.state.isLoadingPanValidation && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginTop: '1%' }}>

                      <label variant="standard">Select Entities <span style={{ color: 'red' }}>*</span>:</label>

                      <Select
                        style={{ width: '100%', height: '40px' }}
                        multiple
                        value={this.state.addEntityData.entities}
                        onChange={this.handleEntityChange}
                        renderValue={(selected) => {
                          const displayedEntities = selected
                            .map((value) => this.getEntityName(value))
                            .join(', ');

                          return (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {displayedEntities}
                            </div>
                          );
                        }}
                      >
                        {this.state.entityOptions.length > 0 ? (
                          this.state.entityOptions.map((entity) => (
                            <MenuItem key={entity.id} value={entity.id}>
                              <Checkbox checked={this.state.addEntityData.entities.indexOf(entity.id) > -1} />
                              <ListItemText primary={entity.name} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <Typography variant="body2" style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                              No options available
                            </Typography>
                          </MenuItem>
                        )}
                      </Select>



                    </Grid>

                    <Grid item xs={12} sm={3} style={{ marginTop: '1.7%' }}>
                      <SelectInput
                        choice_id="id"
                        requiredField="*"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        choice_name="upload_reference"
                        SelectLabel="DATA UPLOADS"
                        name="is_uploads"
                        value={this.state.addEntityData.is_uploads ?? ""}
                        textOnchangeFunction={this.handleAddEntityInputChange}
                        choices={[
                          {
                            id: "YES",
                            upload_reference: "Yes",
                          },
                          {
                            id: "NO",
                            upload_reference: "No",
                          },
                        ]}
                      />
                    </Grid>




                  </Grid>
                  <div style={{ marginLeft: '75%', marginTop: '2%' }}>
                    <Button variant="outlined" color="secondary" onClick={this.handleModalClose} style={{ marginRight: '6%' }}>Cancel</Button>
                    <Button variant="contained" type="submit" color="primary" >Submit</Button>


                  </div>
                </ValidatorForm>
              </DialogContent>
            </Dialog>
            <Footer />
          </div>
          {this.state.message && (
            <Snackbar open={!!this.state.message}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackbar}
            >
              <MuiAlert onClose={this.handleCloseSnackbar} severity={this.state.variant}>
                {this.state.message}
              </MuiAlert>
            </Snackbar>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(Home);

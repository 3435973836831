import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./GSTReconciliation.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";
import InputField from "../../../../components/ui/inputField";
import BasicInputField from "../../../../components/ui/basicInput";

import Modal from "react-bootstrap/Modal";
import SelectField from "../../../../components/ui/basicSelectInput";
import RefreshIcon from "@mui/icons-material/Refresh";
import CryptoJS from "crypto-js";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

class GSTReconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: "Botmantra",
      file_name: "",
      historyTableOriginalData: null,
      historyTable: null,
      loading: false,
      modalShow: false,
      customRoundOff: null,
      threshold_value: 0,
      yearArr: [],
      errorMessage: false,
      fromMonthArr: [
        { id: 4, value: "APR" },
        { id: 5, value: "MAY" },
        { id: 6, value: "JUN" },
        { id: 7, value: "JUL" },
        { id: 8, value: "AUG" },
        { id: 9, value: "SEPT" },
        { id: 10, value: "OCT" },
        { id: 11, value: "NOV" },
        { id: 12, value: "DEC" },
        { id: 1, value: "JAN" },
        { id: 2, value: "FEB" },
        { id: 3, value: "MAR" },
      ],
      toMonthArr: [
        { id: 4, value: "APR" },
        { id: 5, value: "MAY" },
        { id: 6, value: "JUN" },
        { id: 7, value: "JUL" },
        { id: 8, value: "AUG" },
        { id: 9, value: "SEPT" },
        { id: 10, value: "OCT" },
        { id: 11, value: "NOV" },
        { id: 12, value: "DEC" },
        { id: 1, value: "JAN" },
        { id: 2, value: "FEB" },
        { id: 3, value: "MAR" },
      ],
      year: null,
      from_month: null,
      to_month: null,
      roundOff: 0,
//SnackBar

      snackbarOpen: false,
      snackbarMessage: "",
      snackbarSeverity: "success",
    };
  }

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchAdminData = this.state.historyTableOriginalData.filter(
      (value) => {
        const query = searchInput.toLowerCase();
        return (
          value.upload_date.toLowerCase().indexOf(query) >= 0 ||
          value.status.toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ historyTable: searchAdminData });
  };

  // ---End---

  componentDidMount = () => {
    axios
      .get(`/arc/get_gst_years/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else if (res.data.data.length === 0) {
          this.setState({ errorMessage: true });
        } else {
          let yearArr = [];
          res.data.data.map((v, i) => {
            if(v.filing_year != null)
            return yearArr.push({
              id: String(v.filing_year),
              value: `${parseInt(v.filing_year)}-${
                parseInt(v.filing_year) + 1
              }`,
            });
          });
          this.setState({
            yearArr: yearArr,
          });
        }
      });
    this.getReconDetails();
  };



  // Function to open Snackbar
  handleSnackbarOpen = (message, severity) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  // Function to close Snackbar
  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarSeverity: "success",
    });
  };

  // File uploader for [Form 26AS]
  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ GSTJsonfFile: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  getReconDetails = () => {
    axios
      .get(
        `/arc/get_gst_reconciliation/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          this.setState({
            historyTable: res.data.record,
            historyTableOriginalData: res.data.record,
          });
        }
      });
  };

  // submit for file upload form
  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    // toast.configure();

    axios
      .post(`/arc/gst_reconciliation`, {
        gstin: localStorage.getItem("gst"),
        threshold_value: this.state.threshold_value,
        fromMonth: this.state.from_month,
        toMonth: this.state.to_month,
        fiscalYear: this.state.year,
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
this.handleSnackbarOpen("File processing failed.", "error");
        } else {
          
          this.getReconDetails();
          this.setState({ loading: false });

        }
      })
      .catch((error) => {
        
        this.getReconDetails();
        this.setState({ loading: false, file_name: "" });
this.handleSnackbarOpen(
          `Error: ${error?.response?.data}`,
          "error"
        );
      
      });
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  // ---TABLE LOGIC---
  //---History table logic---
  historyTableHeader = () => {
    const tableHeader = [
      "Fiscal Year",
      "From Month",
      "To Month",
      "Threshold",
      "date & time",
      "status",
      "report",
    ];
    // let header = Object.keys(tableHeader)
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  historyTableData = () => {
    const updatedShowDetails = (id) => {
      const encrypt = (text) => {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
      };
      this.props.history.push({
        pathname: `/gstReconUpdatedTabDetails/${encrypt(id)}`,
        data: id, // your data array of objects
      });
    };

    const showErrorDetails = (id) => {
      axios
        .get(`/arc/get_gst_json/${id}`)
        .then((res) => {
          
          // console.log(res.data.record.json_data.exception);
          const exceptionMessage = res.data.record.json_data.exception;
        this.handleSnackbarOpen(exceptionMessage, "error");
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (this.state.historyTable.length >= 1) {
      return this.state.historyTable.map((tableData, index) => {
        const {
          fiscal_year,
          from_month,
          to_month,
          threshold_value,
          upload_date,
          id,
          status,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{`${fiscal_year}-${parseInt(fiscal_year) + 1}`}</td>
            <td>{from_month}</td>
            <td>{to_month}</td>
            <td>{threshold_value}</td>
            <td>{upload_date}</td>
            <td style={{ textTransform: "capitalize" }}>{status}</td>
            <td style={{ cursor: "pointer" }}>
              {status === "Fail" ? (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  onClick={() => showErrorDetails(id)}
                />
              ) : status === "Initiated" ? (
                <>
                  <VisibilityOutlinedIcon color="disabled" />
                </>
              ) : status === "Pass" ? (
                <VisibilityOutlinedIcon
                  className="action-icon-button showIcon"
                  onClick={() => updatedShowDetails(id)}
                />
              ) : (
                <VisibilityOutlinedIcon color="disabled" />
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={7}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  newReconModalOpen = () => {
    this.setState({
      modalShow: true,
    });
  };

  newReconModalClose = () => {
    this.setState({
      modalShow: false,
      threshold_value: "",
      roundOffValue: 0,
    });
  };

  roundOffInputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 11 && name === "threshold_value") {
      this.setState({
        customRoundOff: "",
        roundOffValue: value,
      });
    } else if (name === "customRoundOff") {
      if (
        String(value).split(".")[1] &&
        String(String(value).split(".")[1]).length > 2
      ) {
        return false;
      }

      this.setState({ threshold_value: value, customRoundOff: value });
    } else {
      this.setState({
        threshold_value: value,
        roundOffValue: value,
        customRoundOff: null,
      });
    }
  };

  inputHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "from_month") {
      let toMonthArr = this.state.fromMonthArr;

      this.setState((prevState) => ({
        ...prevState,
        toMonthArr: toMonthArr.filter(
          (month_value) =>
            (parseInt(value) < 4 &&
              parseInt(month_value.id) >= parseInt(value) &&
              parseInt(month_value.id) < 4) ||
            (parseInt(value) >= 4 &&
              parseInt(month_value.id) >= parseInt(value)) ||
            (parseInt(value) >= 4 && parseInt(month_value.id) < 4)
        ),
      }));
    }
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  refreshUploadHistoryTable = () => {
    this.getReconDetails();
  };

  render() {
    return (
      <React.Fragment>
        <div>
          {this.state.loading ? (
            <button className="button-outlined-primary" disabled>
              <CircularProgress size={22} />
              <span
                style={{
                  textTransform: "capitalize",
                  paddingLeft: "10px",
                }}
              >
                Uploading...
              </span>
            </button>
          ) : (
            <button
              className="button-outlined-primary"
              onClick={this.newReconModalOpen}
            >
              New GSTR-1 Reconciliation
            </button>
          )}

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            onHide={this.newReconModalClose}
            dialogClassName="modal-50w"
          >
            <Modal.Header closeButton className="modal-header">
              <Modal.Title className="modal-title">
                New GSTR-1 Reconciliation
              </Modal.Title>
            </Modal.Header>
            {this.state.errorMessage && (
              <div style={{ color: "red", paddingLeft: "20px" }}>
                There is no data in GSTR1 Repository{" "}
              </div>
            )}
            <ValidatorForm
              ref="form"
              onSubmit={this.onSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <Modal.Body>
                {/* Form 26AS upload [UPLOADER(File input)] */}

                <div
                  style={{
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <p style={{ fontSize: "16px" }}>
                        Select Financial Year
                        <span style={{ color: "red" }}>*</span>:
                      </p>
                    </Grid>
                    <Grid item sm={9}>
                      <SelectField
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        choice_id="id"
                        disabled={this.state.errorMessage}
                        choice_name="value"
                        name="year"
                        value={this.state.year ?? ""}
                        choices={this.state.yearArr}
                        textOnchangeFunction={this.inputHandler}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div
                  style={{
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <p style={{ fontSize: "16px" }}>
                        From Month<span style={{ color: "red" }}>*</span>:
                      </p>
                    </Grid>
                    <Grid item sm={3}>
                      <SelectField
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        choice_id="id"
                        choice_name="value"
                        disabled={this.state.errorMessage}
                        name="from_month"
                        value={this.state.from_month ?? ""}
                        choices={this.state.fromMonthArr}
                        textOnchangeFunction={this.inputHandler}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <p style={{ fontSize: "16px" }}>
                        To Month<span style={{ color: "red" }}>*</span>:
                      </p>
                    </Grid>
                    <Grid item sm={3}>
                      <SelectField
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        choice_id="id"
                        choice_name="value"
                        name="to_month"
                        disabled={this.state.errorMessage}
                        value={this.state.to_month ?? ""}
                        choices={this.state.toMonthArr}
                        textOnchangeFunction={this.inputHandler}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div
                  style={{
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <p style={{ fontSize: "16px" }}>Threshold value:</p>
                    </Grid>
                    <Grid item sm={9}>
                      <SelectField
                        choice_id="id"
                        choice_name="roundOff"
                        disabled={this.state.errorMessage}
                        name="threshold_value"
                        value={this.state.roundOffValue ?? 0}
                        choices={[
                          { id: 0, roundOff: "No Threshold Value" },
                          { id: 1, roundOff: "Upto 1" },
                          { id: 5, roundOff: "Upto 5" },
                          { id: 10, roundOff: "Upto 10" },
                          { id: 11, roundOff: "Custom" },
                        ]}
                        textOnchangeFunction={this.roundOffInputHandler}
                      />
                    </Grid>
                  </Grid>
                </div>

                {this.state.customRoundOff !== null ? (
                  <>
                    <div
                      style={{
                        padding: "0px 20px 20px 20px",
                        marginTop: "30px",
                        marginBottom: "10px",
                        boxShadow:
                          "0px 0px 3px 0px #fff , 0px 1px 1px 1px #ddd",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item sm={3}>
                          <p style={{ marginTop: "30px", fontSize: "16px" }}>
                            Custom Round off
                            <span style={{ color: "red" }}>*</span> :
                          </p>
                        </Grid>
                        <Grid item sm={9}>
                          <BasicInputField
                            inputType="number"
                            name="customRoundOff"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            value={this.state.customRoundOff ?? ""}
                            textOnchangeFunction={this.roundOffInputHandler}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="button-primary"
                  type="submit"
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </button>
                <button
                  className="button-outlined-secondary"
                  type="button"
                  onClick={this.newReconModalClose}
                >
                  Close
                </button>
              </Modal.Footer>
            </ValidatorForm>
          </Modal>
        </div>

        {/* HISTORY TABLE */}
        <BoxWrapper
          boxTitle="GSTR-1 Reconciliation"
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={this.handleSearchInputChange}
          enableAddButton={true}
          addButtonLabel="Refresh"
          addButtonHandlerFn={this.refreshUploadHistoryTable}
        >
          {this.state.historyTableOriginalData ? (
            <div className="responsive-table">
              <table className="custome-table">
                <tbody>
                  <tr>{this.historyTableHeader()}</tr>
                  {this.historyTableData()}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
              <span
                style={{
                  justifyContent: "center",
                  position: "fixed",
                  top: "55%",
                }}
              >
                Loading...please wait
              </span>
            </div>
          )}
        </BoxWrapper>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000} // Adjust as needed
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={this.state.snackbarSeverity} // Use the state variable to determine severity
          >
            {this.state.snackbarMessage}
          </MuiAlert>
        </Snackbar>

        {/*---END---*/}
      </React.Fragment>
    );
  }
}
export default GSTReconciliation;

import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./form26as.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";
import InputField from "../../../../components/ui/inputField";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import GetAppIcon from "@material-ui/icons/GetApp";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";

class form26asRepo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: "Botmantra",
      file_name: "",
      historyTableOriginalData: [],
      historyTable: [],
      loading: false,
      modalShow: false,
      customRoundOff: null,
      threshold_value: 0,
      roundOff: 0,
      loaderForTable: false,
      showUpload: false,
    };
  }

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    let searchAdminData = this.state.historyTableOriginalData.filter(
      (value) => {
        const query = searchInput.toLowerCase();
        return (
          String(value.document_name).toLowerCase().indexOf(query) >= 0 ||
          String(value.financial_year).toLowerCase().indexOf(query) >= 0 ||
          String(value.uploaded_time)
            .replace(/  +/g, " ")
            .toLowerCase()
            .indexOf(query) >= 0 ||
          String(value.file_updated).toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ historyTable: searchAdminData });
  };

  componentDidMount = () => {
    this.getDetails();
  };

  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ GSTJsonfFile: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  downloadPDF = (base64Data, fileName) => {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadFile = (document_id, document_name) => {
    axios
      .get(
        `/form_26/download_document/${localStorage.getItem(
          "business_entity_id"
        )}/${document_id}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          this.downloadPDF(res.data.records, document_name);
        }
      });
  };

  getDetails = () => {
    this.setState({ loaderForTable: true });
    axios
      .get(`/form26/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (typeof res.data === "undefined") {
          this.setState({ loaderForTable: false });
        } else {
          this.setState({
            loaderForTable: false,
            historyTable: res.data.records,
            historyTableOriginalData: res.data.records,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loaderForTable: false,
          historyTable: err.response.data.records,
          historyTableOriginalData: err.response.data.records,
        });
      });
  };

  // submit for file upload form
  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    toast.configure();

    axios
      .post(`/form26/tdsrepository`, {
        file: `${this.state.GSTJsonfFile}`,
        document_name: this.state.file_name,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
        } else {
          toast.success("File processing completed.", {
            autoClose: 5000,
            progress: 0,
          });
          this.getDetails();
          this.setState({ loading: false, file_name: "" });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 5000,
          progress: 0,
        });
        this.getDetails();
        this.setState({ loading: false, file_name: "" });
      });
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  //---History table logic---
  historyTableHeader = () => {
    const tableHeader = [
      "Document Name",
      "Financial Year",
      "Uploaded Time",
      "File Updated",
      "Actions",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  historyTableData = () => {
    if (this.state.loaderForTable) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={5}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (this.state.historyTable.length >= 1) {
      return this.state.historyTable.map((tableData, index) => {
        const {
          document_id,
          document_name,
          financial_year,
          uploaded_time,
          file_updated,
        } = tableData;
        return (
          <tr key={index}>
            <td>{document_name}</td>
            <td style={{ textTransform: "capitalize" }}>{financial_year}</td>
            <td style={{ textTransform: "capitalize" }}>{uploaded_time}</td>
            <td style={{ textTransform: "capitalize" }}>{file_updated}</td>
            <td>
              <GetAppIcon
                className="action-icon-button download"
                onClick={() => this.downloadFile(document_id, document_name)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={5}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  newReconModalOpen = () => {
    this.setState({
      modalShow: true,
    });
  };

  newReconModalClose = () => {
    this.setState({
      modalShow: false,
      threshold_value: "",
      roundOffValue: 0,
    });
  };

  roundOffInputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 11 && name === "threshold_value") {
      this.setState({
        customRoundOff: "",
        roundOffValue: value,
      });
    } else if (name === "customRoundOff") {
      if (
        String(value).split(".")[1] &&
        String(String(value).split(".")[1]).length > 2
      ) {
        return false;
      }

      this.setState({ threshold_value: value, customRoundOff: value });
    } else {
      this.setState({
        threshold_value: value,
        roundOffValue: value,
        customRoundOff: null,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ToggleSwitch
          label="Upload 26AS-TDS"
          onChange={() => this.setState({ showUpload: !this.state.showUpload })}
        />
        {this.state.showUpload && (
          <BoxWrapper boxTitle="26AS-TDS">
            <ValidatorForm
              ref="form"
              onSubmit={this.onSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} md={1}>
                    <label
                      className="button-primary"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Browse
                      <input
                        id="file_value"
                        name="GSTJsonfFile"
                        accept="application/pdf, text/plain"
                        type="file"
                        hidden
                        onChange={this.fileUploader}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      placeholder="Choose file"
                      value={this.state.file_name}
                      name="GSTJson_file_uploader"
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={2}>
                    {" "}
                    {this.state.loading ? (
                      "  Uploading..."
                    ) : (
                      <button className="button-primary" type="submit">
                        Submit
                      </button>
                    )}
                    <button
                      type="button"
                      className="button-outlined-secondary"
                      onClick={this.newReconModalClose}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item sm={1}></Grid>
                </Grid>
              </div>
            </ValidatorForm>
          </BoxWrapper>
        )}

        {/* HISTORY TABLE */}
        <BoxWrapper
          boxTitle="History"
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={this.handleSearchInputChange}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.historyTableHeader()}</tr>
                {this.historyTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      </React.Fragment>
    );
  }
}
export default form26asRepo;

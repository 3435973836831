import React, { useState, useEffect, useMemo, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import IconButton from "@mui/material/IconButton";
import axios from "../../../../axios-order";
import classes from "./assignServices.module.css";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";

import { ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "../../../../components/ui/DatePicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@material-ui/core/TextField";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

// import Data from "./data";

const ServicesTable = (props) => {
  const form = useRef();
  const [dataForTable, setDataForTable] = useState([]);
  const [isRowUpdated, setIsRowUpdated] = useState(false);
  const [isLoadding, setIsLoading] = useState(false);
  const [errorForToDate, setErrorForToDate] = useState([]);

  const getService = () => {
    axios
      .get(
        `/cws_services/get_entity_services/${localStorage.getItem(
          "business_mgmt_entity_id"
        )}`
      )
      .then((res) => {
        setDataForTable(res.data.services_list);
      });
  };

  useEffect(() => {
    getService();
  }, []);

  const tableHeader = () => {
    const header = [
      "Details",
      "Category",
      "Service",
      "Total Number of Sub-Service",
    ];
    return header.map((v) => {
      return <th>{v}</th>;
    });
  };

  const rowData = (item) => {
    let finalData = dataForTable.map((v, i) => {
      if (v.service === item.service) {
        v.sub_service = item.sub_service;
      }
    });
    setIsRowUpdated(!isRowUpdated);
  };

  useMemo(() => {
    setDataForTable(dataForTable);
  }, [isRowUpdated]);

  // service handler fn
  const assignServicesHandler = () => {
    let todateError = [];
    let fromDateError = [];
    dataForTable.map((item, i) => {
      dataForTable[i].sub_service.map((val, i) => {
        var from = new Date(val.from_date);
        var to = new Date(val.to_date);
        if (from > to) {
          todateError.push(val);
          // console.log(val);
        }
      });
    });
    setErrorForToDate(todateError);

    setIsLoading(true);

    if (todateError.length) {
      setIsLoading(false);
      toast.error(
        `To Date is less than From Date ${todateError.map(
          (item) => item.name
        )}`,
        {
          autoClose: 3000,
          progress: 0,
        }
      );
      return false;
    }

    axios
      .post(`/cws_services/post_entity_services`, {
        business_entity_id: localStorage.getItem("business_mgmt_entity_id"),
        services: dataForTable,
      })
      .then((res) => {
        setIsLoading(false);
        toast.success("Service successfully assigned.", {
          autoClose: 3000,
          progress: 0,
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(
          "some error occurred,Please contact system administrator!",
          {
            autoClose: 3000,
            progress: 0,
          }
        );
      });
  };
  console.log(errorForToDate);
  return (
    <>
      <ValidatorForm
        ref={form}
        onSubmit={assignServicesHandler}
        onError={(errors) => console.log(errors)}
      >
        <div style={{ margin: "30px" }}>
          {/* SERVICE TABLE */}
          <table className={classes.customeTable}>
            <thead>
              <tr>{tableHeader()}</tr>
            </thead>
            <tbody>
              {dataForTable.map((v) => (
                <Row data={v} setRowWiseData={rowData} />
              ))}

              {dataForTable.length === 0 && (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    Loading.Please wait...
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* SUBMIT BUTTON */}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <button
              style={{ marginRight: "0px" }}
              className="button-secondary mt-20"
              disabled={isLoadding}
            >
              {isLoadding && (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              )}
              {!isLoadding && "Submit"}
            </button>
          </div>
        </div>
      </ValidatorForm>
    </>
  );
};

function Row(props) {
  const { data, setRowWiseData } = props;
  const [open, setOpen] = useState(false);
  const [isStatusUpadted, setIsStatusUpdated] = useState(false);
  const [isReminderUpadted, setIsReminderUpdated] = useState(false);
  const [isFromDateUpadted, setIsFromDateUpdated] = useState(false);
  const [isToDateUpadted, setIsToDateUpdated] = useState(false);

  useMemo(() => {
    setRowWiseData(data);
  }, [isStatusUpadted, isReminderUpadted, isFromDateUpadted, isToDateUpadted]);

  const statusHandler = (e, item) => {
    data.sub_service.map((service) => {
      if (service.name === item.name) {
        if (e.target.checked === true) {
          service.status = true;
          service.reminder = true;
        } else {
          service.status = false;
          service.reminder = false;
        }
      }
    });
    setIsStatusUpdated(!isStatusUpadted);
  };

  const reminderHandler = (e, item) => {
    data.sub_service.map((service) => {
      if (service.name === item.name) {
        if (e.target.checked === true) {
          service.reminder = true;
        } else {
          service.reminder = false;
        }
      }
    });
    setIsReminderUpdated(!isReminderUpadted);
  };

  const fromDateHandler = (v, item) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      const from_date = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
      data.sub_service.map((service) => {
        if (service.name === item.name) {
          service.from_date = from_date;
        } else {
          return false;
        }
      });
    }

    setIsFromDateUpdated(!isFromDateUpadted);
  };

  const toDateHandler = (v, item) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      const to_date = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
      data.sub_service.map((service) => {
        if (service.name === item.name) {
          service.to_date = to_date;
        } else {
          return false;
        }
      });
    }
    setIsToDateUpdated(!isToDateUpadted);
  };

  return (
    <>
      <tr>
        <td>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <DoNotDisturbOnIcon style={{ color: "black" }} />
            ) : (
              <AddCircleIcon style={{ color: "black" }} />
            )}
          </IconButton>
        </td>
        <td>{data && data.category}</td>
        <td>{data && data.service}</td>
        <td>{data && data.sub_service.length}</td>
      </tr>

      <tr>
        <TableCell style={{ padding: "0px 20px" }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ fontSize: "15px", textAlign: "left" }}
              >
                {/* Sub-Services Under this service */}
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                style={{ color: "black" }}
              >
                <thead>
                  <TableRow>
                    <th style={{}}>S NO.</th>
                    <th>Sub-Service</th>
                    <th>status(Active / Inactive)</th>
                    <th>
                      From Date<span style={{ color: "#fff" }}>*</span>
                    </th>
                    <th>
                      To Date<span style={{ color: "#fff" }}>*</span>
                    </th>
                    <th>Reminder</th>
                  </TableRow>
                </thead>
                <tbody>
                  {data.sub_service.map((value, index) => (
                    <TableRow key={index}>
                      <td style={{ textAlign: "center", border: "none" }}>
                        {index + 1}
                      </td>
                      <td style={{ textAlign: "center", border: "none" }}>
                        {value.name}
                      </td>
                      <td style={{ textAlign: "center", border: "none" }}>
                        <input
                          style={{
                            width: "18px",
                            height: "18px",
                            position: "relative",
                          }}
                          type="checkbox"
                          checked={value.status}
                          className="form-check-input"
                          onChange={(e) => statusHandler(e, value)}
                        />
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          padding: "0px 10px",
                          border: "none",
                        }}
                      >
                        {value.status === true ? (
                          <DatePicker
                            name="from_date"
                            value={value.from_date ?? ""}
                            onChange={(v) => fromDateHandler(v, value)}
                            validators={["required"]}
                            errorMessages={["From Date is required"]}
                            maxDateForDatepicker={
                              value.to_date ? value.to_date : new Date()
                            }
                          />
                        ) : (
                          <TextField
                            disabled
                            fullWidth
                            id="outlined-disabled"
                            size="small"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InsertInvitationIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          padding: "5px 10px",
                          border: "none",
                        }}
                      >
                        {value.status === true ? (
                          <DatePicker
                            name="to_date"
                            value={value.to_date ?? ""}
                            minDateForDatepicker={
                              value.from_date ? value.from_date : new Date()
                            }
                            onChange={(v) => toDateHandler(v, value)}
                            validators={["required"]}
                            errorMessages={["To Date is required"]}
                          />
                        ) : (
                          <TextField
                            disabled
                            fullWidth
                            id="outlined-disabled"
                            size="small"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InsertInvitationIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </td>
                      <td style={{ textAlign: "center", border: "none" }}>
                        <input
                          style={{
                            width: "18px",
                            height: "18px",
                            position: "relative",
                          }}
                          type="checkbox"
                          checked={value.reminder}
                          className="form-check-input"
                          onChange={(e) => reminderHandler(e, value)}
                        />
                      </td>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </tr>
    </>
  );
}

export default ServicesTable;

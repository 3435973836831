import React, { useEffect, useState } from "react";
import axios from "../../../../axios-order";
import {
    Button,
    Typography,
    Box,
    CircularProgress,
    Snackbar,
    Alert,
    IconButton,
    Tooltip, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip } from '@mui/material';
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import SaveIcon from '@mui/icons-material/Save';
import { v4 as uuidv4 } from 'uuid';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
    DataGridPro,
    useGridApiRef,
    gridFilteredSortedRowEntriesSelector,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector
} from '@mui/x-data-grid-pro';



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
    },

    uploadSection: {

        background: "transparent",
        borderRadius: "15px",
        padding: theme.spacing(4),
        boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)",
        maxWidth: "500px",
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        border: "2px dashed #007bff",
        borderStyle: "dashed",
        borderColor: "#007bff",
    },

    fileInput: {
        display: "none",
    },
    fileName: {
        color: "#444",
        fontSize: "0.95rem",
        fontWeight: 'bold',
        wordBreak: "break-word",
        textAlign: "center",
    },
    uploadButtonInput: {
        background: "linear-gradient(90deg, #4a90e2, #007aff)",
        color: "#fff",
        fontWeight: 700,
        padding: theme.spacing(1.5, 4),
        borderRadius: "30px",
        textTransform: "uppercase",
        fontSize: "1.1rem",
        transition: "all 0.3s ease",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: "linear-gradient(90deg, #007aff, #4a90e2)",
            transform: "scale(1.05)",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        },
    },
    uploadButton: {
        background: "linear-gradient(90deg, #4a90e2, #007aff)",
        color: "#ffffff",
        fontWeight: 700,

        padding: theme.spacing(1.5, 4),
        borderRadius: "30px",
        textTransform: "uppercase",
        fontSize: "1.1rem",
        transition: "all 0.3s ease",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: "linear-gradient(90deg, #2ecc71, #1abc9c)",
            transform: "scale(1.05)",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },

    leftIconButtonsContainer: {
        position: 'absolute',
        left: '16px',
        display: 'flex',
        gap: theme.spacing(2),
    },

    rightIconButtonsContainer: {
        position: 'absolute',
        right: '16px',
        display: 'flex',
        gap: theme.spacing(2),
    },

}));

export default function Form26ASExcelPairing() {
    let history = useHistory();
    const classes = useStyles();
    const financial_year = localStorage.getItem('financial_year_26_as')
    const [pairingData, setPairingData] = useState([])
    const [summaryrecords, setSummaryrecords] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [exportButtonLaoding, setExportButtonLaoding] = useState(false)
    const [fileDataToUpload, setFileDataToUpload] = useState("");
    const [upload26AS, setUpload26AS] = useState(false)
    const [booksTds, setBooksTds] = useState(0)
    const [form26asTds, setForm26asTds] = useState(0)
    const [pairingDataLoading, setPairingDataLoading] = useState(true);
    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");
    const [message, setMessage] = useState('');
    const [pairIdSrNum, setPairIdSrNum] = useState(localStorage.getItem("max_pair_id") || 0)
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [openErrorDailog, setOpenErrorDailog] = useState(false)
    const [errorData, setErrorData] = useState([])
    const [fileName, setFileName] = useState('')
    const [saveLoading, setSaveLoading] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [allBooksSelected, setAllBooksSelected] = useState(false);
    const [allForm26ASSelected, setAllForm26ASSelected] = useState(false);
    const gridApiRef = useGridApiRef();


    useEffect(() => {
        getForm26asExcelUploadLogs()
        getPairingData()
    }, [refresh])

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setMessage(message);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const getVisibleRows = () => {
        const visibleRows = gridFilteredSortedRowEntriesSelector(gridApiRef);
        return visibleRows.map(item => item.model);
    };


    const generatePairId = (srNo) => {

        // const srNo = pairIdSrNum
        const financial_year = localStorage.getItem('financial_year_26_as')
        let pairId = "M/";
        pairId += financial_year + "/" + (parseInt(srNo) + 1)

        return pairId
    }

    const updateSelectAllBooksAnd26AS = (updatedData) => {

        const visibleRows = getVisibleRows(gridApiRef);

        const allSelectedInVisibleRowsBooks = visibleRows.every(visibleRow => {
            const row = updatedData.find(updatedRow => updatedRow.id === visibleRow.id);
            return row && parseFloat(row.books_tds_amount) > 0 ? row.books_checked : true;
        });

        const allSelectedInVisibleRows26AS = visibleRows.every(visibleRow => {
            const row = updatedData.find(updatedRow => updatedRow.id === visibleRow.id);
            return row && parseFloat(row.form_26as_tds_amount) > 0 ? row.form_26as_checked : true;
        });

        setAllBooksSelected(allSelectedInVisibleRowsBooks);
        setAllForm26ASSelected(allSelectedInVisibleRows26AS);

    };


    const updateTdsAmounts = (checked, booksTdsAmount, form26asTdsAmount) => {
        if (checked) {
            setBooksTds(prevBooksTds => prevBooksTds + booksTdsAmount);
            setForm26asTds(prevForm26asTds => prevForm26asTds + form26asTdsAmount);
        } else {
            setBooksTds(prevBooksTds => {
                const newBooksTds = prevBooksTds - booksTdsAmount;
                return newBooksTds > 0 ? newBooksTds : 0;
            });
            setForm26asTds(prevForm26asTds => {
                const newForm26asTds = prevForm26asTds - form26asTdsAmount;
                return newForm26asTds > 0 ? newForm26asTds : 0;
            });
        }
    }


    const handleBooksCheckboxClcik = (event, item) => {
        const checked = event.target.checked;
        setPairingData(prevPairingData => {
            const updatedData = prevPairingData.map(row => {
                if ((item.matching_id && row.matching_id === item.matching_id) || row.id === item.id) {
                    updateTdsAmounts(
                        checked,
                        parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
                        parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0)
                    );
                    return {
                        ...row,
                        books_checked: row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 ? checked : row.books_checked,
                        form_26as_checked: row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 ? checked : row.form_26as_checked
                    };
                }
                return row;
            });

            updateSelectAllBooksAnd26AS(updatedData)
            return updatedData;
        });
    };

    const handleForm26ASCheckboxClick = (event, item) => {
        const checked = event.target.checked;
        setPairingData(prevPairingData => {
            const updatedData = prevPairingData.map(row => {
                if ((item.matching_id && row.matching_id === item.matching_id) || row.id === item.id) {
                    updateTdsAmounts(
                        checked,
                        parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
                        parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0)
                    );
                    return {
                        ...row,
                        books_checked: row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 ? checked : row.books_checked,
                        form_26as_checked: row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 ? checked : row.form_26as_checked
                    };
                }
                return row;
            });

            updateSelectAllBooksAnd26AS(updatedData)

            return updatedData;
        });
    };

    const handleSelectAllBooks = (event) => {
        const checked = event.target.checked;
        const visibleRows = getVisibleRows(gridApiRef)
        setAllBooksSelected(checked);

        setPairingData(prevPairingData => {
            const updatedData = prevPairingData.map(row => {
                if (visibleRows.find(vr => vr.id === row.id)) {
                    if (row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 && checked != row.books_checked) {
                        updateTdsAmounts(
                            checked,
                            parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
                            0
                        );
                        return { ...row, books_checked: checked };
                    }
                }
                return row;
            });
            // updateSelectAllBooksAnd26AS(updatedData);
            return updatedData;
        });
    };

    const handleSelectAllForm26AS = (event) => {
        const checked = event.target.checked;
        const visibleRows = getVisibleRows(gridApiRef)
        setAllForm26ASSelected(checked);

        setPairingData(prevPairingData => {
            const updatedData = prevPairingData.map(row => {
                if (visibleRows.find(vr => vr.id === row.id)) {
                    if (row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 && checked != row.form_26as_checked) {
                        updateTdsAmounts(
                            checked,
                            0,
                            parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0)
                        );
                        return { ...row, form_26as_checked: checked };
                    }
                }
                return row;
            });
            // updateSelectAllBooksAnd26AS(updatedData);
            return updatedData;
        });
    };

    const columns = [
        { field: 'cust_pan', headerName: 'PAN', width: 130, align: 'center', filterable: true },
        { field: 'tan', headerName: 'TAN', width: 130, align: 'center', filterable: true },
        { field: 'customer_name', headerName: 'Customer Name', width: 220, pinned: 'left', align: 'center', filterable: true, },
        { field: 'entity_gstin', headerName: 'Branch Entity GSTIN', width: 180, align: 'center', filterable: true },
        { field: 'branch_name', headerName: 'Branch Name', width: 150, align: 'center', filterable: true },
        { field: 'invoice_no', headerName: 'Invoice No', width: 150, align: 'center', filterable: true },
        { field: 'invoice_date', headerName: 'Invoice Date', width: 180, align: 'center', filterable: true },
        { field: 'collection_date', headerName: 'Collection Date', width: 150, align: 'center', filterable: true },
        { field: 'transaction_date', headerName: 'Transaction Date', width: 160, align: 'center', filterable: true },
        {
            field: 'form_26as_tds_amount',
            headerName: 'Form26AS TDS Amount',
            width: 200,
            align: 'center',
            type: 'number',
            renderCell: (params) => {
                return formatCurrency(params?.row?.form_26as_tds_amount);
            },
            filterable: true
        },
        {
            field: 'books_tds_amount',
            headerName: 'Books Tds Amount',
            width: 200,
            type: 'number',
            align: 'center',
            renderCell: (params) => {
                return formatCurrency(params?.row?.books_tds_amount);
            },
            filterable: true
        },
        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 180,
            align: 'center',
            type: 'singleSelect', // Enables dropdown filter
            valueOptions: (params) => {
                const visibleRows = getVisibleRows(gridApiRef);

                return Array.from(new Set(
                    visibleRows
                        .map(item => item?.remarks)
                        .filter(remark => remark !== null && remark !== undefined)
                ));
            },
            renderCell: (params) =>
                params?.row && params?.row?.remarks ? (
                    <Chip
                        label={params?.row?.remarks}
                        style={{
                            backgroundColor: handleRemarkColor(params?.row?.remarks),
                            color: '#fff',
                            maxWidth: '150px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    />
                ) : null,
            filterable: true
        },
        {
            field: 'remarks_2',
            headerName: 'Remarks-2',
            width: 180,
            align: 'center',
            type: 'singleSelect',
            valueOptions: (params) => {
                const visibleRows = getVisibleRows(gridApiRef);

                return Array.from(new Set(
                    visibleRows
                        .map(item => item?.remarks_2)
                        .filter(remark => remark !== null && remark !== undefined)
                ));
            },
            renderCell: (params) => (
                params?.row && params?.row?.remarks_2 ? (
                    <Chip
                        label={params?.row?.remarks_2}
                        style={{
                            backgroundColor: handleRemarkColor(params?.row.remarks_2),
                            color: '#fff',
                            maxWidth: '150px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    />
                ) : null
            ),
            filterable: true
        },
        { field: 'matching_id', headerName: 'Matching ID', width: 130, align: 'center', },
        {
            field: "books_checked",
            headerName: "Books",
            renderHeader: () => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span style={{ color: '#ffff', fontWeight: 500 }}>Books</span>
                    <input
                        type="checkbox"
                        checked={allBooksSelected}
                        onChange={handleSelectAllBooks}
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #1976d2',
                            borderRadius: '5px',
                            cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                        }}
                    />
                </div>
            ),
            width: 180,
            align: 'center',
            sortable: false,
            disableColumnFilter: true,
            renderCell: (params) => (
                !params?.row?.books_checkbox_visble && params?.row?.remarks !== 'Exact Match' && (params?.row?.books_tds_amount && parseFloat(params?.row?.books_tds_amount) > 0) ? (
                    <input type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #1976d2',
                            borderRadius: '5px',
                            cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                        }}

                        title={localStorage.getItem('locked_status') === "Y" ? "This financial year is locked" : undefined}
                        disabled={localStorage.getItem('locked_status') === "Y" ? true : false}
                        checked={!!params?.row?.books_checked}
                        onChange={(event) => handleBooksCheckboxClcik(event, params?.row)}
                    />
                ) : ""
            ),
            filterable: true
        },
        {
            field: "form_26as_checked",
            headerName: "26AS",
            renderHeader: () => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span style={{ color: '#ffff', fontWeight: 500 }}>26AS</span>
                    <input
                        type="checkbox"
                        checked={allForm26ASSelected}
                        onChange={handleSelectAllForm26AS}
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #1976d2',
                            borderRadius: '5px',
                            cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                        }}
                    />
                </div>
            ),
            width: 180,
            align: 'center',
            sortable: false,
            disableColumnFilter: true,
            renderCell: (params) => (
                !params?.row?.form_26_as_checkbox_visble && params?.row?.remarks !== "Exact Match" && (params?.row?.form_26as_tds_amount && parseFloat(params?.row?.form_26as_tds_amount) > 0) ? (
                    <input type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #1976d2',
                            borderRadius: '5px',
                            cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                        }}
                        disabled={localStorage.getItem('locked_status') === "Y" ? true : false}
                        checked={!!params?.row?.form_26as_checked}
                        onChange={(event) => handleForm26ASCheckboxClick(event, params?.row)}
                    />
                ) : ""
            ),
            filterable: true
        }


    ];

    const converBookData = (row) => {
        return {
            ...row,
            customer_name: row.customer_name,
            // remarks: 'Unpaired',
            remarks_2: "Manually Unpaired",
            form_26as_id: '',
            id: uuidv4(),
            section: '',
            matching_id: null,
            form_26as_tds_amount: '',
            form_26as_financial_year: '',
            transaction_date: '',
            form_26_as_quarter: '',
            books_checked: false,
            form_26as_checked: false,
            form_26_as_checkbox_visble: true,

        }
    }

    const convertForm26asData = (row) => {
        return {
            ...row,
            id: uuidv4(),
            customer_name: row.customer_name,
            books_id: '',
            // remarks: 'Unpaired',
            cust_pan: "",
            remarks_2: "Manually Unpaired",
            invoice_no: '',
            matching_id: null,
            invoice_date: '',
            taxable_value: '',
            non_taxable_value: '',
            collection_date: '',
            amount_paid: '',
            books_tds_amount: '',
            books_quarter: '',
            books_financial_year: '',
            books_checked: false,
            form_26as_checked: false,
            books_checkbox_visble: true,

        }
    }

    const handleUnpair = () => {

        const unPairedRows = [];
        const unhiddenRows = []
        let displayRecordHaveNotPairId = false

        const checkedRecords = pairingData.filter(book => book.books_checked || book.form_26as_checked);

        if (checkedRecords.length === 0) {
            handleSnackbarOpen('Please select records to unpair.', 'warning');
            return ''
        }

        const selectedMatchingIds = [...new Set(
            pairingData
                .filter(row => row.matching_id && (row.books_checked || row.form_26as_checked))
                .map(row => row.matching_id)
        )];

        for (let i = 0; i < selectedMatchingIds.length; i++) {
            const pairId = selectedMatchingIds[i];
            const rowsForPairId = pairingData.filter(row => row.matching_id === pairId);

            for (let j = 0; j < rowsForPairId.length; j++) {
                const row = rowsForPairId[j];

                if (
                    (row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 && !row.books_checked) ||
                    (row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 && !row.form_26as_checked)
                ) {
                    handleSnackbarOpen(
                        `Pair ID ${pairId} has unselected records. Select all related entries before unpairing.`,
                        'warning'
                    );
                    resetSelection();
                    return;
                }
            }
        }

        pairingData.forEach(row => {
            if (row.books_checked && row.form_26as_checked) {
                const converted26ASRow = convertForm26asData(row)
                unPairedRows.push(converBookData(row), converted26ASRow);
                unhiddenRows.push(converted26ASRow.id)
            } else if (row.books_checked || row.form_26as_checked) {
                if (row.matching_id) {
                    unPairedRows.push({ ...row, matching_id: '', remarks_2: "Manually Unpaired", books_checked: false, form_26as_checked: false })
                    unhiddenRows.push(row.id)
                } else {
                    displayRecordHaveNotPairId = true;
                }
            } else {
                unPairedRows.push(row);
            }
            localStorage.setItem('unhiddenRows', JSON.stringify(unhiddenRows))
        });

        if (displayRecordHaveNotPairId) {
            handleSnackbarOpen('The selection contains records which are not paired. Please select only the paired records.', 'warning')
            const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
            setPairingData(pairedRows);
        } else {
            setUnsavedChanges(true)
            setPairingData(unPairedRows);
        }
        resetSelection(true)

    };

    const resetSelection = (isPaired = false) => {

        if (!isPaired) {
            for (let i = 0; i < pairingData.length; i++) {
                pairingData[i].books_checked = false;
                pairingData[i].form_26as_checked = false;
            }
            setPairingData([...pairingData]);
        }

        setAllBooksSelected(false);
        setAllForm26ASSelected(false);
        setBooksTds(0);
        setForm26asTds(0);
    };

    const handlePair = () => {

        let panTanPairingMap = new Map(); // This map stores paired data for each PAN and TAN combination(grouped by pan first then TAN)
        const tanMapping = new Map(); // This map keeps track of which PANs are linked to which TANs (track which any TAN is linked with More then one pan or not)

        // max TDS_THRESHOLD to pair the records
        const TDS_THRESHOLD = 2;
        // new Pair ID will starts from maxPairId+1
        let maxPairId = pairIdSrNum
        

        ///////////////////////////////////////////////////////////////////////////////////
        // Loop through all records in pairingData & prepare the BOOKS Data and (track which any TAN is linked with More then one pan or not)
        // handle the comma seperated TANs also eg. first record TAN1, TAN2 second record TAN1 both will be come under same tan TAN1, TAN2 if they have same PAN
        ///////////////////////////////////////////////////////////////////////////////////
        for (const row of pairingData) {

            if (!(row?.books_checked)) continue; // Skip this record if Books checkbox not selected for pairing

            // If the record is already paired, show a warning and stop the process
            if (row?.matching_id) {
                resetSelection();
                handleSnackbarOpen(`Please unpair the record with Pair ID: ${row.matching_id} before attempting to pair it`, 'warning');
                return;
            }

            const pan = row?.cust_pan || "";
            const tan = row?.tan || "";

            if (row?.books_checked) {
                // If PAN is not present panTanPairingMap, then add that pan with empty value
                if (!panTanPairingMap.has(pan)) {
                    panTanPairingMap.set(pan, new Map());
                }
                // if this TAN already present in the panTanPairingMap with any PAN, if not, use the current TAN
                let parentTan = Array.from(panTanPairingMap.get(pan).keys()).find(key => key.includes(tan)) || tan;

                // if TAN is not present in that panTanPairingMap for the current pan then add it
                if (!panTanPairingMap.get(pan).has(parentTan)) {
                    panTanPairingMap.get(pan).set(parentTan, { books: [], form26as: [], booksTdsSum: 0, form26asTdsSum: 0 });
                }

                // Get the data associated with this TAN and PAN
                let tanData = panTanPairingMap.get(pan).get(parentTan);

                // Add this Book record to the TAN's  books's list and update the sum of "Books" TDS amounts
                tanData.books.push(row);
                tanData.booksTdsSum += parseFloat(row.books_tds_amount || 0);

                // And, keep track of this TAN present in how many PAN's
                let parentTanForPanTanMap = Array.from(tanMapping.keys()).find(key => key.includes(tan)) || tan;
                if (!tanMapping.has(parentTanForPanTanMap)) {
                    tanMapping.set(parentTanForPanTanMap, new Set());
                }
                tanMapping.get(parentTanForPanTanMap).add(pan);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////
        // Loop through all records in pairingData & prepare the 26AS Data
        // check this every 26AS tan is present in the how many pan's (in tanMapping) books side  if it is present more then 1 pan throw the error
        ///////////////////////////////////////////////////////////////////////////////////
        for (const row of pairingData) {

            if (!(row?.form_26as_checked)) continue; // Skip this record if 26AS checkbox not selected for pairing

            // If the record is already paired, show a warning and stop the process
            if (row?.matching_id) {
                resetSelection();
                handleSnackbarOpen(`Please unpair the record with Pair ID: ${row.matching_id} before attempting to pair it`, 'warning');
                return;
            }

            if (row?.form_26as_checked) {
                const tan = row?.tan || "";

                // For this 26AS record find the PAN based on the tan (from the tanMapping (this is prepared by using the Books tan's))
                let applicablePans = new Set();

                tanMapping.forEach((pans, key) => {
                    if (key.includes(tan)) {
                        pans.forEach(pan => applicablePans.add(pan));
                    }
                });

                applicablePans = [...applicablePans] || [];

                // If 26AS TAN is present in the more then one PAN Books Side. show a warning and stop the process
                if (applicablePans.length > 1) {
                    handleSnackbarOpen(`Unable to pair TAN ${tan} is linked to multiple PANs ${applicablePans}`, 'warning');
                    return
                }

                // Get the first PAN from the list (if it exists)
                const pan = applicablePans[0] || "";

                if (!panTanPairingMap.has(pan)) {
                    panTanPairingMap.set(pan, new Map());
                }

                // Check if this PAN and TAN combination already exists in the pairing map, if not, create it
                let parentTan = Array.from(panTanPairingMap.get(pan).keys()).find(key => key.includes(tan)) || tan;

                if (!panTanPairingMap.get(pan).has(parentTan)) {
                    panTanPairingMap.get(pan).set(parentTan, { books: [], form26as: [], booksTdsSum: 0, form26asTdsSum: 0 });
                }

                // Get the data for this PAN and TAN
                let tanData = panTanPairingMap.get(pan).get(parentTan);

                // Add this Form 26AS record to the TAN's 26AS list and update the sum of Form 26AS TDS amounts
                tanData.form26as.push(row);
                tanData.form26asTdsSum += parseFloat(row.form_26as_tds_amount || 0);
            }
        }

        let updatedPairingData = [...pairingData];
        let changesMade = false;

        // Iterating on selected PAN's & TAN's and validate TDS amounts matching
        for (const [pan, panData] of panTanPairingMap.entries()) {

            for (const [tan, data] of panData.entries()) {
                const { books, form26as, booksTdsSum, form26asTdsSum } = data;
                const tdsDifference = Math.abs(booksTdsSum - form26asTdsSum);

                // Checks TDS difference within the threshold
                if (tdsDifference > TDS_THRESHOLD) {
                    handleSnackbarOpen(`TDS amounts for PAN - ${pan} and TAN - ${tan} do not match within the threshold.`, 'warning');
                    resetSelection();
                    return;
                }

                // Genearte Unique Pair ID For TAN
                const pairId = generatePairId(maxPairId);

                const booksSet = new Set(books.map(b => b.id));
                const form26asSet = new Set(form26as.map(f => f.id));

                // for selected records update the pair id and save
                updatedPairingData = updatedPairingData.map(row => {
                    if (booksSet.has(row.id) || form26asSet.has(row.id)) {
                        return {
                            ...row,
                            matching_id: pairId,
                            remarks_2: "Manually Paired",
                            books_checked: false,
                            form_26as_checked: false
                        };
                    }
                    return row;
                });

                changesMade = true;
                maxPairId += 1
            }
        }

        // Finalize pairing and save data
        if (changesMade) {
            setPairingData(updatedPairingData);
            setUnsavedChanges(true);
            localStorage.setItem('max_pair_id', maxPairId);
            setPairIdSrNum(maxPairId)
            resetSelection(true);
            handleSnackbarOpen('Records paired manually successfully.', 'success');
        } else {
            handleSnackbarOpen('No valid pairs found.', 'warning');
            resetSelection();
        }
    }


    const saveReconData = () => {
        setSaveLoading(true)
        axios.post('/form26/saveReconData', { 'business_entity_id': localStorage.getItem("business_entity_id"), 'paired_data': pairingData })
            .then((res) => {
                if (res?.data?.status) {
                    setUnsavedChanges(false)
                    handleSnackbarOpen(res?.data?.message, 'success')
                }
            })
            .catch((err) => {

            }).finally(() => {
                setSaveLoading(false)
            })
    }


    const getPairingData = () => {
        setPairingDataLoading(true)
        const payloadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year,
            "entity_type": localStorage.getItem("entity_type")
        }
        axios.post('/form_26as_manual_pairing', payloadData)
            .then((res) => {
                if (res?.data?.status) {
                    const responsePairingData = res?.data?.records
                    setPairingData(responsePairingData);
                }
            })
            .catch((err) => {
                setPairingData([])
            }).finally(() => {
                setPairingDataLoading(false)
            });
    }

    const handleRemarkColor = (remark) => {
        let color = "";
        switch (remark) {
            case "Exact Match":
                color = "#14A44D";
                break;
            case "Probable Match":
                color = "#3B71CA";
                break;
            case "Manually Paired":
                color = "#14A44D";
                break;
            case "Mismatch":
                color = "#E4A11B";
                break;
            case "Manually Unpaired":
                color = "#DC4C64";
                break;
            default:
                color = "#3B71CA";
        }
        return color;
    };

    const getForm26asExcelUploadLogs = () => {
        const payLoadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year
        }
        axios.post("/get_form_26as_excel_upload_logs", payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    const maxPairId = res?.data?.records?.max_paired_id
                    setSummaryrecords(res?.data?.records?.records)
                    localStorage.setItem('max_pair_id', maxPairId)
                    setPairIdSrNum(maxPairId)
                }
            }).catch((err) => {

            })
    }

    const handleFileChange = (e) => {
        if (e.target.files.length) {
            const file_data = e.target.files[0];
            let fileName = file_data.name;
            setFileName(fileName)
            setSelectedFile(file_data);
            const reader = new FileReader();
            reader.readAsDataURL(file_data);
            reader.onload = function () {
                let file_value = reader.result;
                setFileDataToUpload(file_value);
            };
            e.target.value = null;
        }
    };

    const handleFileUpload = () => {
        if (!selectedFile) {
            setMessage("Please select a file first.");
            setSnackbarOpen(true);
            return;
        }

        const payLoadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year,
            "file": fileDataToUpload,
            "document_name": fileName,
            "max_pair_id": localStorage.getItem("max_pair_id")
        }

        setIsLoading(true);
        axios
            .post("/process_form_26_manual_paired_excel", payLoadData)
            .then((response) => {
                if (response?.data?.message) {
                    setMessage(response?.data?.message);
                    setSnackbarOpen(true);
                }
                getForm26asExcelUploadLogs()
            })
            .catch((error) => {
                setMessage("Error uploading file.");
                setSnackbarOpen(true);
            }).finally(() => {
                setSelectedFile(null);
                setFileDataToUpload("")
                setIsLoading(false);
            });
    };

    const formatCurrency = (value) => {
        if (value == null || value === '') return '₹0';

        return `₹${parseFloat(value).toLocaleString('en-IN')}`;
    };

    const handleDownloadErrorReport = (item) => {
        setSummaryrecords(prevRecords =>
            prevRecords.map(record =>
                record.id === item.id ? { ...record, excel_loading: true } : record
            )
        );

        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Form 26AS Errors.xlsx'

        const payLoadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year,
            "log_id": item.id
        }
        axios
            .post(`/form_26as_errors_excel`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {

                setSummaryrecords(prevRecords =>
                    prevRecords.map(record =>
                        record.id === item.id ? { ...record, excel_loading: false } : record
                    )
                );

            });
    }

    const showUploadStatus = (item) => {
        if (item.upload_status === "Pass") {
            handleSnackbarOpen("Records Uploaded successfully", "success")
            return
        }

        const payLoadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year,
            "log_id": item.id
        }
        axios.post("/form_26as_ui_errors_logs", payLoadData)
            .then((res) => {
                if (res?.data?.Status) {
                    setErrorData(res?.data?.records)
                    setOpenErrorDailog(true)
                }
            }).catch(() => {

            })
    }

    const renderSummaryTable = () => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table
                aria-label="customized table"
                sx={{
                    borderRadius: '20px',
                    '& .MuiTableCell-root': {
                        padding: '10px 16px', // Adjust padding to be between small and medium
                        fontSize: '0.9rem' // Adjust font size
                    }
                }} size="medium"
            >
                <TableRow sx={{ backgroundColor: '#607d8b' }}>
                    <TableCell
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontFamily: `'Montserrat', sans-serif', 'Roboto', sans-serif`,
                            fontSize: '1rem',
                            letterSpacing: '0.05rem',
                            textTransform: 'uppercase',
                        }}
                        align="center"
                    >
                        FINANCIAL YEAR
                    </TableCell>
                    <TableCell
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontFamily: `'Montserrat', sans-serif', 'Roboto', sans-serif`,
                            fontSize: '1rem',
                            letterSpacing: '0.05rem',
                            textTransform: 'uppercase',
                        }}
                        align="center"
                    >
                        UPLOAD TIME
                    </TableCell>
                    <TableCell
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontFamily: `'Montserrat', sans-serif', 'Roboto', sans-serif`,
                            fontSize: '1rem',
                            letterSpacing: '0.05rem',
                            textTransform: 'uppercase',
                        }}
                        align="center"
                    >
                        STATUS
                    </TableCell>
                    <TableCell
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            fontFamily: `'Montserrat', sans-serif', 'Roboto', sans-serif`,
                            fontSize: '1rem',
                            letterSpacing: '0.05rem',
                            textTransform: 'uppercase',
                        }}
                        align="center"
                    >
                        ACTION
                    </TableCell>

                </TableRow>

                <TableBody>
                    {(summaryrecords && summaryrecords.length > 0) ? (
                        summaryrecords.map((row, index) => (
                            <TableRow key={index}>

                                <TableCell sx={{ fontWeight: 'bold' }} align="center">{row.financial_year}</TableCell>
                                <TableCell align="center">{(row.uploaded_date_time)}</TableCell>
                                <TableCell align="center">
                                    <Chip
                                        label={row.upload_status}
                                        color={row.upload_status === 'Pass' ? 'success' : 'error'}
                                        variant="outlined"
                                        sx={{
                                            height: 32,
                                            borderRadius: '16px',
                                            width: 80,

                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {row.upload_status === 'Pass' && <VisibilityOutlinedIcon
                                        className="action-icon-button showIcon"
                                        onClick={() => showUploadStatus(row)}
                                        style={{ marginTop: '2px' }}
                                        color="primary"
                                    />}
                                    {row.upload_status === "Fail" && (
                                        <VisibilityOutlinedIcon
                                            className="action-icon-button deleteIcon"
                                            style={{ marginTop: '2px' }}
                                            onClick={() => showUploadStatus(row)}
                                        />
                                    )}
                                    {row.upload_status === "In Progress" && (
                                        <VisibilityOutlinedIcon
                                            className="action-icon-button deleteIcon"
                                            style={{ marginTop: '2px' }}
                                            color="disabled"
                                        // onClick={() => showFailReason(item)}
                                        />
                                    )}

                                    {(!row.upload_status || row.upload_status === "Initiated") && (
                                        <VisibilityOutlinedIcon
                                            className="action-icon-button deleteIcon"
                                            color="disabled"
                                            style={{ pointerEvents: 'none', marginTop: '2px' }}
                                        />
                                    )}

                                    {
                                        row.upload_status === 'Fail' && (!row.excel_loading ?
                                            <Tooltip title="Export" arrow>
                                                <img
                                                    onClick={() => row.upload_status === 'Fail' ? handleDownloadErrorReport(row) : handleExportExcel(row)}
                                                    style={{ marginLeft: '8px', height: '23px', width: '25px', cursor: 'pointer' }}
                                                    src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg"
                                                />
                                            </Tooltip> :
                                            <CircularProgress
                                                size={20}
                                                style={{ color: "green", marginLeft: '8px', marginTop: '10px' }}
                                            />)
                                    }

                                </TableCell>

                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}>
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const handleCloseDrillDownInvoiceDialog = () => {
        setOpenErrorDailog(false);

    };

    const UploadErrorDetailsDialog = () => {
        const headerCellStyle = {
            fontWeight: 'bold',
            backgroundColor: '#f0f0f0',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px',
            minWidth: '120px',
            textAlign: 'center'
        };
        return (
            <Dialog
                open={openErrorDailog}
                onClose={handleCloseDrillDownInvoiceDialog}
                fullWidth
                maxWidth="lg"
                sx={{
                    marginLeft: '14%',
                    marginTop: '5%',
                    borderRadius: '12px',
                    // boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)'
                }}
            >
                <DialogTitle style={{
                    backgroundColor: '#36A2EB', // Blue background
                    color: 'white',
                    fontSize: '22px',
                    padding: '14px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                    <span>
                        Error
                    </span>

                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} style={{ marginTop: '1%', maxHeight: '48vh' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={headerCellStyle}>ROW NO</TableCell>
                                    <TableCell style={headerCellStyle}>CUSTOMER NAME</TableCell>
                                    <TableCell style={headerCellStyle}>TAN</TableCell>
                                    <TableCell style={headerCellStyle}>ERROR</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(errorData && errorData.length > 0) ? (
                                    errorData.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{item.row_num}</TableCell>
                                            <TableCell align="center">{item.customer_name}</TableCell>
                                            <TableCell align="center">{item.tan}</TableCell>
                                            <TableCell align="center" style={{ color: red[500] }}>{item.error_message}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ color: 'red', textAlign: 'center' }}>
                                            No data found!
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={{
                            borderRadius: '8px', fontWeight: 'bold', textTransform: 'none', fontSize: '16px',
                            border: '2px solid #ff0000',
                        }}
                        onClick={handleCloseDrillDownInvoiceDialog}
                        color="error"
                        variant='outlined'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleExportExcel = (item = null) => {
        const payLoadData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": financial_year,
            "entity_type": localStorage.getItem("entity_type")
        }
        if (item) {
            setSummaryrecords(prevRecords =>
                prevRecords.map(record =>
                    record.id === item.id ? { ...record, excel_loading: true } : record
                )
            );
        } else {



            setExportButtonLaoding(true)
        }

        const visibleRows = getVisibleRows(gridApiRef);
        payLoadData['pairing_data'] = visibleRows

        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Form 26AS.xlsx'


        axios
            .post(`/form_26as_excel_manual_pairing`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                if (item) {
                    setSummaryrecords(prevRecords =>
                        prevRecords.map(record =>
                            record.id === item.id ? { ...record, excel_loading: false } : record
                        )
                    );
                } else {
                    setExportButtonLaoding(false)
                }
            });
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const handleChnageFilterModel = () => {
        const checkedRecords = pairingData.filter(book => book.books_checked || book.form_26as_checked);

        if (checkedRecords.length > 0) {
            resetSelection()
        }
    }

    const CustomToolbar = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
                gap: 1,
                padding: '3px 10px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            }}
        >
            <Box sx={{ display: 'flex', gap: 1 }}>
                <GridToolbarColumnsButton
                    sx={{
                        variant: 'outlined',
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        borderRadius: '4px',
                        padding: '2px 4px',
                        '&:hover': {
                            borderColor: '#1565c0',
                            backgroundColor: '#e3f2fd',
                        }
                    }}
                />
                <GridToolbarFilterButton
                    sx={{
                        variant: 'outlined',
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        borderRadius: '4px',
                        padding: '2px 4px',
                        '&:hover': {
                            borderColor: '#1565c0',
                            backgroundColor: '#e3f2fd',
                        }
                    }}
                />
                <GridToolbarDensitySelector
                    sx={{
                        variant: 'outlined',
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        borderRadius: '4px',
                        padding: '2px 4px',
                        '&:hover': {
                            borderColor: '#1565c0',
                            backgroundColor: '#e3f2fd',
                        }
                    }}
                />
            </Box>
            <Box sx={{ minWidth: '180px' }}>
                <GridToolbarQuickFilter sx={{ width: '100%', padding: '6px 12px', borderRadius: '4px', background: '#e0e0e0', '&:hover': { backgroundColor: '#d0d0d0' } }} />
            </Box>
        </Box>
    );


    return (
        <div className={classes.root}>
            <Typography
                variant="h6"
                sx={{
                    textAlign: 'center',
                    letterSpacing: '0.04rem',
                    fontFamily: `'Montserrat', sans-serif`,
                    textTransform: 'uppercase',
                    marginBottom: '2%'
                }}
            >
                <span style={{ fontWeight: 'bold', color: '#1D3557' }}>Form 26AS Manual Pairing ({financial_year})</span>
            </Typography>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "'Roboto', sans-serif",
                    width: "100%",
                    marginTop: '1%'
                }}
            >
                <ToggleSwitch
                    onChange={() =>
                        setUpload26AS(!upload26AS)
                    }
                    value={upload26AS}
                    label="Upload 26AS"
                />
            </div>


            <div className={classes.leftIconButtonsContainer}>

                <Tooltip title="Back" arrow>

                    <IconButton
                        // color='warning'
                        onClick={() => history.goBack()}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            color: '#263238',
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#455a64',
                                color: '#ffffff',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>

                </Tooltip>

                <Tooltip title="Refresh" arrow>

                    <IconButton
                        // color='warning'
                        onClick={() => setRefresh(!refresh)}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            color: '#3949ab',
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#3949ab',
                                color: '#ffffff',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>

                </Tooltip>

                <Tooltip title="Export" arrow>

                    <IconButton
                        color='success'
                        onClick={() => { if (!exportButtonLaoding) handleExportExcel(); }}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ccff90',
                                color: '#000000',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        {exportButtonLaoding ? <CircularProgress sx={{ color: '#1976d2', size: 20 }} size={20} />
                            : <img
                                style={{ height: 24, width: 24, objectFit: 'contain' }}
                                src='https://img.icons8.com/?size=48&id=117561&format=png'
                                alt="Export Icon"
                            />}
                    </IconButton>

                </Tooltip>

            </div>

            <div className={classes.rightIconButtonsContainer}>

                <Tooltip title="Unpair" arrow>

                    <IconButton
                        // color='warning'
                        onClick={handleUnpair}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            color: red[500],
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ff1744',
                                color: '#ffffff',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        <RemoveCircleIcon />
                    </IconButton>

                </Tooltip>

                <Tooltip title="Pair" arrow>

                    <IconButton
                        // color='warning'
                        onClick={handlePair}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            color: green[500],
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#00e676',
                                color: '#ffffff',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        <AddCircleIcon />
                    </IconButton>

                </Tooltip>

                <Tooltip title="Save" arrow>

                    <IconButton
                        color='primary'
                        onClick={saveReconData}
                        sx={{
                            borderRadius: '50%',  // Round button
                            padding: '8px',
                            // color: red[500],
                            fontSize: '24px',
                            marginRight: '15px',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#2196f3',
                                color: '#ffffff',
                            },
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        {saveLoading ? <CircularProgress sx={{ color: '#1976d2', size: 20 }} size={20} /> : <SaveIcon />}
                    </IconButton>

                </Tooltip>

            </div>

            {upload26AS && <>
                <div className={classes.uploadSection}>
                    <input
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                        className={classes.fileInput}
                        id="excel-upload-input"
                    />
                    <label htmlFor="excel-upload-input">
                        <Button variant="contained" component="span" className={classes.uploadButtonInput}>
                            <CloudUploadIcon className={classes.icon} />
                            Choose Excel File
                        </Button>
                    </label>

                    {selectedFile && (
                        <Typography className={classes.fileName} style={{ fontWeight: 'bold' }}>{selectedFile.name}</Typography>
                    )}

                    <Button onClick={handleFileUpload} className={classes.uploadButton}>
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            <>
                                <span style={{ color: '#ffffff' }}>Upload Excel</span>
                                <TurnedInNotIcon className={classes.icon} style={{ color: '#ffffff' }} />
                            </>
                        )}
                    </Button>

                </div>

                {renderSummaryTable()}
            </>}

            <Box sx={{ height: '60vh', width: '100%', marginTop: '0.8%' }}>
                <DataGridPro
                    rows={pairingData}
                    columns={columns}
                    loading={pairingDataLoading}
                    apiRef={gridApiRef}
                    localeText={{
                        toolbarDensity: 'Size',
                        toolbarDensityLabel: 'Size',
                        toolbarDensityCompact: 'Small',
                        toolbarDensityStandard: 'Medium',
                        toolbarDensityComfortable: 'Large',
                    }}
                    sx={{
                        maxHeight: '54vh',
                        '& .MuiDataGrid-columnHeaders': {
                            // backgroundColor: '#1976d2',
                            color: '#fff',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            textAlign: 'right',
                            zIndex: 1000,
                        },
                        '& .MuiDataGrid-columnHeader': {
                            position: 'relative',
                            textAlign: 'right',
                            width: '100%',
                            fontWeight: 'bold',
                            backgroundColor: '#607d8b',
                        },

                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: '#fff',
                        },
                        "& .MuiDataGrid-sortIcon": {
                            opacity: 'inherit !important',
                        },

                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: 'visible',
                        },
                        '& .MuiDataGrid-menuIconButton': {
                            opacity: '1 !important',
                            visibility: 'visible !important',
                            display: 'flex !important',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            '& .MuiDataGrid-columnHeader': {
                                '& .MuiDataGrid-menuIconButton': {
                                    display: 'flex !important',
                                    visibility: 'visible !important',
                                    opacity: '1 !important',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaderIcon': {
                            color: '#fff',
                        },
                        '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
                            color: '#fff',
                        },
                        '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: '#f9f9f9',
                        },
                        '& .MuiDataGrid-row:nth-of-type(even)': {
                            backgroundColor: '#ffffff',
                        },
                        '& .MuiDataGrid-cell': {
                            padding: '8px',
                            textAlign: 'center',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#f0f0f0',
                        },
                        '& .MuiCheckbox-root': {
                            color: '#1976d2',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#d3e3fc',
                            cursor: 'pointer'
                        },


                    }}
                    pagination
                    pageSize={pageSize}
                    pageSizeOptions={[5, 10, 25, 50]}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    isRowSelectable={() => false}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    onFilterModelChange={(filterModel) => {
                        handleChnageFilterModel()
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0', backgroundColor: '#f0f0f0' }}>
                    <Chip
                        label={`Books Total: ${formatCurrency(booksTds.toFixed(2))}`}
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderRadius: '20px',
                            fontSize: '14px',
                            fontWeight: 'bold',

                        }}
                    />
                    <Chip
                        label={`26AS Total: ${formatCurrency(form26asTds.toFixed(2))}`}
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderRadius: '20px',
                            fontSize: '14px',
                            fontWeight: 'bold',

                        }}
                    />
                    <Chip
                        label={`Difference: ${formatCurrency(Math.abs(booksTds - form26asTds).toFixed(2))}`}
                        color="error"
                        variant="outlined"
                        sx={{
                            borderRadius: '20px',
                            fontSize: '14px',
                            fontWeight: 'bold',

                        }}
                    />
                </Box>
            </Box>

            <UploadErrorDetailsDialog />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionProps={{
                    timeout: 500, // animation time (fade in/out)
                    onExit: () => setSnackbarOpen(false),
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import {
    Container, Grid, Paper, Typography, Box,
    Tooltip, FormControl, InputLabel, Select, MenuItem,
    IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Popover
} from '@mui/material';
import { Pie, Cell, Legend, ResponsiveContainer, Sector } from 'recharts';
import CircularProgress from "@mui/material/CircularProgress";
import axios from '../../../axios-order'
import LoadingButton from '@mui/lab/LoadingButton';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from "react-router-dom";
import '../../Dashboards/./sales/ageingSummary/dashboard.css'
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DashboardCards from './DashBoard';
import TablePagination from "@mui/material/TablePagination";
import Top5CustomerChart from './top_5cutomer_bar_chart'
import ColumnChart from '../../../components/charts/barchart';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PieChart from '../PieChart'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "../../../components/ui/DatePicker";
import moment from "moment";


const fetchDateOptions = () => {
    const currentDate = new Date();
    const options = [];
    options.push(`${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`)
    for (let i = 1; i <= 11; i++) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() - i;
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const formattedDate = `${lastDayOfMonth.getFullYear()}-${('0' + (lastDayOfMonth.getMonth() + 1)).slice(-2)}-${('0' + lastDayOfMonth.getDate()).slice(-2)}`;
        options.push(formattedDate);
    }
    return options

};
const currentDate = new Date();
const todayDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`

const AgeingRecivableReport = () => {

    let history = useHistory();
    const endRef = useRef(null);
    const isFirstRender = useRef(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const [loading, setLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState({});
    const [notYetDueGraphData, setNotYetDueGraphData] = useState([])
    const [overDueGraphData, setOverDueGraphData] = useState([])
    const [selectedDeatils, setSelectedDetails] = useState(0);
    const [top5CustomerData, setTop5CustomerData] = useState([])
    const [monthlyTrenChartData, setMonthlyTrenChartData] = useState([])
    const [customerDetails, setCustomerDetails] = useState([])
    const [customerTotalRecords, setcustomerTotalRecords] = useState(0);
    const [invoiceDetails, setInvoiceDetails] = useState([])
    const [invoiceTotalRecords, setinvoiceTotalRecords] = useState(0);
    const [advanceDetails, setAdvanceDetails] = useState([])
    const [advanceTotalRecords, SetAdvanceTotalRecords] = useState(0)
    const [unallocatedReceipts, setUnallocatedReceipts] = useState([])
    const [unallocatedTotalRecords, setUnallocatedTotalRecords] = useState(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedEntity, setSelectedEntity] = useState(localStorage.getItem("business_entity_id"))
    const [displyAsOFDate, setDisplayAsOfDate] = useState(todayDate)
    const [selectedAsOfDate, setSelectedAsOfDate] = useState(todayDate);
    const [exportButtonLaoding, setExportButtonLaoding] = useState(false)
    const [entityData, setEntityData] = useState([])
    const [haveMultiEntity, setHaveMultiEntity] = useState(false)
    const [openDrillDownDialog, setopenDrillDownDialog] = useState(false);
    const [drillDownInvoiceDetails, setDrillDownInvoiceDetails] = useState([]);
    const [drillDownFilterInvoice, setDrillDownFilterInvoice] = useState({})
    const [drillDownCustomerDetails, setDrillDownCustomerDetails] = useState([]);
    const [drillDownFilterCustomer, setDrillDownFilterCustomer] = useState({})
    const [drillDownTotalRecords, setDrillDownTotalRecords] = useState(0)
    const [drillDownPage, setDrillDownPage] = useState(0);
    const [drillDownRowsPerPage, setDrillDownRowsPerPage] = useState(10);
    const [drillDownDailogLoading, setDrillDownDailogLoading] = useState(false)

    useEffect(() => {
        if (selectedAsOfDate && moment(selectedAsOfDate, "YYYY-MM-DD", true).isValid()) {
            getReletedEntitiesData()
            getAgeingRecivableDashBoardDetails()
            getAgeingRecivableDueSummury()
            getAgeingRecivableMontlyTrendDueData()
            getAgeingRecivableTopCustomerData()
            getAgeingRecivableCustomerDetails()
            getAgeingRecivableInvoiceDetails()
            getAgeingRecivableAdvanceDetails()
            getAgeingRecivableUnallocatedDetails()
        }
        if (isFirstRender && isFirstRender.current) {
            isFirstRender.current = false;
        }


    }, [selectedEntity, selectedAsOfDate]);


    useEffect(() => {
        if (isFirstRender && isFirstRender.current) {
            return;
        }

        if (selectedDeatils === 0) getAgeingRecivableCustomerDetails();
        if (selectedDeatils === 1) getAgeingRecivableInvoiceDetails();
        if (selectedDeatils === 2) getAgeingRecivableAdvanceDetails();
        if (selectedDeatils === 3) getAgeingRecivableUnallocatedDetails();

    }, [page, rowsPerPage]);

    useEffect(() => {
        if (isFirstRender && isFirstRender.current) {
            return;
        }
        if (openDrillDownDialog) {
            getAgeingRecivableInvoiceDetails()
        }
    }, [drillDownFilterInvoice, drillDownPage, drillDownRowsPerPage])

    useEffect(() => {
        if (isFirstRender && isFirstRender.current) {
            return;
        }
        if (openDrillDownDialog) {
            getAgeingRecivableCustomerDetails()
        }
    }, [drillDownFilterCustomer, drillDownPage, drillDownRowsPerPage])

    useEffect(() => {
        if (isFirstRender && isFirstRender.current) return;
        setPage(0);
        setRowsPerPage(10);
    }, [selectedDeatils]);


    const formatCurrency = (value) => {
        if (!value || value == null || value === '') return '₹0';
        return `₹${value.toLocaleString('en-IN')}`;
    };

    const getReletedEntitiesData = () => {
        axios.get(`/get_related_entites_for_report/${selectedEntity}`)
            .then((res) => {
                const records = res?.data?.records
                if (records.length > 1) { 
                    setEntityData(records)
                    setHaveMultiEntity(true)
                }
            }).catch((err) => {

            })
    }

    const getAgeingRecivableDashBoardDetails = () => {
        setLoading(true)
        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'ageing_type': "receivable"
        }
        axios.post('/aging_recivable_dashboard_report', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setDashboardData(res?.data?.ageing_recivable_dashboard)
                }

            })
            .catch((err) => {

            }).finally(() => {
                setLoading(false)
            })
    }

    const getAgeingRecivableDueSummury = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'ageing_type': "receivable"
        }

        axios.post('/ageing_receivable_summary_by_bucket', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setOverDueGraphData(res?.data?.age_summary?.overdue_ageing_summary)
                    setNotYetDueGraphData(res?.data?.age_summary?.not_yet_due_ageing_summary)
                }
            })
            .catch((err) => {

            })
    }

    const getAgeingRecivableMontlyTrendDueData = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'ageing_type': "receivable"
        }

        axios.post('/ageing_receivable_monthly_due_trend_data', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setMonthlyTrenChartData(res?.data?.monthly_due_trend_data)
                }
            })
            .catch((err) => {

            })
    }

    const getAgeingRecivableTopCustomerData = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'ageing_type': "receivable"
        }

        axios.post('/ageing_receivable_top_customer_data', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setTop5CustomerData(res?.data?.top_customer_data)
                }
            })
            .catch((err) => {

            })
    }

    const getAgeingRecivableCustomerDetails = () => {

        let payLoadData = {}
        if (typeof drillDownFilterCustomer === 'object' && Object.keys(drillDownFilterCustomer).length === 0) {
            payLoadData = {
                'business_entity_id': selectedEntity,
                'as_of_date': selectedAsOfDate,
                'page': page,
                'per_page': rowsPerPage,
                'ageing_type': "receivable"
            }
        } else {
            setDrillDownDailogLoading(true)
            payLoadData = {
                'business_entity_id': selectedEntity,
                'as_of_date': selectedAsOfDate,
                'page': drillDownPage,
                'per_page': drillDownRowsPerPage,
                'filter': drillDownFilterCustomer,
                'ageing_type': "receivable"

            }
        }

        axios.post('/ageing_receivable_customer_details_summary', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    if (typeof drillDownFilterCustomer === 'object' && Object.keys(drillDownFilterCustomer).length === 0) {
                        setCustomerDetails(res?.data?.customer_details)
                        setcustomerTotalRecords(res?.data?.total_records)
                    } else {
                        setDrillDownCustomerDetails(res?.data?.customer_details)
                        setDrillDownTotalRecords(res?.data?.total_records)
                    }
                }
            })
            .catch((err) => {

            }).finally(() => {
                if (Object.keys(drillDownFilterCustomer).length != 0) {
                    setDrillDownDailogLoading(false)
                }

            })
    }

    const getAgeingRecivableInvoiceDetails = () => {
        let payLoadData = {}
        if (typeof drillDownFilterInvoice === 'object' && Object.keys(drillDownFilterInvoice).length === 0) {
            payLoadData = {
                'business_entity_id': selectedEntity,
                'as_of_date': selectedAsOfDate,
                'page': page,
                'per_page': rowsPerPage,
                'ageing_type': "receivable"
            }
        } else {
            setDrillDownDailogLoading(true)
            payLoadData = {
                'business_entity_id': selectedEntity,
                'as_of_date': selectedAsOfDate,
                'page': drillDownPage,
                'per_page': drillDownRowsPerPage,
                'filter': drillDownFilterInvoice,
                'ageing_type': "receivable"

            }
        }


        axios.post('/ageing_receivable_invoice_details_summary', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    if (typeof drillDownFilterInvoice === 'object' && Object.keys(drillDownFilterInvoice).length === 0) {
                        setInvoiceDetails(res?.data?.invoice_details)
                        setinvoiceTotalRecords(res?.data?.total_records)
                    } else {
                        setDrillDownInvoiceDetails(res?.data?.invoice_details)
                        setDrillDownTotalRecords(res?.data?.total_records)
                    }

                }
            })
            .catch((err) => {

            }).finally(() => {
                if (Object.keys(drillDownFilterInvoice).length != 0) {
                    setDrillDownDailogLoading(false)
                }

            })
    }

    const getAgeingRecivableAdvanceDetails = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'page': page,
            'per_page': rowsPerPage,
            'ageing_type': "receivable"
        }

        axios.post('/ageing_receivable_advance_details_summary', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setAdvanceDetails(res?.data?.advance_details)
                    SetAdvanceTotalRecords(res?.data?.total_records)
                }
            })
            .catch((err) => {

            })
    }

    const getAgeingRecivableUnallocatedDetails = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'page': page,
            'per_page': rowsPerPage,
            'ageing_type': "receivable"
        }

        axios.post('/ageing_receivable_unallocate_details_summary', payLoadData)
            .then((res) => {
                if (res?.data?.status) {
                    setUnallocatedReceipts(res?.data?.unallocated_receipts)
                    setUnallocatedTotalRecords(res?.data?.total_records)
                }
            })
            .catch((err) => {

            })
    }

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleExportExcel = () => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Ageing Receivable Report.xlsx'
        const payLoadData = {
            "business_entity_id": selectedEntity,
            "as_of_date": selectedAsOfDate,
            "ageing_type": "receivable",
            "multi_export": true
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }

            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });
    }

    const handleExportCustomerDetailsExcel = () => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Ageing Receivable Report - Customer Details.xlsx'
        const payLoadData = {
            "business_entity_id": selectedEntity,
            "as_of_date": selectedAsOfDate,
            'page': drillDownPage,
            'per_page': drillDownRowsPerPage,
            'filter': drillDownFilterCustomer,
            'ageing_type': "receivable",
            "multi_export": false,
            "sheet_names": ['master_details']
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res.data.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });
    }

    const handleExportInvoiceDetailsExcel = () => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Ageing Receivable Report - Invoice Details.xlsx'
        const payLoadData = {
            "business_entity_id": selectedEntity,
            "as_of_date": selectedAsOfDate,
            'page': drillDownPage,
            'per_page': drillDownRowsPerPage,
            'filter': drillDownFilterInvoice,
            'ageing_type': "receivable",
            "multi_export": false,
            "sheet_names": ['invoice_details']
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });
    }

    const handleExportUnallocateDetailsExcel = () => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Ageing Receivable Report - Unallocated Deatils.xlsx'
        const payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'page': page,
            'per_page': rowsPerPage,
            'ageing_type': "receivable",
            'multi_export': false,
            "sheet_names": ['unallocated_receipts']
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });
    }

    const handleExportAdvanceDetailsExcel = () => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'Ageing Receivable Report - Advance Details.xlsx'
        const payLoadData = {
            'business_entity_id': selectedEntity,
            'as_of_date': selectedAsOfDate,
            'page': page,
            'per_page': rowsPerPage,
            'ageing_type': "receivable",
            'multi_export': false,
            "sheet_names": ['advance_details']
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                if (res?.data?.file) {
                    downloadBase64File(contentType, res?.data?.file, filename);
                }
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });
    }

    const renderTable = () => {
        const rowsPerPageOptions = [10, 25, 100, 200, 300, 500];
        return (
            <>
                <Paper sx={{ p: 2, borderTop: '5px solid #36A2EB', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                            {selectedDeatils === 0 ? "CUSTOMER DETAILS" :
                                selectedDeatils === 1 ? "INVOICE DETAILS" :
                                    selectedDeatils === 2 ? "ADVANCE DETAILS" :
                                        "UNALLOCATED RECEIPTS"}
                        </Typography>
                        <LoadingButton
                            variant="outlined"
                            color='success'
                            loading={exportButtonLaoding}
                            style={{ height: '35px' }}
                            onClick={() => {
                                if (selectedDeatils === 0) {
                                    handleExportCustomerDetailsExcel();
                                } else if (selectedDeatils === 1) {
                                    handleExportInvoiceDetailsExcel();
                                } else if (selectedDeatils === 2) {
                                    handleExportAdvanceDetailsExcel();
                                } else {
                                    handleExportUnallocateDetailsExcel();
                                }
                            }}
                            endIcon={<img
                                style={{ height: 20, width: 20, objectFit: 'contain' }}
                                src='https://img.icons8.com/?size=48&id=117561&format=png'
                                alt="Export Icon" />}
                        >
                            Export
                        </LoadingButton>
                    </div>

                    <Tabs
                        onChange={handleChange}
                        value={selectedDeatils}
                        aria-label="Tabs where selection follows focus"
                        selectionFollowsFocus
                        textColor="secondary"
                        indicatorColor="secondary"
                        style={{ marginBottom: '1.5%' }}
                    >
                        <Tab label="CUSTOMER DETAILS" />
                        <Tab label="INVOICE DETAILS" />
                        <Tab label="ADVANCE DETAILS" />
                        <Tab label="UNALLOCATED RECEIPTS" />
                    </Tabs>

                    <div className="CustomerTable">
                        <div className="responsive-table" style={{ minHeight: '350px' }}>
                            {selectedDeatils === 0 ? renderCustomerDetailsTable() :
                                selectedDeatils === 1 ? renderInvoiceDetailsTable() :
                                    selectedDeatils === 2 ? renderAdvanceDetailsTable() : renderUnallocatedReceiptsTable()}
                        </div>
                    </div>

                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={selectedDeatils === 0 ? customerTotalRecords :
                            selectedDeatils === 1 ? invoiceTotalRecords :
                                selectedDeatils === 2 ? advanceTotalRecords : unallocatedTotalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </>
        );
    };

    const renderCustomerDetailsTable = () => {
        return (


            <table className="custome-long-table">
                <tbody>
                    <tr>
                        <th>CUSTOMER ERP CODE</th>
                        <th>CUSTOMER NAME</th>
                        <th>CUSTOMER GSTIN</th>
                        <th>CUSTOMER PAN</th>
                        <th>TAXABLE AMOUNT</th>
                        <th>NON-TAXABLE AMOUNT</th>
                        <th>GST AMOUNT</th>
                        <th>TOTAL AMOUNT</th>
                        <th>ADVANCE</th>
                        <th>EXCESS</th>
                        <th>COLLECTED AMOUNT</th>
                        <th>TOTAL OUTSTANDING - Agst Ref</th>
                        <th>TOTAL OUTSTANDING - All</th>
                        <th>OVER DUE</th>
                        <th>NOT YET DUE</th>

                    </tr>
                    {(customerDetails) ? customerDetails.map((item, index) => (
                        <tr key={index}>
                            <td>{item.erp_cust_code}</td>
                            <td>{item.cust_legal_name}</td>
                            <td>{item.cust_gstin}</td>
                            <td>{item.cust_pan}</td>
                            <td>{formatCurrency(item.taxable_value)}</td>
                            <td>{formatCurrency(item.non_taxable_adj)}</td>
                            <td>{formatCurrency(item.tax_amount)}</td>
                            <td>{formatCurrency(item.receivable_amount)}</td>
                            <td>{formatCurrency(item.advance)}</td>
                            <td>{formatCurrency(item.excess_amount)}</td>
                            <td>{formatCurrency(item.collection_amount)}</td>
                            <td>{formatCurrency(item.outstanding_amount)}</td>
                            <td>{formatCurrency(item.outstanding_amount_all)}</td>
                            <td>{formatCurrency(item.overdue_amount)}</td>
                            <td>{formatCurrency(item.not_yet_due_amount)}</td>

                        </tr>
                    )) : (
                        <tr>
                            <td style={{ color: "red" }} colspan={19}>
                                No data found!
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>



        )
    }

    const renderInvoiceDetailsTable = () => {
        return (


            <table className="custome-long-table">
                <tbody>
                    <tr>
                        <th>CUSTOMER NAME</th>
                        <th>CUSTOMER GSTIN</th>
                        <th>CUSTOMER PAN</th>
                        <th>INVOICE NUMBER</th>
                        <th>INVOICE DATE</th>
                        <th>ACCOUNT MANAGER NAME</th>
                        <th>GST INVOICE TYPE</th>
                        <th>AS OF DATE</th>
                        <th>TAXABLE AMOUNT</th>
                        <th>NON-TAXABLE AMOUNT</th>
                        <th>GST AMOUNT</th>
                        <th>TOTAL INVOICE AMOUNT</th>
                        <th>TOTAL DEBIT NOTE AMOUNT</th>
                        <th>TOTAL CREDIT NOTE AMOUNT</th>
                        <th>TOTAL INVOICE RECEIVABLE</th>
                        <th>INVOICES DUE</th>
                        <th>INVOICES NOT YET DUE</th>
                        <th>OVER DUE</th>
                        <th>NOT YET DUE</th>
                        <th>DUE DATE</th>
                        <th>DUE DAYS</th>
                        <th>COLLECTED AMOUNT</th>
                        <th>EXCESS</th>
                        <th>OVER DUE BUCKETS</th>
                        <th>NOT YET DUE BUCKETS</th>
                        <th>TOTAL OUTSTANDING</th>
                        <th>ADJUSTMENT METHOD</th>
                    </tr>
                    {(invoiceDetails) ? invoiceDetails.map((item, index) => (
                        <tr key={index}>
                            <td>{item.cust_legal_name}</td>
                            <td>{item.cust_gstin}</td>
                            <td>{item.cust_pan}</td>
                            <td>{item.invoice_no}</td>
                            <td>{item.invoice_date}</td>
                            <td>{item.cust_acctmgr_name}</td>
                            <td>{item.gst_invoice_type}</td>
                            <td>{item.as_of_date}</td>
                            <td>{formatCurrency(item.taxable_value)}</td>
                            <td>{formatCurrency(item.non_taxable_adj)}</td>
                            <td>{formatCurrency(item.tax_amount)}</td>
                            <td>{formatCurrency(item.invoice_amount)}</td>
                            <td>{formatCurrency(item.debit_amount)}</td>
                            <td>{formatCurrency(item.credit_amount)}</td>
                            <td>{formatCurrency(item.receivable_amount)}</td>
                            <td>{item.over_due_count}</td>
                            <td>{item.not_yet_due_count}</td>
                            <td>{formatCurrency(item.overdue_amount)}</td>
                            <td>{formatCurrency(item.not_yet_due_amount)}</td>
                            <td>{item.due_date}</td>
                            <td>{item.due_days}</td>
                            <td>{formatCurrency(item.collection_amount)}</td>
                            <td>{formatCurrency(item.excess_amount)}</td>
                            <td>{item.over_due_ageing_bucket}</td>
                            <td>{item.not_yet_due_ageing_bucket}</td>
                            <td>{formatCurrency(item.outstanding_amount)}</td>
                            <td>{item.adjustment_method}</td>
                        </tr>

                    )) : (
                        <tr>
                            <td style={{ color: "red" }} colspan={19}>
                                No data found!
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>



        )
    }

    const renderUnallocatedReceiptsTable = () => {
        const unallocatedHeaders = [
            "ERP CUSTOMER CODE", "CUSTOMER NAME", "CUSTOMER GSTIN", "CUSTOMER PAN",
            "COLLECTION DATE", "COLLECTION REF NO", "UNALLOCATED AMOUNT", "ADJUSTMENT METHOD"
        ];

        const columnWidths = [
            "250px", "200px", "200px", "200px",
            "200px", "200px", "220px", "200px"
        ];

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            {unallocatedHeaders.map((header, index) => (
                                <TableCell
                                    key={index}
                                    style={{
                                        width: columnWidths[index],
                                        whiteSpace: 'nowrap', // Prevents wrapping
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unallocatedReceipts && unallocatedReceipts?.length > 0 ? (
                            unallocatedReceipts.map((item, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                                    }}
                                >
                                    <TableCell>{item.erp_cust_code}</TableCell>
                                    <TableCell>{item.cust_legal_name}</TableCell>
                                    <TableCell>{item.cust_gstin}</TableCell>
                                    <TableCell>{item.cust_pan}</TableCell>
                                    <TableCell>{item.collection_date}</TableCell>
                                    <TableCell>{item.collection_ref_number}</TableCell>
                                    <TableCell>{formatCurrency(item.amount_paid)}</TableCell>
                                    <TableCell>{item.adjustment_method}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} style={{ color: "red", textAlign: 'center' }}>
                                    No data found!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

        );
    };

    const renderAdvanceDetailsTable = () => {
        const advanceHeaders = [
            "ERP CUSTOMER CODE", "CUSTOMER NAME", "GSTIN", "PAN",
            "COLLECTION DATE", "COLLECTION REF NO", "ADVANCE AMOUNT", "ADJUSTMENT METHOD"
        ];

        const columnWidths = [
            "250px", "200px", "200px", "200px",
            "200px", "200px", "220px", "200px"
        ];
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            {advanceHeaders.map((header, index) => (
                                <TableCell
                                    key={index}
                                    style={{
                                        width: columnWidths[index],
                                        whiteSpace: 'nowrap', // Prevents wrapping
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {advanceDetails && advanceDetails?.length > 0 ? (
                            advanceDetails.map((item, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                                    }}
                                >
                                    <TableCell>{item.erp_cust_code}</TableCell>
                                    <TableCell>{item.cust_legal_name}</TableCell>
                                    <TableCell>{item.cust_gstin}</TableCell>
                                    <TableCell>{item.cust_pan}</TableCell>
                                    <TableCell>{item.collection_date}</TableCell>
                                    <TableCell>{item.collection_ref_number}</TableCell>
                                    <TableCell>{formatCurrency(item.amount_paid)}</TableCell>
                                    <TableCell>{item.adjustment_method}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} style={{ color: "red", textAlign: 'center' }}>
                                    No data found!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderBarChart = () => {
        if (!monthlyTrenChartData) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }

        // Map the data to match the format of the chart configuration
        const categories = monthlyTrenChartData.map(item => item.period);
        const overdueData = monthlyTrenChartData.map(item => item["Over due"] || 0);
        const notYetDueData = monthlyTrenChartData.map(item => item["Not yet due"] || 0);



        const chartOptions = {
            chart: {
                borderRadius: 15,
                type: 'column',
                styledMode: true,
                // margin: [20, 20, 40, 20]
            },

            xAxis: {
                categories: categories,
                gridLineWidth: 0,
            },
            title: {
                text: 'Ageing Receivables: Monthly Payment Trends',
                style: {
                    fontSize: '18px',
                    fontWeight: '600',
                    color: '#2e3b5d',
                },
            },
            subtitle: {
                text: 'A Detailed View of Overdue and Not Yet Due Receivables by Month',
                style: {
                    fontSize: '14px',
                    color: '#6c757d',
                },
            },
            yAxis: [
                {
                    className: 'highcharts-color-0',
                    title: {
                        text: 'Overdue'
                    },
                    gridLineWidth: 0,
                    labels: {
                        formatter: function () {
                            return formatCurrency(this.value);
                        }
                    }
                },
                {
                    className: 'highcharts-color-1',
                    opposite: true,
                    title: {
                        text: 'Not Yet Due'
                    },
                    labels: {
                        formatter: function () {
                            return formatCurrency(this.value);
                        }
                    }
                }
            ],
            plotOptions: {
                column: {
                    allowPointSelect: true,
                    borderRadius: 5,
                    cursor: 'pointer',
                    showInLegend: true,
                    events: {
                        click: handleClickMonthleyTrendChart
                    }
                },


            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                maxWidth: '100%',
                itemWidth: 120,
                itemMarginTop: 7,
                itemMarginBottom: 5,
                useHTML: true,
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: 'Overdue',
                    data: overdueData,
                    tooltip: {
                        pointFormatter: function () {
                            return `${this.series.name}: <b>${formatCurrency(this.y)}</b>`;
                        }
                    }
                },
                {
                    name: 'Not Yet Due',
                    data: notYetDueData,
                    yAxis: 1,
                    tooltip: {
                        pointFormatter: function () {
                            return `${this.series.name}: <b>${formatCurrency(this.y)}</b>`;
                        }
                    }
                }
            ]
        };

        return (
            < >
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </>
        );
    };

    const handleopenDrillDownDialog = () => {
        setopenDrillDownDialog(true);
    };

    const handleCloseDrillDownInvoiceDialog = () => {
        setopenDrillDownDialog(false);
        setDrillDownInvoiceDetails([]);
        setDrillDownFilterInvoice({})
        setDrillDownCustomerDetails([]);
        setDrillDownFilterCustomer({})
        setDrillDownPage(0)
        setDrillDownRowsPerPage(10)
    };

    const renderInvoiceDillDownDeatils = () => {
        const headerCellStyle = {
            fontWeight: 'bold',
            backgroundColor: '#f0f0f0',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px',
            minWidth: '120px',
            textAlign: 'center'
        };
        return (
            <TableContainer component={Paper} style={{ marginTop: '1%', maxHeight: '48vh' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerCellStyle}>CUSTOMER NAME</TableCell>
                            <TableCell style={headerCellStyle}>INVOICE NUMBER</TableCell>
                            <TableCell style={headerCellStyle}>INVOICE DATE</TableCell>
                            <TableCell style={headerCellStyle}>GST AMOUNT</TableCell>
                            <TableCell style={headerCellStyle}>OVER DUE</TableCell>
                            <TableCell style={headerCellStyle}>NOT YET DUE</TableCell>
                            <TableCell style={headerCellStyle}>DUE DATE</TableCell>
                            <TableCell style={headerCellStyle}>DUE DAYS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drillDownInvoiceDetails && drillDownInvoiceDetails.length > 0 ? (
                            drillDownInvoiceDetails.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{item.cust_legal_name}</TableCell>
                                    <TableCell align="center">{item.invoice_no}</TableCell>
                                    <TableCell align="center">{item.invoice_date}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.tax_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.overdue_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.not_yet_due_amount)}</TableCell>
                                    <TableCell align="center">{item.due_date}</TableCell>
                                    <TableCell align="center">{item.due_days}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10} style={{ color: 'red', textAlign: 'center' }}>
                                    No data found!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderCustomerDillDownDeatils = () => {
        const headerCellStyle = {
            fontWeight: 'bold',
            backgroundColor: '#f0f0f0',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '10px',
            minWidth: '120px',
            textAlign: 'center'
        };
        return (
            <TableContainer component={Paper} style={{ marginTop: '1%', maxHeight: '48vh' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerCellStyle}>CUSTOMER NAME</TableCell>
                            <TableCell style={headerCellStyle}>GST AMOUNT</TableCell>
                            <TableCell style={headerCellStyle}>OVER DUE</TableCell>
                            <TableCell style={headerCellStyle}>NOT YET DUE</TableCell>
                            <TableCell style={headerCellStyle}>TOTAL OUTSTANDING</TableCell>
                            <TableCell style={headerCellStyle}>TOTAL OUTSTANDING ALL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drillDownCustomerDetails && drillDownCustomerDetails.length > 0 ? (
                            drillDownCustomerDetails.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{item.cust_legal_name}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.tax_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.overdue_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.not_yet_due_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.outstanding_amount)}</TableCell>
                                    <TableCell align="center">{formatCurrency(item.outstanding_amount_all)}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10} style={{ color: 'red', textAlign: 'center' }}>
                                    No data found!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const DrillDownInvoiceDetailsDialog = () => {
        const filter = (typeof drillDownFilterInvoice === 'object' && Object.keys(drillDownFilterInvoice).length === 0) ? drillDownFilterCustomer : drillDownFilterInvoice
        return (
            <Dialog
                open={openDrillDownDialog}
                onClose={handleCloseDrillDownInvoiceDialog}
                fullWidth
                maxWidth="lg"
                sx={{
                    marginLeft: '14%', marginTop: '5%', borderRadius: '12px', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)'
                }}
            >
                <DialogTitle style={{
                    backgroundColor: '#36A2EB', // Blue background
                    color: 'white',
                    fontSize: '22px',
                    padding: '14px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                    <span>
                        {filter['filter_type']} ({filter['name']})  <FilterAltIcon style={{ marginLeft: '10px' }} />
                    </span>
                    <LoadingButton
                        variant="outlined"
                        style={{
                            height: '35px',
                            color: 'white', // Button text color
                            borderColor: 'white' // Button border color
                        }}
                        loading={exportButtonLaoding}
                        onClick={() => {
                            if (typeof drillDownFilterInvoice === 'object' && Object.keys(drillDownFilterInvoice).length === 0) {
                                handleExportCustomerDetailsExcel();
                            } else {
                                handleExportInvoiceDetailsExcel();
                            }
                        }}
                        endIcon={<img
                            style={{ height: 20, width: 20, objectFit: 'contain' }}
                            src='https://img.icons8.com/?size=48&id=117561&format=png'
                            alt="Export Icon" />}
                    >
                        Export
                    </LoadingButton>
                </DialogTitle>
                <DialogContent>
                    {drillDownDailogLoading ? (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <CircularProgress />
                            <p>Loading data, please wait...</p>
                        </div>
                    ) : (
                        <>
                            {(typeof drillDownFilterInvoice === 'object' && Object.keys(drillDownFilterInvoice).length === 0) ? renderCustomerDillDownDeatils() : renderInvoiceDillDownDeatils()}
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100, 200, 300, 500]}
                                component="div"
                                count={drillDownTotalRecords}
                                rowsPerPage={drillDownRowsPerPage}
                                page={drillDownPage}
                                onPageChange={handleChangeDrillDownPage}
                                onRowsPerPageChange={handleChangeDrillDownRowsPerPage}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={{
                            borderRadius: '8px', fontWeight: 'bold', textTransform: 'none', fontSize: '16px',
                            border: '2px solid #ff0000',
                        }}
                        onClick={handleCloseDrillDownInvoiceDialog}
                        color="error"
                        variant='outlined'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleChange = (event, newValue) => {
        setSelectedDetails(newValue);
        setPage(0);
        setRowsPerPage(10);
    };

    const handleChangeDrillDownPage = (event, newPage) => {
        setDrillDownPage(newPage);
    };

    const handleChangeDrillDownRowsPerPage = (event) => {
        setDrillDownRowsPerPage(event.target.value);
        setDrillDownPage(0);
    };

    const handleClickAgeingBucketSummury = (filter) => {
        handleopenDrillDownDialog()
        setDrillDownFilterInvoice(filter)
    };

    const handleClickMonthleyTrendChart = (e) => {
        const clickedCategory = e.point.category;
        const clickedValue = e.point.y;
        const filterObject = {
            "filter_type": "Monthly Payment Trends",
            "name": clickedCategory
        }
        handleopenDrillDownDialog()
        setDrillDownFilterInvoice(filterObject)
    }

    const onChngeTop5CustomersChart = (clickedCustomer, clickedAmount) => {

        const filterObject = {
            "filter_type": "Top Customers",
            "name": clickedCustomer
        }
        console.log("filterObject..............", filterObject)
        handleopenDrillDownDialog()
        setDrillDownFilterCustomer(filterObject)
    }



    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const scrollToBottom = () => {
        if (endRef && endRef.current) {
            window.scrollTo({
                top: endRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const onDateChnageHandler = (v) => {
        setDisplayAsOfDate(v)
        const date = moment(v).format("YYYY-MM-DD")
        setSelectedAsOfDate(date)
    }


    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '4%' }}>
                <Grid container alignItems="center" spacing={3}>
                    {/* Dropdown on the left */}
                    <Grid item xs={3} md={2.5} >
                        {haveMultiEntity && <FormControl variant="outlined" fullWidth>
                            <InputLabel id="entity-select-label">Select Entity</InputLabel>
                            <Select
                                style={{ height: '40px' }}
                                labelId="entity-select-label"
                                value={selectedEntity}
                                onChange={(event) => setSelectedEntity(event.target.value)}
                                label="Select Entity"
                            >
                                {entityData.map((entity) => (
                                    <MenuItem key={entity.business_entity_id} value={entity.business_entity_id}>
                                        {entity.entity_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                    </Grid>

                    {/* Centered Heading */}
                    <Grid item xs={12} md={5.5} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'center',
                                letterSpacing: '0.04rem',
                                fontFamily: `'Montserrat', sans-serif`,
                                textTransform: 'uppercase',
                            }}
                        >
                            <span style={{ fontWeight: 'bold', color: '#1D3557' }}>Ageing Receivable</span>
                            <span style={{ fontWeight: 'normal', color: "#212121" }}> Dashboard</span>
                            <AnalyticsIcon style={{ marginLeft: '10px', color: '#E76F51', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                        </Typography>
                    </Grid>

                    {/* Buttons on the right */}
                    <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                        <Tooltip title="Back" arrow>

                            <IconButton
                                // color='warning'
                                onClick={() => history.goBack()}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    color: '#263238',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#455a64',
                                        color: '#ffffff',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>

                        </Tooltip>

                        <Tooltip title="Date Filter" arrow>

                            <IconButton
                                color='primary'
                                onClick={handlePopoverOpen}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: Boolean(anchorEl) ? '#2196f3' : '#ffffff',
                                    color: Boolean(anchorEl) && '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#2196f3',
                                        color: '#ffffff',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <CalendarMonthIcon />
                            </IconButton>

                        </Tooltip>

                        <Tooltip title="Export" arrow>

                            <IconButton
                                color='success'
                                onClick={() => { if (!exportButtonLaoding) handleExportExcel(); }}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#ccff90',
                                        color: '#000000',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                {exportButtonLaoding ? <CircularProgress sx={{ color: '#1976d2', size: 20 }} size={20} />
                                    : <img
                                        style={{ height: 24, width: 24, objectFit: 'contain' }}
                                        src='https://img.icons8.com/?size=48&id=117561&format=png'
                                        alt="Export Icon"
                                    />}
                            </IconButton>

                        </Tooltip>

                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box sx={{ padding: 1 }}>
                                <ValidatorForm
                                    useRef="form"
                                    // onSubmit={onSubmitHandler}
                                    onError={(errors) => console.log(errors)}
                                >
                                    <DatePicker
                                        // maxDate={toDateMonthYearDisplay ?? ''}
                                        placeholder="From Date"
                                        errorMessages={["This field is required"]}
                                        validators={["required"]}
                                        value={displyAsOFDate ?? ""}
                                        onChange={(v) => onDateChnageHandler(v)}
                                    />
                                </ValidatorForm>
                            </Box>
                        </Popover>



                    </Grid>
                </Grid>
            </Box>

            {!loading ? (
                <Grid container lg={12} spacing={2} >


                    <Grid lg={12} container
                        md={6}
                        direction="row"
                        mb={2}
                        ml={1}
                        spacing={1.8}>

                        <DashboardCards
                            dashboardData={dashboardData}
                            report_type="ageing_receivable"
                            onCardClick={scrollToBottom}
                        />

                    </Grid>
                    <Grid item xs={12} md={6} mb={1}>


                        <ResponsiveContainer style={{ marginTop: '1%', flexGrow: 1 }} width="100%" height="100" id="chart-container">
                            {overDueGraphData.length > 0 &&
                                <PieChart
                                    graphData={overDueGraphData} id="myPieChart"
                                    title="Overdue Ageing Summary"
                                    onClick={handleClickAgeingBucketSummury}
                                />}
                        </ResponsiveContainer>

                    </Grid>

                    <Grid item xs={12} md={6} mb={1}>


                        <ResponsiveContainer style={{ marginTop: '1%', borderRadius: '16px', flexGrow: 1 }} width="100%" height="100" id="chart-container">
                            {notYetDueGraphData.length > 0 &&
                                <PieChart
                                    graphData={notYetDueGraphData}
                                    id="myPieChart1"
                                    title="Not Yet Due Ageing Summary"
                                    onClick={handleClickAgeingBucketSummury}
                                />}
                        </ResponsiveContainer>

                    </Grid>

                    <Top5CustomerChart
                        top5CustomerData={top5CustomerData}
                        selectedReportTypeForTop5={"ageing_recivable"}
                        onChngeTop5CustomersChart={onChngeTop5CustomersChart}
                        singleReport={true}
                    />


                    <Grid item xs={12} md={12} mb={2}>

                        <>
                            <ResponsiveContainer width="100%" height="100%" id="chart-container">
                                {renderBarChart()}
                            </ResponsiveContainer>
                        </>
                    </Grid>

                    <Grid item xs={12} md={12} ref={endRef}>
                        <>

                            {renderTable()}
                        </>
                    </Grid>
                    <DrillDownInvoiceDetailsDialog />


                </Grid>
            )

                : <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>}
        </div>
    )
}

export default AgeingRecivableReport
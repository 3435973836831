import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./registration.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ThemeProvider, withStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectInput from "../ui/basicSelectInput";
import Checkbox from "@material-ui/core/Checkbox";
import constitution from "../../asserts/jsonData/constitution.json";
import business_sector from "../../asserts/jsonData/business_sector.json";
import Divider from "@material-ui/core/Divider";
import axios from "../../axios-order";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = (theme) => ({
  logo: {
    maxWidth: "80px",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  label: {
    fontFamily: "Dosis",
  },
});

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cappitall Want Solutions (CWS) offers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ textDecoration: "underline" }}>
          Invoice Discounting & Invoice Collection Services-
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We are a market place for{" "}
          <span style={{ fontWeight: 600 }}>Invoice Discounting</span> and{" "}
          <span style={{ fontWeight: 600 }}>Collection service</span>s and
          assist to reach multiple service providers efficiently to ensure you
          choose the one best suited for your organization. You get to choose
          multiple service providers to help you in optimizing debtors related
          working capital management
        </p>
        <h5 style={{ textDecoration: "underline" }}>
          ARC – Accounting Reconciliation and Compliance
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We provide assistance in ready to upload{" "}
          <span style={{ fontWeight: 600 }}>Accounting entries</span> relating
          to Invoice Discounting and Collection transactions ensuring accuracy
          and efficiency in handling them.
        </p>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We track the entire receivable cycle including discounting and
          collection services if availed within our platform or outside and
          enhances the visibility of debtors related working capital management
          with access to various dashboards and reporting.
        </p>
        <p>
          We also help in{" "}
          <span style={{ fontWeight: 600 }}>Reconciliation</span> and{" "}
          <span style={{ fontWeight: 600 }}>Compliance</span> of TDS and GST
          related transactions.
        </p>
        <h6
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Want to know how? Reach our customer support!! @
          <a style={{ color: "#000" }} href="mailto:support@cappitallwant.com">
            {" "}
            support@cappitallwant.com
          </a>
        </h6>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontWeight: 600 }}>Coming Soon... </span> Onboarding
          more solution partners for Invoice discounting and collections!!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

class Rgister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      user: {
        categories: [],
        cin: "",
      },
      is_cin: false,
      showARCData: false,
      categories: [],
      loading: false,
      showPassword: false,
      showBranchFields: false,
      gstRequired: false,
      entities: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleClickShowPassword = () => {
    if (this.state.showPassword === false) {
      this.setState({
        showPassword: true,
      });
    } else {
      this.setState({ showPassword: false });
    }
  };

  getCategories() {
    axios
      .get(`/cws_services/get_categories/`)
      .then((res) =>
        this.setState({
          categories: res.data.categories,
        })
      )
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getCategories();
    // custom rule will have name 'isPasswordMatch' [password validation]
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.user.password) {
        return false;
      }
      return true;
    });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  onLoginPageHandler = () => {
    this.props.history.replace("/");
  };

  // form input change function
  handleInputChange(event) {
    const { user } = this.state;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "entity_type") {
      user[name] = String(value);

      this.setState({
        user,
        showBranchFields: value === "Branch",
        gstRequired: value === "Branch" && !user.gst
      }, () => {
        const gstinPattern = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$/;
        if (value === "Branch" &&  this.state.user.gst && gstinPattern.test(this.state.user.gst)) {
          this.fetchMainEntities();
        }
      });


    }


    if (name !== "ConfirmPassword") {
      if (name === "constitution_id") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
          is_cin: value === 3 || value === 4 ? true : false,
        });
      } else if (name === "business_sector_id") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
        });
      } else if (name === "msme") {
        user[name] = parseInt(value);
        this.setState({
          user: user,
        });
      } else if (name == "gst" || name == "pan") {
        user[name] = String(value.toUpperCase());
        this.setState({
          user,
        });

        const gstinPattern = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$/;
        if (this.state.user.entity_type === "Branch" && this.state.user.gst && gstinPattern.test(this.state.user.gst)) {
          this.fetchMainEntities();
        }

      } else {
        user[name] = String(value);
        this.setState({
          user,
        });
      }
    } else {
      user[name] = String(value);
      this.setState({
        user,
      });
    }
  }

  // ---Service Checkbox---
  handleChangeCheckbox = (e) => {
    let user = this.state.user;
    let category = e.target.value;

    if (e.target.checked) {
      user["categories"].push(category);
      this.setState({
        user,
      });
    } else {
      const index = user["categories"].indexOf(category);
      user["categories"].splice(index, 1);
      this.setState({
        user,
      });
    }
  };

  //---On submit form function---
  onSubmitHandler = (event) => {
    this.setState({ loading: true });
    toast.configure();
    event.preventDefault();
    let categories = this.state.user["categories"];
    if (!categories.length) {
      toast.info("Please select one service.", { autoClose: 2500 });
      this.setState({ loading: false });
      return false;
    }
    axios
      .post("/register", this.state.user)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({ loading: false });
          toast.success(res.data.message, { autoClose: 2500, progress: 0 });
          this.props.history.replace("/login");
        } else {
          this.setState({ loading: false });
          toast.error(res.data.message, { autoClose: 2500, progress: 0 });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        toast.error(error.response.data.message, { autoClose: 2500 });
      });
  };

  //----End---
  modalHandler = () => {
    this.setState({ modalShow: true });
  };
  modalClose = () => {
    this.setState({ modalShow: false });
  };

  fetchMainEntities = () => {
    toast.configure();
    axios.get(`fetch_main_entities_by_gst/${this.state.user.gst}`)
      .then(res => {
        if (res.data.status) {
          this.setState({ entities: res.data.records });
        } else {
          this.setState({ entities: [] });
          const { user } = this.state;
          user['main_branch'] = '';
          this.setState({
            user,
          });
          if (this.state.user.entity_type == "Branch") {
            toast.error(res.data.message, { autoClose: 2500, progress: 0 });
          }
        }

      })
      .catch(error => {
        console.error('Error fetching entities:', error);
      });
  }

  render() {
    console.log(this.state.user);
    return (
      <div className="Register-section">
        <ThemeProvider>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10}>
                <div class="Register-form-div">
                  <div className="Register-form-div-heading">
                    <h2>Register</h2>
                  </div>
                  <Divider />
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <div className="Register-form-div-body">
                      <div className="form-header">
                        <p>Business Entity Details</p>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Entity Name<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="entity_name"
                            value={this.state.user.entity_name ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Entity Email Id
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="contact_email_id"
                            value={this.state.user.contact_email_id ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Contact Number
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            inputProps={{ min: 0 }}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_contact_number"
                            value={this.state.user.user_contact_number ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "matchRegexp:^[0-9]{10}$"]}
                            errorMessages={[
                              "This field is required",
                              "Must be a mobile number",
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            PAN<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            name="pan"
                            value={this.state.user.pan ?? ""}
                            onChange={this.handleInputChange}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            validators={[
                              "required",
                              "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
                            ]}
                            errorMessages={[
                              "This field is required",
                              "Must be a PAN number",
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                          <label>
                            Entity Address
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="entity_address"
                            value={this.state.user.entity_address ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <label>
                            GSTIN<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            id="outlined-basic"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="gst"
                            value={this.state.user.gst ?? ""}
                            onChange={this.handleInputChange}
                            validators={[
                              "required",
                              "matchRegexp:^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$",
                            ]}
                            errorMessages={[
                              this.state.user.entity_type === "Branch" && this.state.gstRequired ? "This field is required" : null,
                              "Must be a GSTIN number",
                            ].filter(Boolean)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <label>TAN:</label>
                          <TextValidator
                            id="outlined-basic"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="tan"
                            value={this.state.user.tan ?? ""}
                            onChange={this.handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="business_sector_name"
                            SelectLabel="Business Sector"
                            name="business_sector_id"
                            value={this.state.user.business_sector_id ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={business_sector}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="constitution_name"
                            SelectLabel="Constitution"
                            name="constitution_id"
                            value={this.state.user.constitution_id ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={constitution}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="msme_reference"
                            SelectLabel="MSME"
                            name="msme_flag"
                            value={this.state.user.msme_flag ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={[
                              {
                                id: "YES",
                                msme_reference: "Yes",
                              },
                              {
                                id: "NO",
                                msme_reference: "No",
                              },
                            ]}
                          />
                        </Grid>



                        {this.state.is_cin && (
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ paddingTop: "0px" }}
                          >
                            <label>
                              CIN<span style={{ color: "red" }}>*</span>:
                            </label>
                            <TextValidator
                              name="cin"
                              value={this.state.user.cin ?? ""}
                              onChange={this.handleInputChange}
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                              validators={[
                                "required",
                                "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
                              ]}
                              errorMessages={[
                                "This field is required",
                                "Must be a CIN number",
                              ]}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                          <SelectInput
                            choice_id="id"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            choice_name="entity_reference"
                            SelectLabel="ENTITY TYPE"
                            name="entity_type"
                            value={this.state.user.entity_type ?? ""}
                            textOnchangeFunction={this.handleInputChange}
                            choices={[
                              {
                                id: "Head Office",
                                entity_reference: "Head Office",
                              },
                              {
                                id: "Branch",
                                entity_reference: "Branch",
                              },
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                              <label>
                                Main/Branch Name<span style={{ color: "red" }}>*</span>:
                              </label>

                              <TextValidator
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                name="branch_name"
                                value={this.state.user.branch_name ?? ""}
                                onChange={this.handleInputChange}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </Grid>

                        {this.state.showBranchFields && (
                          <>
                            

                            <Grid item xs={12} sm={3} style={{ paddingTop: "0px", marginTop: '8px' }}>
                              <SelectInput
                                choice_id="business_entity_id"
                                requiredField="*"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_name="entity_name"
                                SelectLabel="Main Branch"
                                name="main_branch"
                                value={this.state.user.main_branch ?? ""}
                                textOnchangeFunction={this.handleInputChange}
                                choices={this.state.entities}
                              />
                            </Grid>
                          </>
                        )}

                        {/* .......................SERVICES (CATEGORY)................. */}
                        <div className="form-header">
                          <Divider />
                          <p>
                            services -{" "}
                            <a
                              href="https://www.cappitallwant.com/"
                              target="_blank "
                              style={{
                                color: "green",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                            >
                              Know more
                            </a>
                          </p>
                          <MyVerticallyCenteredModal
                            show={this.state.modalShow}
                            onHide={this.modalClose}
                          />
                        </div>

                        <Grid item xs={12} sm={12}>
                          <Grid
                            container
                            spacing={2}
                            style={{ padding: "0px" }}
                          >
                            {this.state.categories.map((category, i) => {
                              return (
                                <Grid item sm={4} style={{ padding: "0px" }}>
                                  <FormControlLabel
                                    disableTypography={true}
                                    style={{
                                      marginRight: "0px",
                                    }}
                                    control={
                                      <Checkbox
                                        checked={
                                          this.state.user.categories.indexOf(
                                            category
                                          ) !== -1
                                        }
                                        onChange={this.handleChangeCheckbox}
                                        color="primary"
                                        value={category}
                                      />
                                    }
                                  />
                                  <label
                                    style={{
                                      fontWeight: "450",
                                      position: "relative",
                                      top: "-2px",
                                    }}
                                  >
                                    {category}
                                  </label>
                                </Grid>
                              );
                            })}
                            {/* <Grid item sm={4} style={{ padding: "0px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(3) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="3"
                                  />
                                }
                                label="ARC (Accounting, Reconciliation & Compliance)"
                              />
                            </Grid>

                            <Grid item sm={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(1) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="1"
                                  />
                                }
                                label="Reporting(Dashboard)"
                              />
                            </Grid>
                            <Grid item sm={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.user.services.indexOf(2) !== -1
                                    }
                                    onChange={this.handleChangeCheckbox}
                                    name="services[]"
                                    color="primary"
                                    value="2"
                                  />
                                }
                                label="Revenue Optimization and Financing"
                              />
                            </Grid> */}
                          </Grid>
                        </Grid>
                        {/* ...................END SERVICES................ */}
                        {/* user details */}
                        <div className="form-header">
                          <Divider />
                          <p>User Details</p>
                        </div>
                        <Grid item sm={3}>
                          <label>
                            User Name<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_name"
                            value={this.state.user.user_name ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <label>
                            User Email<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="user_email_id"
                            value={this.state.user.user_email_id ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "This field is required",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <label>
                            Password<span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            type={this.state.showPassword ? "text" : "password"}
                            fullWidth={true}
                            size="small"
                            name="password"
                            variant="outlined"
                            placeholder="eg.Cwn@123"
                            value={this.state.user.password ?? ""}
                            onChange={this.handleInputChange}
                            validators={[
                              "required",
                              "matchRegexp:^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20})$",
                            ]}
                            errorMessages={[
                              "This field is required",
                              "Password must be at least 8 characters,must contain at least one capital character,One small character,One special character(!@#$%^&*) and One number.",
                            ]}
                            // icon for show password

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityOff size="small" />
                                    ) : (
                                      <Visibility size="small" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <label>
                            Confirm Password
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <TextValidator
                            type="password"
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            name="ConfirmPassword"
                            value={this.state.user.ConfirmPassword ?? ""}
                            onChange={this.handleInputChange}
                            validators={["required", "isPasswordMatch"]}
                            errorMessages={[
                              "This field is required",
                              "password mismatch",
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                    <div style={{ padding: "10px", textAlign: "center" }}>
                      <Button
                        size="small"
                        in={true}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                        style={{
                          fontFamily: "Dosis",
                          fontSize: "16px",
                          fontWeight: "550",
                        }}
                      >
                        {this.state.loading && (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={20}
                          />
                        )}
                        {!this.state.loading && "Submit"}
                      </Button>
                      <p
                        className="LoginLink"
                        onClick={this.onLoginPageHandler}
                      >
                        If you already have an account,{" "}
                        <span className="justLogin">just login.</span>
                      </p>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </Grid>
            <Route path={this.props.match.path + "/app/organization"} />
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(useStyles)(Rgister);

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from '../../../../axios-order'
import {
    CircularProgress, RadioGroup, Radio, FormControlLabel, Box, Tab, Paper, Tabs, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TablePagination, Select, MenuItem,
    FormControl, InputLabel, Typography, Button, IconButton, TextField, Popover, Tooltip
} from '@mui/material';
import { LoadingButton, TabContext } from '@mui/lab';
import { styled } from '@mui/material/styles';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


function BpRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const GSTComputationReportDetails = () => {
    let history = useHistory();
    const isMounted = useRef(false);
    const { reconId } = useParams();
    const [value, setValue] = useState('Current Month');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(3);
    const [selectedOption, setSelectedOption] = useState('ITC');
    const [loading, setLoading] = useState(false);
    const [advancedFilter, setAdvancedFilter] = useState(false);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState("");
    const [isSavedItc, setIsSaveItc] = useState(false)
    const [isSavedRcm, setIsSavedRcm] = useState(false)
    const [snackCloseTime, setSnackCloseTime] = useState(5000)

    // State for filter values and conditions
    const [anchorElGstin, setAnchorElGstin] = useState(null);
    const [anchorElLegalName, setAnchorElLegalName] = useState(null);
    const [anchorElDocumentNo, setAnchorElDocumentNo] = useState(null);
    const [filterValueGstin, setFilterValueGstin] = useState('');
    const [filterValueLegalName, setFilterValueLegalName] = useState('');
    const [filterValueDocumentNo, setFilterValueDocumentNo] = useState('');

    const [exportButtonLaoding, setExportButtonLaoding] = useState(false)
    const [isChngesNotSaved, satIsChnagesNotSaved] = useState(false)

    const [itcUtilizedMonthOptions, setItcUtilizedMonthOptions] = useState([])

    useEffect(() => {
        if (isMounted.current) {
            if (isChngesNotSaved) {
                handleItcDataSave()
            }
            setData([]);
            setPage(0);
            setTotalRows(0);
            setRowsPerPage(5);
            getGstComputationDetailedData();
        } else {
            isMounted.current = true;
        }
    }, [value, selectedOption, advancedFilter]);

    useEffect(() => {
        if (isChngesNotSaved) {
            handleItcDataSave()
        }
        getGstComputationDetailedData()
    }, [page, rowsPerPage, filterValueGstin, filterValueLegalName, filterValueDocumentNo])

    const handleFilterIconClick = (column, event) => {
        if (column === 'gstin') setAnchorElGstin(event.currentTarget);
        if (column === 'legalName') setAnchorElLegalName(event.currentTarget);
        if (column === 'documentNo') setAnchorElDocumentNo(event.currentTarget);
    };

    const handlePopoverClose = (column) => {
        if (column === 'gstin') setAnchorElGstin(null);
        if (column === 'legalName') setAnchorElLegalName(null);
        if (column === 'documentNo') setAnchorElDocumentNo(null);
    };

    const handleFilterValueChange = (column, e) => {
        if (column === 'gstin') setFilterValueGstin(e.target.value);
        if (column === 'legalName') setFilterValueLegalName(e.target.value);
        if (column === 'documentNo') setFilterValueDocumentNo(e.target.value);
    };


    const handleSnackbarOpen = (message, severity) => {
        setSnackbarSeverity(severity);
        setResponseMessage(message);
        setSnackbarOpen(true);


        // setSnackbarOpen(false);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity(snackbarSeverity);
        setResponseMessage("");

    };


    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleItcMonthChange = (event, rowIndex) => {
        satIsChnagesNotSaved(true)
        const updatedRows = [...data];
        updatedRows[rowIndex].itc_utilized_month = event.target.value;
        setData(updatedRows);
    };


    const handleComputationReport = () => {
        setSnackCloseTime(5000)
        if (!isSavedItc) {
            handleSnackbarOpen('Please save the ITC data before proceeding to the Computation Report.', 'success')
            return
        } else if (!isSavedRcm) {
            handleSnackbarOpen('Please save the RCM data before proceeding to the Computation Report.', 'success')
            return
        }

        history.push({
            pathname: `/gst_computation_report_details_data/${reconId}`
        })

    }


    const getGstComputationDetailedData = () => {
        setLoading(true)
        const payLoadData = {
            business_entity_id: localStorage.getItem('business_entity_id'),
            summary_id: reconId,
            page: page + 1,
            limit: rowsPerPage,
            main_type: selectedOption,
            sub_type: value,
            search_gstin: filterValueGstin,
            search_legal_name: filterValueLegalName,
            search_document_number: filterValueDocumentNo
        }

        axios.post('/get_itc_exact_equal_computation_report_data', payLoadData)
            .then((response) => {
                if (response.status) {

                    setData(response.data.records);
                    setTotalRows(response.data.total);
                    setItcUtilizedMonthOptions(response.data.itc_utilized_month_options)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };


    const handleItcDataSave = () => {

        setSaveButtonLoading(true)
        const payLoadData = {
            business_entity_id: localStorage.getItem('business_entity_id'),
            records: data,
            is_changed: isChngesNotSaved
        }
        axios.post('/save_itc_utilized_records', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    setSnackCloseTime(1500)
                    handleSnackbarOpen(`${selectedOption} utilized records saved successfully.`, 'success')
                } else {
                    handleSnackbarOpen('Save failed. Please try again.', 'error');
                }
            }).catch((err) => {
                handleSnackbarOpen('An error occurred while saving the data.', 'error');
            }).finally(() => {
                setSaveButtonLoading(false)
                if (selectedOption === "ITC") {
                    setIsSaveItc(true)
                } else {
                    setIsSavedRcm(true)
                }
                satIsChnagesNotSaved(false)
            })
    }

    const handleExportExcel = () => {
        setExportButtonLaoding(true)
        const payLoadData = {
            'summary_id': reconId,
            'business_entity_id': localStorage.getItem("business_entity_id"),
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `GST Computation Report.xlsx`;

        axios.post('/computation_excel_report', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);


            }).catch((err) => {

            })
            .finally(() => {
                setExportButtonLaoding(false)
            })
    }


    const numberFormat = (value) => {
        if (!value || value === null || value === "") {
            return "₹ 0";
        }

        const numberValue = parseFloat(value);

        if (!isNaN(numberValue)) {
            return new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                maximumSignificantDigits: 10,
            }).format(numberValue);
        } else {
            return "₹ 0";
        }
    };

    const renderFilterPopover = (anchorEl, column, filterValue, setFilterValue) => {
        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => handlePopoverClose(column)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <TextField
                    value={filterValue}
                    onChange={(e) => handleFilterValueChange(column, e)}
                    placeholder={`Filter by ${column}`}
                    size="small"
                    sx={{ margin: '8px', width: '200px' }}
                />
            </Popover>
        );
    };


    const DetailsTable = () => {
        // const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '10px', borderRadius: '12px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <TableContainer sx={{
                    overflowX: 'auto',

                    maxHeight: '48vh',
                    '&::-webkit-scrollbar': {
                        width: '8px', // Reduced width for vertical scrollbar
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1', // Light background for the track
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888', // Thumb color
                        borderRadius: '10px', // Rounded edges for the thumb
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Darker color on hover
                    },
                }}>
                    <Table sx={{ minWidth: '100%' }} size="medium">
                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                            <TableRow>
                                <TableCell sx={{ width: '12%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>
                                    <span>GSTIN</span>
                                    <IconButton
                                        onClick={(e) => handleFilterIconClick('gstin', e)}
                                        size="small"
                                        sx={{
                                            marginLeft: '4px',
                                            color: filterValueGstin ? '#2196f3' : '#000000'
                                        }}
                                    >
                                        <FilterAltIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>

                                <TableCell sx={{ width: '12%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>
                                    <span>Legal Name</span>
                                    {/* Legal Name */}
                                    <IconButton
                                        onClick={(e) => handleFilterIconClick('legalName', e)}
                                        size="small"
                                        sx={{
                                            marginLeft: '4px',
                                            color: filterValueLegalName ? '#2196f3' : '#000000'
                                        }}
                                    >
                                        <FilterAltIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>GST INVOICE TYPE</TableCell>
                                <TableCell sx={{ width: '12%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>
                                    <span>Document No</span>
                                    {/* Document No */}

                                    <IconButton
                                        onClick={(e) => handleFilterIconClick('documentNo', e)}
                                        size="small"
                                        sx={{
                                            marginLeft: '4px',
                                            color: filterValueDocumentNo ? '#2196f3' : '#000000'
                                        }}
                                    >
                                        <FilterAltIcon fontSize="small" />
                                    </IconButton>

                                </TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Document Date</TableCell>
                                <TableCell sx={{ width: '10%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Booking Date</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>IGST</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>CGST</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>SGST</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Taxable Value</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Total GST</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Total Amount</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>Match Result</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>RCM Status</TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>ITC Status</TableCell>
                                <TableCell sx={{ width: '10%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>ITC Available Month</TableCell>
                                {(value === "Other Than Current Month" || value === "Missing in Books" || value === "unavailed") && <TableCell sx={{ width: '10%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>ITC Utilized Month</TableCell>}
                                {/* <TableCell sx={{ width: '10%', textAlign: 'center', fontWeight: 600, fontSize: '14px', textTransform: 'uppercase', color: '#333', whiteSpace: 'nowrap', padding: '10px' }}>ITC Month</TableCell> */}

                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowY: 'auto' }}>
                            {loading ? (
                                <TableRow >
                                    <TableCell colSpan={16} align="center" sx={{ padding: '8px' }}>
                                        <CircularProgress sx={{ color: '#1976d2' }} />
                                    </TableCell>
                                </TableRow>
                            ) : (data && data.length === 0) ? (
                                <TableRow >
                                    <TableCell colSpan={16} align="center" sx={{ padding: '16px', color: '#757575' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data?.map((row, index) => (
                                    <TableRow key={row.document_no} sx={{ '&:hover': { backgroundColor: '#e1f5fe', cursor: 'pointer' } }}>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.cust_gstin}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.cust_legal_name}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.gst_invoice_type}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.document_no}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.document_date}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.booking_date}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.igst)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.cgst)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.sgst)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.taxable_value)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.total_gst)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{numberFormat(row.total_amount)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.match_result}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.final_rcm_status}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.final_itc_status}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.itc_available_month}</TableCell>
                                        {(value === "Other Than Current Month" || value === "Missing in Books" || value === "unavailed") && (
                                            <TableCell sx={{ textAlign: 'center', borderBottom: '1px solid #ddd', verticalAlign: 'middle' }}>
                                                {(value !== "Missing in Books") ? (
                                                    <span>{row.itc_utilized_month}</span>
                                                ) : (
                                                    <FormControl sx={{ maxWidth: 120, height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Select
                                                            value={row.itc_utilized_month ? row.itc_utilized_month : "None"}
                                                            onChange={(e) => handleItcMonthChange(e, index)}
                                                            displayEmpty
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                fontSize: '14px',
                                                                height: '30px',
                                                                padding: '2px 10px',
                                                                borderRadius: '5px',
                                                                '&:focus': { borderColor: '#1976d2' },
                                                            }}
                                                        >
                                                            {
                                                                row.block_utilized == "Y" ?
                                                                    <MenuItem
                                                                        key={row.itc_utilized_month}
                                                                        value={row.itc_utilized_month}
                                                                    >
                                                                        {row.itc_utilized_month}
                                                                    </MenuItem>
                                                                    :
                                                                    itcUtilizedMonthOptions.map((month) => (
                                                                <MenuItem key={month.id} value={month.month}>
                                                                    {month.month}
                                                                </MenuItem>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </TableCell>
                                        )}

                                        {/* <TableCell sx={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>{row.itc_month}</TableCell> */}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        backgroundColor: '#f5f5f5',
                        borderTop: '1px solid #ddd',
                        position: 'sticky',
                        bottom: 0,
                        '& .MuiTablePagination-selectIcon': {
                            color: '#1976d2',
                        },
                        '& .MuiTablePagination-actions button': {
                            color: '#1976d2',
                        },
                    }}
                />
            </Paper>
        );
    };



    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Box sx={{ display: 'flex', marginBottom: '13px' }}>
                    {/* Select Box */}
                    <FormControl sx={{ width: 'auto' }}>
                        <InputLabel id="select-option-label" sx={{
                            width: '100%', fontFamily: '"Poppins", sans-serif',
                            fontSize: '15px',
                            fontWeight: '600',
                        }} shrink={true}>
                            Report
                        </InputLabel>
                        <RadioGroup

                            aria-labelledby="select-option-label"
                            name="report-option"
                            value={selectedOption}
                            onChange={handleSelectChange}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row', // Change from 'column' to 'row' for side-by-side layout
                                gap: '10px', // Adds some space between the radio buttons
                                padding: '9px 20px',
                                backgroundColor: '#ffffff',
                                borderRadius: '10px',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease',
                            }}
                        >
                            <FormControlLabel
                                value="ITC"
                                control={<BpRadio />}
                                label="ITC"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: '"Poppins", sans-serif',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        color: '#444',
                                        letterSpacing: '0.8px',
                                        textTransform: 'uppercase',
                                        lineHeight: '1.6',
                                        textShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        '&:hover': {
                                            color: '#1976d2',
                                        },
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="RCM"
                                control={<BpRadio />}
                                label="RCM"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: '"Poppins", sans-serif',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        color: '#444',
                                        letterSpacing: '0.8px',
                                        textTransform: 'uppercase',
                                        lineHeight: '1.6',
                                        textShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        '&:hover': {
                                            color: '#1976d2',
                                        },
                                    },
                                }}
                            />
                        </RadioGroup>
                    </FormControl>


                    {/* Heading for the report */}
                    <Typography variant="h5" sx={{
                        fontWeight: '600',
                        color: '#212121',
                        letterSpacing: '0.5px',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        fontSize: '24px',

                        flexGrow: 10,
                        padding: '0 20px',
                        fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
                        background: 'linear-gradient(45deg, #3f51b5, #2196f3)', // Gradient text effect
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
                        letterSpacing: '0.8px',

                    }}>
                        GST Computation Detailed Report
                        <BubbleChartIcon sx={{
                            marginLeft: '8px',
                            fontSize: '1.2rem',
                            verticalAlign: 'middle',
                            color: '#2196f3', // Apply color to the icon
                        }} />
                    </Typography>


                    <Box>
                        <Tooltip title="Back" arrow>

                            <IconButton
                                // color='warning'
                                onClick={() => history.goBack()}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    color: '#263238',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#455a64',
                                        color: '#ffffff',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>

                        </Tooltip>

                        {/* Round Export Icon Button */}
                        <Tooltip title="Export" arrow>

                            <IconButton
                                color='success'
                                onClick={() => { if (!exportButtonLaoding) handleExportExcel(); }}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#ccff90',
                                        color: '#000000',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                {exportButtonLaoding ? <CircularProgress sx={{ color: '#1976d2', size: 20 }} size={20} />
                                    : <img
                                        style={{ height: 24, width: 24, objectFit: 'contain' }}
                                        src='https://img.icons8.com/?size=48&id=117561&format=png'
                                        alt="Export Icon"
                                    />}
                            </IconButton>

                        </Tooltip>
                    </Box>

                    {/* Round Save Icon Button */}
                    {(value === "Missing in Books") && <Box>
                        <Tooltip title={`Confirm ${selectedOption}`} arrow>

                            <IconButton
                                color="primary"
                                onClick={() => { if (!saveButtonLoading) handleItcDataSave(); }}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                        color: '#ffffff',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                {saveButtonLoading ? <CircularProgress sx={{ color: '#1976d2', size: 20 }} size={20} /> : <SaveIcon />}
                            </IconButton>

                        </Tooltip>

                        {/* Round Computation Icon Button */}
                        <Tooltip title="Computation" arrow>

                            <IconButton
                                // color='info'
                                onClick={handleComputationReport}
                                sx={{
                                    borderRadius: '50%',  // Round button
                                    padding: '8px',
                                    color: '#795548',
                                    fontSize: '24px',
                                    marginRight: '15px',
                                    backgroundColor: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#795548',
                                        color: '#ffffff',
                                    },
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <AnalyticsIcon />
                            </IconButton>

                        </Tooltip>
                    </Box>}

                </Box>


                {/* Tabs on the next line, centered */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <TabContext value={value}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            sx={{
                                borderBottom: '2px solid #FFD700',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '4px',
                                padding: '8px 0',
                            }}
                        >
                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        CURRENT MONTH
                                        <EventAvailableIcon sx={{ marginLeft: 1 }} />
                                    </Box>
                                }
                                value="Current Month"
                                sx={{
                                    color: value === "Current Month" ? '#ffffff' : '#757575',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    padding: '12px 24px',
                                    borderRadius: '8px',
                                    backgroundColor: value === "Current Month" ? 'linear-gradient(135deg, #F8B400, #FFD700)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: value === "Current Month" ? '#1976d2' : '#80d8ff',
                                        transition: 'background-color 0.3s ease',
                                    },
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        backgroundColor: '#1976d2',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s ease',
                                    },

                                }}
                            />
                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {/* Icon for Other Than Current Month */}
                                        OTHER THAN CURRENT MONTH
                                        <CalendarMonthIcon sx={{ marginLeft: 1 }} />
                                    </Box>
                                }
                                value="Other Than Current Month"
                                sx={{
                                    color: value === "Other Than Current Month" ? '#ffffff' : '#757575',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    padding: '12px 24px',
                                    borderRadius: '8px',
                                    backgroundColor: value === "Other Than Current Month" ? '#1976d2' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: value === "Other Than Current Month" ? '#1565c0' : '#80d8ff',
                                        transition: 'background-color 0.3s ease',
                                    },
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        backgroundColor: '#1976d2',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s ease',
                                    },

                                }}
                            />

                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        ITC UNAVAILED
                                        <SpaceDashboardIcon sx={{ marginLeft: 1 }} />
                                    </Box>
                                }
                                value="unavailed"
                                sx={{
                                    color: value === "unavailed" ? '#ffffff' : '#757575',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    padding: '12px 24px',
                                    borderRadius: '8px',
                                    backgroundColor: value === "unavailed" ? 'linear-gradient(135deg, #F8B400, #FFD700)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: value === "unavailed" ? '#1976d2' : '#80d8ff',
                                        transition: 'background-color 0.3s ease',
                                    },
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        backgroundColor: '#1976d2',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s ease',
                                    },

                                }}
                            />

                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {/* Icon for Other Than Current Month */}
                                        MISSING IN BOOKS
                                        <EditCalendarIcon sx={{ marginLeft: 1 }} />
                                    </Box>
                                }
                                value="Missing in Books"
                                sx={{
                                    color: value === "Missing in Books" ? '#ffffff' : '#757575',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    padding: '12px 24px',
                                    borderRadius: '8px',
                                    backgroundColor: value === "Missing in Books" ? '#1976d2' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: value === "Missing in Books" ? '#1565c0' : '#80d8ff',
                                        transition: 'background-color 0.3s ease',
                                    },
                                    '&.Mui-selected': {
                                        color: '#ffffff',
                                        backgroundColor: '#1976d2',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s ease',
                                    },

                                }}
                            />
                        </Tabs>
                    </TabContext>
                </Box>

                {/* Table Component */}
                {DetailsTable()}
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={snackbarOpen}
                    autoHideDuration={snackCloseTime}
                    onClose={handleSnackbarClose}
                    style={{ maxWidth: '25%' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                    >
                        {responseMessage}
                        {message}
                    </MuiAlert>
                </Snackbar>
            </Box>
            {renderFilterPopover(anchorElGstin, 'gstin', filterValueGstin, setFilterValueGstin)}
            {renderFilterPopover(anchorElLegalName, 'legalName', filterValueLegalName, setFilterValueLegalName)}
            {renderFilterPopover(anchorElDocumentNo, 'documentNo', filterValueDocumentNo, setFilterValueDocumentNo)}
        </>
    );
};

export default GSTComputationReportDetails;

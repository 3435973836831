import React, { Component } from "react";
import classes from "./Vender.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import VenderMaster from "../../../../asserts/templates/vendor_management.xlsx";
import TextField from "@material-ui/core/TextField";

import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import SelectField from "../../../../components/ui/select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomTable from "../../../../components/ui/Table/Table";
import { TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";

import ZohoModel from "../../../../components/ZohoModel/ZohoModel";

let flagForVendorMang = 1;
let variableForTimerForZoho = null;

const indianFormat = (date) => {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};
class VendorManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      effitracCol: [
        {
          key: "Venodr Name",
          render: (index, row) => {
            return row["venodrName"];
          },
        },
        {
          key: "Vendor Code+",
          render: (index, row) => {
            return row["erpVendorCode"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["vendorGstin"];
          },
        },
        {
          key: "pan",
          render: (index, row) => {
            return row["vendorPan"];
          },
        },

        {
          key: "tan",
          render: (index, row) => {
            return row["vendorTan"];
          },
        },
        {
          key: "tdsPercentage",
          render: (index, row) => {
            return row["tdsPercentage"];
          },
        },
      ],
      effitracData: null,
      oldEffitracData: null,
      effitracHeader: [
        { label: "Vendor Name" },
        { label: "Vendor Code+" },
        { label: "GSTIN" },
        { label: "PAN" },

        { label: "TAN" },
        { label: "TDS %" },
      ],
      read_only: "",
      actionHeaderForZoho: [
        { id: "country", name: "Country" },
        { id: "state", name: "State" },
        { id: "contactName", name: "Contact Name" },
        { id: "contactNumber", name: "Contact Number" },
        { id: "vendorEmail", name: "Email" },
        { id: "address", name: "Address" },
        { id: "vendorPan", name: "PAN" },
        { id: "vendorTan", name: "Tan" },
        { id: "tcsPercentage", name: "TCS %" },
        { id: "gstRegistrationType", name: "GST Registration Type" },
        { id: "purchaseManagerName", name: "Purchase Manager Name" },
        {
          id: "purchaseManagerContactNumber",
          name: "Purchase Manager Contact Number",
        },
        { id: "purchaseManagerEmail", name: "Purchase Manager Email" },
        { id: "creditDays", name: "Credit Days" },
        { id: "thresholdLimitFlag", name: "TDS Threshold Limit Applicability" },
        { id: "residentFlag", name: "Resident" },
        { id: "section206ABFlag", name: "Section 206AB" },
        { id: "comments", name: "Comments" },
      ],
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["vendorGstin"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,

      allArcCustomerDataPurchase: [],
      OriginalDataAllArcCustomerDataPurchase: [],
      showDrawer: false,
      showData: [],
      showUploadData: false,
      loading: false,

      add_DrawerOpen: false,
      addCustomerData: {
        erpVendorCode: "",
        address: "",
        contactName: "",
        contactNumber: "",
        vendorEmail: "",
        vendorTan: "",
        purchaseManagerName: "",
        purchaseManagerContactNumber: "",
        purchaseManagerEmail: "",
        gstRegistrationType: "",
        tcsPercentage: null,
        tdsPercentage: null,
        thresholdLimitFlag: "",
        residentFlag: "",
        comments: "",
        vendorGstin: "",
        creditDays: null,
        vendorPan: "",
        section206ABFlag: "",
      },

      //..............Edited by Harsh............
      openZohoDrawer: false,
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      grantToken: "",
      url: "",
      zohoDate: {},

      edit_DrawerOpen: false,
      editCustomerData: {},

      openImportEffitracDrawer: false,
      importEffitracFetchData: {},
      effitracFetchedRecords: {},
      fetrac: "",
      actions: [
        {
          label: "Show",
          onclickEvent: (index, row) => this.openModal(index),
        },
      ],
      effitracInvShow: false,
      targetId: null,
      tcsPercentage: 0,
      tdsPercentageKey: true,
      tcsPercentageKey: false,

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",

      // upload history table data
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      flagForZoho: false,
      timerForZoho: 60,
      loaderForVendorMgmt: true,
      // variableForTimerForZoho:null ,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getPurchaseDataApi(event, this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.getPurchaseDataApi(event, this.state.searchInput);
    });
  };

  // table serach
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value, page: 0 }, () => {
      this.getPurchaseDataApi(event, this.state.searchInput);
    });
  };

  openModal = (index) => {
    this.setState(
      {
        targetId: index,
      },
      () => {
        this.setState({
          effitracInvShow: true,
        });
      }
    );
  };

  closeEffitracInvShowHandler = () => {
    this.setState({
      effitracInvShow: false,
      targetId: null,
    });
  };

  getPurchaseDataApi = (event, serachIn) => {
    axios
      .post(`/get_vendor_master_details`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_text: serachIn,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcCustomerDataPurchase: res.data.record,
          OriginalDataAllArcCustomerDataPurchase: res.data.record,
          rowCount: res.data.row_count,
          loaderForVendorMgmt: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSellercustomerLastUploadDate = () => {
    const customer = "Seller Customer";
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/${customer}`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = indianFormat(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDate: Date,
          lastUploadTime: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        console.log(res.data);
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${11}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });

    let drawer = domain.get("drawer");
    drawer &&
      this.setState({
        openZohoDrawer: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);
    console.log(url.pathname);

    this.getApiForClientID();
    this.getPurchaseDataApi();
    this.getSellercustomerLastUploadDate();
    this.getHistoryTableUploadData();
  }

  componentDidUpdate() {
    if (this.state.buttonColor === "green" && !this.state.flagForZoho) {
      this.setState({ flagForZoho: true });
      variableForTimerForZoho = setInterval(() => {
        console.log("run");
        this.setState((prevstate) => ({
          timerForZoho: prevstate.timerForZoho - 1,
        }));
      }, 1000);
    }

    if (this.state.timerForZoho === 0) {
      clearInterval(variableForTimerForZoho);
      this.setState({
        flagForZoho: false,
        timerForZoho: 60,
        buttonColor: "red",
      });
    }
  }

  //Search field---
  handleSearchInputChangeFroEffitrackAndZoho = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    let searchAdminData = this.state.oldEffitracData.filter((value) => {
      const query = searchInput.trim().toLowerCase();
      return (
        String(value.erpVendorCode).toLowerCase().indexOf(query) >= 0 ||
        String(value.venodrName).toLowerCase().indexOf(query) >= 0 ||
        String(value.vendorGstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.vendorPan).toLowerCase().indexOf(query) >= 0 ||
        String(value.vendorTan).toLowerCase().indexOf(query) >= 0 ||
        String(value.tdsPercentage).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ effitracData: searchAdminData });
  };
  // End---

  //file upload handler---
  fileUploader = (event, serachIn) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);

    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendormaster`, {
          file: file_value,
          entityGst: localStorage.getItem("gst"),
        })
        .then((data) => {
          dis.getHistoryTableUploadData();
          dis.setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
        });
    };
  };

  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.getPurchaseDataApi();
  };

  // file upload button---
  CollectionListActions = () => (
    <div>
      <a
        href={VenderMaster}
        download={"vendor_management.xlsx"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        UPLOAD TEMPLATE{" "}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>

      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
    </div>
  );

  // arc customer table header---
  arc_Customer_TableHeader() {
    const tableHeader = [
      "Vendor Name",
      "ERP Vendor Code",
      "GSTIN",
      "Pan",
      "Tan",
      "TDS %",
      // 'action',
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_Customer__data() {
    const showToggleDrawer = (
      purchaseManagerEmail,
      vendorEmail,
      residentFlag,
      gstRegistrationType,
      address,
      created_date,
      contactNumber,
      purchaseManagerName,
      vendorPan,
      comments,
      vendorCode,
      country,
      state,
      creditDays,
      thresholdLimitFlag,
      erpVendorCode,
      modified_date,
      vendorGstin,
      venodrName,
      purchaseManagerContactNumber,
      vendorTan,
      contactName,
      tdsPercentage,
      tcsPercentage,
      orgCode,
      section206ABFlag
    ) => {
      this.setState({
        showData: {
          address: address,
          contactName: contactName,
          contactNumber: contactNumber,
          vendorEmail: vendorEmail,
          vendorPan: vendorPan,
          vendorTan: vendorTan,
          purchaseManagerName: purchaseManagerName,
          purchaseManagerContactNumber: purchaseManagerContactNumber,
          purchaseManagerEmail: purchaseManagerEmail,
          creditDays: creditDays,
          gstRegistrationType: gstRegistrationType,
          tcsPercentage: tcsPercentage,
          thresholdLimitFlag: thresholdLimitFlag,
          residentFlag: residentFlag,
          comments: comments,
          create_date: created_date,
          modify_date: modified_date,
          country: country,
          state: state,
          section206ABFlag: section206ABFlag,
        },
        showDrawer: true,
      });
    };
    // edit ----
    const editARCCustomer = (
      purchaseManagerEmail,
      vendorEmail,
      residentFlag,
      gstRegistrationType,
      address,
      created_date,
      contactNumber,
      purchaseManagerName,
      vendorPan,
      comments,
      vendorCode,
      country,
      state,
      creditDays,
      thresholdLimitFlag,
      erpVendorCode,
      modified_date,
      vendorGstin,
      venodrName,
      purchaseManagerContactNumber,
      vendorTan,
      contactName,
      tdsPercentage,
      tcsPercentage,
      orgCode,
      section206ABFlag
    ) => {
      this.setState({
        editCustomerData: {
          venodrName: venodrName,
          erpVendorCode: erpVendorCode,
          country: country,
          state: state,
          address: address,
          contactName: contactName,
          contactNumber: contactNumber,
          vendorEmail: vendorEmail,
          vendorGstin: vendorGstin,
          vendorPan: vendorPan,
          vendorTan: vendorTan,

          purchaseManagerName: purchaseManagerName,
          purchaseManagerContactNumber: purchaseManagerContactNumber,
          purchaseManagerEmail: purchaseManagerEmail,
          creditDays: creditDays,
          gstRegistrationType: gstRegistrationType,

          tcsPercentage: tcsPercentage,
          tdsPercentage: tdsPercentage,

          thresholdLimitFlag: thresholdLimitFlag,
          residentFlag: residentFlag,
          comments: comments,
          section206ABFlag: section206ABFlag,
        },
        edit_DrawerOpen: true,
      });
    };

    if (
      this.state.allArcCustomerDataPurchase &&
      this.state.allArcCustomerDataPurchase.length >= 1
    ) {
      return this.state.allArcCustomerDataPurchase.map((tableData, index) => {
        const {
          purchaseManagerEmail,
          vendorEmail,
          residentFlag,
          gstRegistrationType,
          address,
          created_date,
          contactNumber,
          purchaseManagerName,
          vendorPan,
          comments,
          vendorCode,
          country,
          state,
          creditDays,
          thresholdLimitFlag,
          erpVendorCode,
          modified_date,
          vendorGstin,
          venodrName,
          purchaseManagerContactNumber,
          vendorTan,
          contactName,
          tdsPercentage,
          tcsPercentage,
          orgCode,
          section206ABFlag,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{venodrName}</td>
            <td>{erpVendorCode}</td>
            <td>{vendorGstin}</td>
            <td>{vendorPan}</td>
            <td>{vendorTan}</td>
            <td>{tdsPercentage}</td>
            {/* <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editARCCustomer(
                    purchaseManagerEmail,
                    vendorEmail,
                    residentFlag,
                    gstRegistrationType,
                    address,
                    createDate,
                    contactNumber,
                    purchaseManagerName,
                    vendorPan,
                    comments,
                    vendorCode,
                    country,
                    state,
                    creditDays,
                    thresholdLimitFlag,
                    erpVendorCode,
                    modifyDate,
                    vendorGstin,
                    venodrName,
                    purchaseManagerContactNumber,
                    vendorTan,
                    contactName,
                    tdsPercentage,
                    tcsPercentage,
                    orgCode,
                    section206ABFlag
                  )
                }
              >
                edit
              </Button>
            </td> */}
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showToggleDrawer(
                    purchaseManagerEmail,
                    vendorEmail,
                    residentFlag,
                    gstRegistrationType,
                    address,
                    created_date,
                    contactNumber,
                    purchaseManagerName,
                    vendorPan,
                    comments,
                    vendorCode,
                    country,
                    state,
                    creditDays,
                    thresholdLimitFlag,
                    erpVendorCode,
                    modified_date,
                    vendorGstin,
                    venodrName,
                    purchaseManagerContactNumber,
                    vendorTan,
                    contactName,
                    tdsPercentage,
                    tcsPercentage,
                    orgCode,
                    section206ABFlag
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loaderForVendorMgmt) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // -------------------------------------arc customer table end---------------------------

  // show drawer close handler---
  showToggleDrawerClose = (event) => {
    this.setState({ showDrawer: false });
  };

  // file upload response data handler---
  closeUploadData = () => {
    this.setState({ showUploadData: false });
    this.getPurchaseDataApi();
  };
  // ------------------------add customer-----------------------
  // add customer[add button] drawer open handeler---
  addCustomer_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // add customer inputfield handler---
  addCustomer_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "tdsPercentage" || name === "tcsPercentage") {
      if (value > 100)
        if (name === "tdsPercentage") {
          this.setState({ buttonDisabled: true });
        } else {
          this.setState({ buttonDisabled: true });
        }
      else {
        this.setState({ buttonDisabled: false });
      }
    }

    this.setState((prevState) => ({
      addCustomerData: {
        ...prevState.addCustomerData,
        [name]: String(value),
      },
    }));
  };

  // add customer input field handler[number format]
  addCustomer_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addCustomerData: {
        ...prevState.addCustomerData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add customer add submit handler
  addCustomer_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/vendor/vendormaster`, {
        entityGst: localStorage.getItem("gst"),
        record: this.state.addCustomerData,
      })
      .then((res) => {
        if (res.data.status === false) {
          let error2 = res.data.message;
          let error = "";
          {
            error2
              ? toast.error(error2, { autoClose: 2000 })
              : Object.keys(res.data.record.details[0].errors_in).map(
                  (v, i) => {
                    return (error +=
                      String(v) +
                      " " +
                      res.data.record.details[0].errors_in[v]);
                  }
                );
          }
          if (error) {
            toast.error(error, { autoClose: 2000 });
            this.setState({
              add_DrawerOpen: false,
              addCustomerData: {
                erpVendorCode: "",
                address: "",
                contactName: "",
                contactNumber: "",
                vendorEmail: "",
                vendorTan: "",
                purchaseManagerName: "",
                purchaseManagerContactNumber: "",
                purchaseManagerEmail: "",
                gstRegistrationType: "",
                tcsPercentage: null,
                tdsPercentage: null,
                thresholdLimitFlag: "",
                residentFlag: "",
                comments: "",
                vendorGstin: "",
                creditDays: null,
                vendorPan: "",
              },
            });
          }
        } else {
          this.getPurchaseDataApi();
          this.setState({
            add_DrawerOpen: false,
            addCustomerData: {
              erpVendorCode: "",
              address: "",
              contactName: "",
              contactNumber: "",
              vendorEmail: "",
              vendorTan: "",
              purchaseManagerName: "",
              purchaseManagerContactNumber: "",
              purchaseManagerEmail: "",
              gstRegistrationType: "",
              tcsPercentage: null,
              tdsPercentage: null,
              thresholdLimitFlag: "",
              residentFlag: "",
              comments: "",
              vendorGstin: "",
              creditDays: null,
              vendorPan: "",
            },
          });
          toast.success("Vendor added successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // add customer drawer close handler---
  add_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addCustomerData: {
        erpVendorCode: "",
        address: "",
        contactName: "",
        contactNumber: "",
        vendorEmail: "",
        vendorTan: "",
        purchaseManagerName: "",
        purchaseManagerContactNumber: "",
        purchaseManagerEmail: "",
        gstRegistrationType: "",
        tcsPercentage: null,
        tdsPercentage: null,
        thresholdLimitFlag: "",
        residentFlag: "",
        comments: "",
        vendorGstin: "",
        creditDays: null,
        vendorPan: "",
      },
    });
  };

  // edit customer[edit button] drawer open handeler---
  editCustomer_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // edit customer inputfield handler---
  editCustomer_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "tdsPercentage" || name === "tcsPercentage") {
      if (value > 100)
        if (name === "tdsPercentage") {
          this.setState({ buttonDisabled: true });
        } else {
          this.setState({ buttonDisabled: true });
        }
      else {
        this.setState({ buttonDisabled: false });
      }
    }

    this.setState((prevState) => ({
      editCustomerData: {
        ...prevState.editCustomerData,
        [name]: String(value),
      },
    }));
  };

  editCustomer_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editCustomerData: {
        ...prevState.editCustomerData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit customer edit submit handler
  editCustomer_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/vendor/vendormaster`, {
        entityGst: localStorage.getItem("gst"),
        record: this.state.editCustomerData,
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = res.data.message;
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.getPurchaseDataApi();
          this.setState({ edit_DrawerOpen: false });
          toast.success("Vendor updated successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        // alert(error);
        this.setState({ edit_DrawerOpen: false });
      });
  };

  // edit customer drawer close handler---
  edit_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false });
  };
  // ------------------------add customer end-----------------------

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = (dataformfun) => {
    toast.configure();
    // export to excel for both
    let data = dataformfun;
    console.log(data);
    // if (this.state.isEffitrac) {
    //   data = this.state.effitracData;
    // } else {
    //   data = this.state.allArcCustomerDataPurchase;
    // }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendor_management.xlsx`;
    axios
      .post(`/vendor/vendormasterdownload`, {
        data: data,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        if (error.response.data.detail) {
          toast.error(error?.response?.data?.detail, { autoClose: 2000 });
        } else {
          toast.error("Server Error Try After Some Time", { autoClose: 2000 });
        }

        console.log(error);
      });
  };

  // import effitrac button onclick[from handler]
  import_Effitrac_tally = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importEffitracCreatedFromDateChangeHandler = (v) => {
    let dateAndTime = v.toISOString().replace("Z", "");
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        vendor_last_modified_time: dateAndTime,
      },
    }));
  };

  importEffitracCreatedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        vendorBillsToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  fetchEffitacFormSubmitHandler = () => {
    toast.configure();
    this.setState({ effitracImportFormloader: true });
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      },
    }));
    const data = this.state.importEffitracFetchData;
    axios
      .post("/arc/effitrac/customerdataexport", data)
      .then((res) => {
        this.setState({
          isEffitrac: true,
          effitracData: res.data.records.records,
          oldEffitracData: res.data.records.records,
          importEffitracFetchData: {},
          openImportEffitracDrawer: false,
          effitracImportFormloader: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ effitracImportFormloader: false });
      });
  };

  closeEffitracDrawer = () => {
    this.setState({
      openImportEffitracDrawer: false,
      importEffitracFetchData: {},
      effitracImportFormloader: false,
    });
  };

  closeEffitracInvShowHandler = () => {
    this.setState({
      effitracInvShow: false,
      targetId: null,
    });
  };

  // import to cws button onclick--
  importToCWS = (data) => {
    toast.configure();

    axios
      .post("/arc/effitrac/vendormaster", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: data,
      })
      .then((res) => {
        this.setState({
          showUploadHistroyTable: true,
        });
        this.refreshUploadHistoryTable();
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });

        // edited by harsh json formate had been change Status Code: 204 NO CONTENT

        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status == "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),

        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        toast.error(error.response.data.messge, {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // back button after import effitrac data
  backHandler = () => {
    this.setState({ isEffitrac: false, showUploadHistroyTable: false });
  };

  //...............Edited By Harsh Handle Drawer.........
  handleZohoDrawer = () => {
    flagForVendorMang = 1;
    this.setState({ openZohoDrawer: true });
  };

  closeZohoDrawer = () => {
    clearInterval(variableForTimerForZoho);
    this.setState({
      zohoDate: {},
      openZohoDrawer: false,
      effitracImportFormloader: false,
      buttonColor: "red",
      messageForConnection: "Establish Connection",
      grantToken: "",
    });
  };

  establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.settings.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${
      this.state.url
    }/zohoTesting&access_type=offline&prompt=Consent`;
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else if (name === "created_to_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else if (name === "modified_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    }
  };

  fetchingdataForZoho = () => {
    clearInterval(variableForTimerForZoho);
    flagForVendorMang += 1;
    toast.configure();

    if (flagForVendorMang === 2) {
      this.setState({ effitracImportFormloader: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "purchase_vendor",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              zohoDate: {},
              effitracImportFormloader: false,
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
          } else {
            this.setState({
              zohoDate: {},
              effitracImportFormloader: false,
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            zohoDate: {},
            effitracImportFormloader: false,
            buttonColor: "red",
            messageForConnection: "Establish Connection",
            grantToken: "",
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({
          showUploadData: true,
          uploadStatusFail: response.data.records,
        });
        let res = response.data.records.records;

        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `VendorUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    // const { push, ...props } = this.props;
    return (
      <React.Fragment>
        <ZohoModel
          openZohoDrawer={this.state.openZohoDrawer}
          buttonColor={this.state.buttonColor}
          establishConnection={this.establishConnection}
          messageForConnection={this.state.messageForConnection}
          timerForZohoInSalesCustomer={this.state.timerForZoho}
          closeZohoDrawer={this.closeZohoDrawer}
          zohoDateChangeHandler={this.zohoDateChangeHandler}
          effitracImportFormloader={this.state.effitracImportFormloader}
          fetchingdataForZoho={this.fetchingdataForZoho}
          zohoDate={this.state.zohoDate}
          logApitName="purchase_vendor"
          nameOfTheModel="Vendor Management"
          importToCWS={this.importToCWS}
          exportToExcel={this.export_to_excel}
          zohoTableHeader={this.state.effitracHeader}
          zohoTableColumn={this.state.effitracCol}
          drawerKeys={this.state.actionHeaderForZoho}
        />
        {(localStorage.getItem('is_uploads') !== "N")&&<>
        <div>
          <ToggleSwitch
            onChange={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
            value={this.state.showUploadHistroyTable}
            label="Upload Vendor"
          />
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                !this.state.isEffitrac &&
                true
              }
              zohoEffitracFn={this.handleZohoDrawer}
              // enableImportToEffTallybutton={localStorage.getItem("Trigger_Effitrac/Tally_Button") && localStorage.getItem("Trigger_Effitrac/Tally_Button") === "true" && !this.state.isEffitrac}
              // importToEffiTallyFn={this.import_Effitrac_tally}
            >
              <this.CollectionListActions />
              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === "In Progress" ? (
                              <VisibilityIcon color="disabled" />
                            ) : item.status === "Uploaded" ? (
                              <VisibilityIcon color="disabled" />
                            ) : (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button showIcon"
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        </>}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>

                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        {/* arc customer table */}
        <BoxWrapper
          boxTitle="Vendor Details"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={() =>
            this.export_to_excel(this.state.allArcCustomerDataPurchase)
          }
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={
            this.state.isEffitrac
              ? this.handleSearchInputChangeFroEffitrackAndZoho
              : this.handleSearchInputChange
          }
          // back button
          backButtonForZohoEnable={this.state.isEffitrac}
          backButton={this.backHandler}
          // import to cws button
          importToCwsEnableForZoho={this.state.isEffitrac}
          importToCwsForZoho={this.importToCWS}
        >
          {this.state.isEffitrac ? (
            <CustomTable
              headers={this.state.effitracHeader}
              columns={this.state.effitracCol}
              data={this.state.effitracData}
              actions={this.state.actions}
            />
          ) : (
            <>
              <div className="responsive-table">
                <table className="custome-table">
                  <tbody>
                    <tr>{this.arc_Customer_TableHeader()}</tr>
                    {this.arc_Customer__data()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </BoxWrapper>

        {/*.......,.Edited by Harsh..............*/}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDrawer}
          onClose={this.closeZohoDrawer}
        >
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              className="button-outlined-primary"
              disabled={this.state.buttonColor === "green" ? true : false}
              onClick={this.establishConnection}
            >
              {this.state.messageForConnection}
            </button>
          </div>

          {this.state.buttonColor === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color: this.state.timerForZoho < 30 ? "red" : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZoho} Seconds
              </span>
            </div>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchingdataForZoho}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                Created From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                name="modified_from_date"
                value={this.state.zohoDate.modified_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "modified_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

              {this.state.effitracImportFormloader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeZohoDrawer}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer> */}

        {/* import tally/Effitrac drawer */}
        <RightDrawer
          drawerName="Import Tally/Effitrac"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeEffitracDrawer}
        >
          <div style={{ textAlign: "center" }}>
            Last Upload Date<br></br>
            {this.state.lastUploadDate} {this.state.lastUploadTime}
          </div>

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchEffitacFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                Created From Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="createdFromDate"
                value={
                  this.state.importEffitracFetchData.vendorBillsFromDate ?? ""
                }
                onChange={(v) =>
                  this.importEffitracCreatedFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Created To Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="createdToDate"
                value={
                  this.state.importEffitracFetchData.vendorBillsToDate ?? ""
                }
                onChange={(v) =>
                  this.importEffitracCreatedToDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={
                  this.state.importEffitracFetchData.modifiedFromDate ?? ""
                }
                onChange={(v) => this.importEffitracFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified To Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.importEffitracFetchData.modifiedToDate ?? ""}
                onChange={(v) =>
                  this.importEffitracModifiedToDateChangeHandler(v)
                }
              />
            </div>
            {/* submit and back button */}
            <div className="mt-20">
              {this.state.effitracImportFormloader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeEffitracDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>
        {/* import tally/effitrac end */}

        {/*arc show  drawer */}
        <RightDrawer
          drawerName="Vendor Details"
          open={this.state.showDrawer}
          onClose={this.showToggleDrawerClose}
        >
          {Object.keys(this.state.showData).length && (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Country"
                value={this.state.showData.country ?? "" }
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="State"
                value={this.state.showData.state ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Contact Name"
                value={this.state.showData.contactName ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Contact Number"
                value={this.state.showData.contactNumber ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Email"
                value={this.state.showData.vendorEmail ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Address"
                value={this.state.showData.address ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="PAN"
                value={this.state.showData.vendorPan ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="TAN"
                value={this.state.showData.vendorTan ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="TCS %"
                value={this.state.showData.tcsPercentage ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="GST Registration Type"
                value={this.state.showData.gstRegistrationType ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Purchase Manager Name"
                value={this.state.showData.purchaseManagerName ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Purchase Manager Contact Number"
                value={this.state.showData.purchaseManagerContactNumber ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Purchase Manager Email"
                value={this.state.showData.purchaseManagerEmail ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Credit Days"
                value={this.state.showData.creditDays ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showData.create_date ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showData.modify_date ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="TDS Threshold Limit Applicability"
                value={this.state.showData.thresholdLimitFlag}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Resident"
                value={this.state.showData.residentFlag}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Section 206AB"
                value={this.state.showData.section206ABFlag}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Comments"
                value={this.state.showData.comments}
              />
            </>
          )}
          <button
            type="button"
            className="button-outlined-secondary mt-20"
            onClick={this.showToggleDrawerClose}
          >
            close
          </button>
        </RightDrawer>

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          onClose={this.add_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addCustomer_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Vendor Name"
                name="venodrName"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.venodrName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Vendor Code+"
                name="erpVendorCode"
                value={this.state.addCustomerData.erpVendorCode ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Country"
                name="country"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.country ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="State"
                name="state"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.state ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Address"
                name="address"
                value={this.state.addCustomerData.address ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Name"
                name="contactName"
                value={this.state.addCustomerData.contactName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contactNumber"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.addCustomerData.contactNumber ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Email"
                name="vendorEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.addCustomerData.vendorEmail ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="GSTIN"
                validator={[
                  "matchRegexp:^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                ]}
                errorMsg={["Invalid GSTIN Number"]}
                name="vendorGstin"
                value={this.state.addCustomerData.vendorGstin ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="vendorPan"
                validator={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$"]
                    : ["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$"]
                }
                errorMsg={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["Invalid PAN Number"]
                    : ["Invalid PAN Number"]
                }
                value={this.state.addCustomerData.vendorPan ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TAN"
                name="vendorTan"
                validator={["matchRegexp:[A-Z]{4}[0-9]{5}[A-Z]{1}$"]}
                errorMsg={["Invalid TAN Number"]}
                value={this.state.addCustomerData.vendorTan ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Purchase Manager Name"
                name="purchaseManagerName"
                value={this.state.addCustomerData.purchaseManagerName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Purchase Manager Contact Number"
                name="purchaseManagerContactNumber"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={
                  this.state.addCustomerData.purchaseManagerContactNumber ?? ""
                }
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Purchase Manager Email"
                name="purchaseManagerEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.addCustomerData.purchaseManagerEmail ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Credit Days"
                name="creditDays"
                // requiredField="*"
                validator={["matchRegexp:[0-9]"]}
                errorMsg={["Must be number"]}
                value={this.state.addCustomerData.creditDays ?? ""}
                textOnchangeFunction={this.addCustomer_inputNumberHandler}
              />

              <SelectField
                SelectLabel="GST Registration Type"
                choice_id="id"
                choice_name="GSTRegistrationType"
                name="gstRegistrationType"
                value={this.state.addCustomerData.gstRegistrationType ?? ""}
                choices={[
                  { id: "Regular", GSTRegistrationType: "Regular" },
                  { id: "Unregistered", GSTRegistrationType: "Unregistered" },
                  { id: "Consumer", GSTRegistrationType: "Consumer" },
                  { id: "Composition", GSTRegistrationType: "Composition" },
                  { id: "Unknown", GSTRegistrationType: "Unknown" },
                ]}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TDS %"
                name="tdsPercentage"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addCustomerData.tdsPercentage ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="TCS %"
                name="tcsPercentage"
                // step=".01"
                // requiredField=""

                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addCustomerData.tcsPercentage ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="TDS Threshold Limit Applicability"
                choice_id="id"
                choice_name="ThresholdLimitFlag"
                name="thresholdLimitFlag"
                value={this.state.addCustomerData.thresholdLimitFlag ?? ""}
                choices={[
                  { id: "Applicable", ThresholdLimitFlag: "Applicable" },
                  {
                    id: "Not applicable",
                    ThresholdLimitFlag: "Not applicable",
                  },
                ]}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="Resident"
                choice_id="id"
                choice_name="ResidentFlag"
                name="residentFlag"
                value={this.state.addCustomerData.residentFlag ?? ""}
                choices={[
                  { id: "Resident", ResidentFlag: "Resident" },
                  { id: "Non Resident", ResidentFlag: "Non Resident" },
                ]}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="Section 206AB"
                choice_id="id"
                choice_name="Section206ABFlag"
                name="section206ABFlag"
                value={this.state.addCustomerData.section206ABFlag ?? ""}
                choices={[
                  { id: "Yes", Section206ABFlag: "Yes" },
                  { id: "No", Section206ABFlag: "No" },
                ]}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Comments"
                name="comments"
                value={this.state.addCustomerData.comments ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
                disabled={this.state.buttonDisabled}
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.add_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>
        {/* arc add drawer end */}

        {/* arc edit drawer  */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.edit_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editCustomer_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Vendor Name"
                name="venodrName"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCustomerData.venodrName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Vendor Code+"
                name="erpVendorCode"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCustomerData.erpVendorCode ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Country"
                name="country"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCustomerData.country ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="State"
                name="state"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCustomerData.state ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Address"
                name="address"
                value={this.state.editCustomerData.address ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Name"
                name="contactName"
                value={this.state.editCustomerData.contactName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contactNumber"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.editCustomerData.contactNumber ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Email"
                name="vendorEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.editCustomerData.vendorEmail ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="vendorGstin"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCustomerData.vendorGstin ?? ""}
                // textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="vendorPan"
                requiredField={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? "*"
                    : ""
                }
                validator={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? [
                        "required",
                        "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
                      ]
                    : ["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$"]
                }
                errorMsg={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["This field is required", "Invalid PAN Number"]
                    : ["Invalid PAN Number"]
                }
                value={this.state.editCustomerData.vendorPan ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TAN"
                name="vendorTan"
                validator={["matchRegexp:[A-Z]{4}[0-9]{5}[A-Z]{1}$"]}
                errorMsg={["Invalid TAN Number"]}
                value={this.state.editCustomerData.vendorTan ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="purchase Manager Name"
                name="purchaseManagerName"
                value={this.state.editCustomerData.purchaseManagerName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Purchase Manager Contact Number"
                name="purchaseManagerContactNumber"
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={
                  this.state.editCustomerData.purchaseManagerContactNumber ?? ""
                }
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Purchase Manager Email"
                name="purchaseManagerEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.editCustomerData.purchaseManagerEmail ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Credit Days"
                name="creditDays"
                validator={["matchRegexp:[0-9]"]}
                errorMsg={["Must be number"]}
                value={this.state.editCustomerData.creditDays ?? ""}
                textOnchangeFunction={this.editCustomer_inputNumberHandler}
              />

              <SelectField
                SelectLabel="GST Registration Type"
                choice_id="id"
                choice_name="GSTRegistrationType"
                name="gstRegistrationType"
                value={this.state.editCustomerData.gstRegistrationType ?? ""}
                choices={[
                  { id: "Regular", GSTRegistrationType: "Regular" },
                  { id: "Unregistered", GSTRegistrationType: "Unregistered" },
                  { id: "Consumer", GSTRegistrationType: "Consumer" },
                  { id: "Composition", GSTRegistrationType: "Composition" },
                  { id: "Unknown", GSTRegistrationType: "Unknown" },
                ]}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TDS %"
                name="tdsPercentage"
                requiredField=""
                // validator={["matchRegexp:^([1-9][0-9]?|100)$|^[0-9]{0,2}\.[0-9]{1,2}$|[0]$"]}
                // errorMsg={["invalid percentage."]}
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editCustomerData.tdsPercentage ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="TCS %"
                name="tcsPercentage"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editCustomerData.tcsPercentage ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="TDS Threshold Limit Applicability"
                choice_id="id"
                choice_name="thresholdLimit"
                name="thresholdLimitFlag"
                value={this.state.editCustomerData.thresholdLimitFlag ?? ""}
                choices={[
                  { id: "Applicable", thresholdLimit: "Applicable " },
                  { id: "Not applicable", thresholdLimit: "Not applicable" },
                ]}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="Resident"
                choice_id="id"
                choice_name="residentflag"
                name="residentFlag"
                value={this.state.editCustomerData.residentFlag ?? ""}
                choices={[
                  { id: "Resident", residentflag: "Resident" },
                  { id: "Non Resident", residentflag: "Non Resident" },
                ]}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <SelectField
                SelectLabel="section 206AB"
                choice_id="id"
                choice_name="section206ABFlag"
                name="section206ABFlag"
                value={this.state.editCustomerData.section206ABFlag ?? ""}
                choices={[
                  { id: "Yes", section206ABFlag: "Yes" },
                  { id: "No", section206ABFlag: "No" },
                ]}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Comment"
                name="comments"
                value={this.state.editCustomerData.comments ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
                disabled={this.state.buttonDisabled}
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.edit_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>
        {/* arc edit drawer end */}

        {/* effitrac/tally show drawer */}
        <Drawer
          anchor="right"
          open={this.state.effitracInvShow}
          onClose={this.closeEffitracInvShowHandler}
        >
          <div className={classes.showDetails}>
            <>
              <div className={classes.showDetailsBody}>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Country"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].country
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="State"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].state
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Contact Name"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].contactName
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Contact Number"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].contactNumber
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Email"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].vendorEmail
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Address"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].address
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="PAN"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].vendorPan
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="TAN"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].vendorTan
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="TCS %"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].tcsPercentage
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="GST Registration Type"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .gstRegistrationType
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Purchase Manager Name"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .purchaseManagerName
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Purchase Manager Contact Number"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .purchaseManagerContactNumber
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Purchase Manager Email"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .purchaseManagerEmail
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Credit Days"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].creditDays
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="TDS Threshold Limit Applicability"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .thresholdLimitFlag
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Resident"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].residentFlag
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Section 206AB"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId]
                      .section206ABFlag
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Comments"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.effitracData[this.state.targetId].comments
                  }
                />
              </div>
            </>
            <Button
              style={{
                marginTop: "25px",
                marginBottom: "30px",
                marginLeft: "10px",
              }}
              variant="contained"
              size="small"
              onClick={this.closeEffitracInvShowHandler}
            >
              Back
            </Button>
          </div>
        </Drawer>
        {/* effitrac/tally show drawer end*/}
      </React.Fragment>
    );
  }
}

export default VendorManagement;

import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Grid, Paper, Typography, Box, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {
    LineChart, Line, BarChart, Bar, ScatterChart, Scatter, PieChart, Pie, Cell,
    XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer,
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, RadialBarChart, RadialBar,
    TreeMap, Tooltip as RechartsTooltipTreeMap, Treemap
} from 'recharts';

import BarChartIcon from '@mui/icons-material/BarChart';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CircularProgress from "@mui/material/CircularProgress";
import axios from '../../../../axios-order';
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import favicon from '../favicon.ico'


const colors = [
    '#339BFF', '#FF33D4', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
    '#FF5733', '#CCCCFF', '#FF6384', '#FF33A1', '#33FFF5'
];

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// const graphImageUrl = 'https://img.freepik.com/premium-vector/vector-design-graph-icon-style_822882-55106.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user';
const graphImageUrl = 'https://cdn-icons-png.freepik.com/256/16170/16170706.png?ga=GA1.1.706857113.1721211877&semt=ais_hybrid'

const tableImageUrl = 'https://img.freepik.com/premium-vector/audio-symbol-icon-flat-color-circle-style_755164-19997.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                borderRadius: '40px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                backgroundImage: `url(${graphImageUrl})`, // Use graph image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
        '&:not(.Mui-checked)': {
            color: '#fff',
            transform: 'translateX(-6px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                borderRadius: '35px',
                backgroundImage: `url(${tableImageUrl})`, // Use table image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


const ItemWiseReportContainer = () => {
    const location = useLocation();
    const props = location.state.items || {};

    const start_period = props.from_tax_period
    const end_period = props.to_tax_period

    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false)

    const [graphType, setGraphType] = useState('BarChart');

    const [selectedMatchResult, setSelectedMatchResult] = useState('line_total');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [graphData, setGraphData] = useState([])

    const [dispalTable, setDisplyTable] = useState(false)

    const [tableData, setTableData] = useState([])

    const [selectedReportType, setSelectedReportType] = useState('sales');
    const [selectedReportBy, setSelectedReportBy] = useState('hsn_sac_code');

    const [chartData, setChartData] =useState([])

    useEffect(() => {
        setLoading(true)
        getReportDetails()
    }, [selectedReportType, selectedReportBy])

    const getReportDetails = () => {

        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'start_period': start_period,
            'end_period': end_period,
            'grouping_key': selectedReportBy,
            'report_type': selectedReportType
        }
        axios.post('get_item_wise_report', payLoadData)
        .then((res) => {
            if (res.data.status) {
                const data = res.data.totals
                setDashboardData(data)
                setGraphData(res.data.records)
                setChartData(res.data.graph_data)
                setLoading(false)
            }
        })
        .catch((err) => {
            setLoading(false)
        })
    }

    const exportToPDF = () => {
        const input = document.getElementById('chart-container');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.setLineWidth(1);
            const logoImg = new Image();
            logoImg.src = favicon;
            pdf.addImage(logoImg, 'PNG', pdfWidth - 30, 10, 20, 20);

            pdf.setFontSize(18);
            pdf.text('ITEM WISE REPORT', pdfWidth / 2, 20, { align: 'center' });

            const periodText = `Period: ${props.from_tax_period} TO ${props.to_tax_period}`;
            pdf.setFontSize(12);
            pdf.text(periodText, pdfWidth / 2, 30, { align: 'center' });

            pdf.setFontSize(10);
            pdf.text('Powered by Cappitallwant Network', pdfWidth / 2, pdfHeight + 25, { align: 'center' });

            pdf.addImage(imgData, 'PNG', 15, 40, pdfWidth - 30, pdfHeight);

            pdf.save(`Item Wise Report.pdf`);
        });
    };

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };


    const renderGraph = () => {
        switch (graphType) {
            case 'LineChart':
                return (
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={selectedReportBy} tick={{ angle: selectedReportBy==="hsn_sac_code" && 45, textAnchor: 'start' }} interval={0}/>
                        <YAxis dataKey={selectedMatchResult} hide={true} />
                        <RechartsTooltip />
                        <Legend />
                        <Line type="monotone" dataKey={selectedMatchResult} stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                );
            case 'BarChart':
                return (
                    <BarChart data={chartData}>
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis dataKey={selectedReportBy}  tick={{ angle: selectedReportBy==="hsn_sac_code" && 45, textAnchor: 'start' }} interval={0}/>
                        <YAxis dataKey={selectedMatchResult} hide={true} />
                        <RechartsTooltip />
                        <Legend />
                        <Bar barSize={50} dataKey={selectedMatchResult} fill="#8884d8" />
                    </BarChart>
                );
            case 'BubbleChart':
                return (
                    <ScatterChart width={400} height={400}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={selectedReportBy} type="category" tick={{ angle: selectedReportBy==="hsn_sac_code" && 45, textAnchor: 'start' }} interval={0}/>
                        <YAxis dataKey={selectedMatchResult} type="number" hide={true} />
                        <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Legend />
                        <Scatter data={chartData} fill="#8884d8" />
                    </ScatterChart>
                );
            case 'PieChart':
                return (
                    <PieChart width={'100%'} height={'100%'}>
                        <RechartsTooltip />
                        <Legend />
                        <Pie data={chartData} dataKey={selectedMatchResult} nameKey={selectedReportBy} cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                );
            case 'DoughnutChart':
                return (
                    <ResponsiveContainer width="100%" height={300} id="chart-container">
                        <PieChart>
                            <Pie
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey={selectedMatchResult}
                                nameKey={selectedReportBy}
                            >
                                {chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Legend />
                            <RechartsTooltip />
                        </PieChart>
                    </ResponsiveContainer>
                );
            case 'RadarChart':
                return (
                    <RadarChart outerRadius={90} width={730} height={250} data={chartData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey={selectedReportBy} />
                        <PolarRadiusAxis />
                        <Radar name={selectedMatchResult} dataKey={selectedMatchResult} stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        <RechartsTooltip />
                    </RadarChart>
                );
            case 'RadialBarChart':
                return (
                    <ResponsiveContainer width="100%" height={300} id="chart-container">
                        <RadialBarChart
                            // width={730}
                            // height={250}
                            innerRadius="10%"
                            outerRadius="80%"
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            // barSize={10}
                            nameKey={selectedReportBy}
                        >
                            <RadialBar
                                minAngle={15}
                                label={{ position: 'insideStart', fill: '#0000' }}
                                background
                                clockWise
                                dataKey={selectedMatchResult}
                            >
                                {
                                    chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))
                                }
                            </RadialBar>
                            <Legend />
                            <RechartsTooltip />
                        </RadialBarChart>
                    </ResponsiveContainer>
                );
            case 'TreeMap':
                return (
                    <ResponsiveContainer width="100%" height={400}>
                        <Treemap
                            width={400}
                            height={200}
                            data={chartData}
                            dataKey={selectedMatchResult}
                            ratio={4 / 3}
                            stroke="#fff"
                            fill="#8884d8"
                        >
                            <RechartsTooltipTreeMap />
                        </Treemap>
                    </ResponsiveContainer>
                );
            default:
                return null;
        }
    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleExportExcel = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem('business_entity_id'),
            'start_period': start_period,
            'end_period': end_period,
            'grouping_key': selectedReportBy,
            'report_type': selectedReportType
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `Item Wise Report -${selectedReportBy.toUpperCase().replace(/_/g, ' ')}-${selectedReportType.toUpperCase().replace(/_/g, ' ')}.xlsx`;

        axios.post('/exportItemWiseReportData', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);
            }).catch((err) => {

            })
    }

    const renderTable = () => {
        return (
            <>


                <BoxWrapper boxTitle="ITEM WISE REPORT">
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    <th>{selectedReportBy === "hsn_sac_code" ? 'HSN/SAC Code' : (selectedReportBy == "gst_tax_rate" ? 'GST Tax-wise Report' : 'Invoice Type-wise Report')}</th>
                                    <th>Unit of Measurement (UOM)</th>
                                    <th>Total Quantity</th>
                                    <th>Taxable Value</th>
                                    <th>Non Taxable Value</th>
                                    <th>IGST</th>
                                    <th>CGST</th>
                                    <th>SGST/UTGST</th>
                                    <th>Cess</th>
                                    <th>Total Line Value</th>
                                </tr>
                                {graphData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item[selectedReportBy]}</td>
                                        <td>{item.unit_of_measurement}</td>
                                        <td>{item.total_quantity}</td>
                                        <td>{item.total_taxable_value}</td>
                                        <td>{item.total_non_taxable_value}</td>
                                        <td>{item.total_igst}</td>
                                        <td>{item.total_cgst}</td>
                                        <td>{item.total_sgst}</td>
                                        <td>{item.total_cess}</td>
                                        <td>{item.line_total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            </>
        );
    };

    const onClickCard = (key) => {
        setDisplyTable(true)
        // setTableData(graphData)
    }

    const OnchageSwitch = (e) => {
        setDisplyTable(!dispalTable)
    }


    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2%' }}>
                {/* Report Type Dropdown */}
                <FormControlLabel
                    control={<MaterialUISwitch defaultChecked />}
                    onChange={OnchageSwitch}
                    label={
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {dispalTable ? "Data Table" : "Graph Report"}
                        </Typography>}
                />
                <FormControl style={{ flex: '1', minWidth: 250, maxWidth: '20%' }} variant="outlined">
                    <InputLabel>Report Type</InputLabel>
                    <Select

                        value={selectedReportType}
                        onChange={(e) => setSelectedReportType(e.target.value)}
                        label="Report Type"
                        fullWidth
                        style={{ height: '48px' }}
                    >
                        <MenuItem value="sales">Sales</MenuItem>
                        <MenuItem value="purchases">Purchases</MenuItem>
                    </Select>
                </FormControl>

                {/* Item Wise Report */}
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '2%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        ITEM WISE REPORT <AnalyticsIcon style={{ marginBottom: '4px', color: '#ff6361' }} />
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom style={{ textAlign: 'center', marginBottom: '2%' }}>
                        Period: <Typography style={{ color: 'green', fontWeight: 'bold', display: 'inline', fontSize: 'inherit', fontWeight: 'inherit' }} variant="h7">{props.from_tax_period} TO {props.to_tax_period}</Typography>
                    </Typography>
                </div>

                {/* Report By Dropdown */}
                <FormControl style={{ flex: '1', minWidth: 250, maxWidth: '20%' }} variant="outlined">
                    <InputLabel>Report By</InputLabel>
                    <Select
                        value={selectedReportBy}
                        onChange={(e) => setSelectedReportBy(e.target.value)}
                        label="Report By"
                        fullWidth
                        style={{ height: '48px' }}
                    >
                        <MenuItem value="gst_tax_rate">GST Tax-wise Report</MenuItem>
                        <MenuItem value="gst_invoice_type">Invoice Type-wise Report</MenuItem>
                        <MenuItem value="hsn_sac_code">HSN/SAC wise Report</MenuItem>
                    </Select>
                </FormControl>
                <Button onClick={handleExportExcel} variant="contained" color="primary" style={{ marginLeft: '2%' }} endIcon={<FileDownloadIcon />}>
                    Export
                </Button>
            </div>

            {!loading ? ((!dispalTable) ? <Grid container spacing={2}>
                {!isFullScreen && Object.keys(dashboardData).map((key, index) => (
                    <Grid onClick={() => onClickCard(key)} key={index} item xs={12} md={6} lg={4} style={{ cursor: 'pointer' }}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                borderTop: `5px solid ${colors[index % colors.length]}`,
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                height: '90%',
                            }}
                        >
                            <Typography textAlign='center' variant="h7">{key.replace(/_/g, ' ')}</Typography>
                            <Typography textAlign='center' variant="h6">{dashboardData[key] ? `₹ ${dashboardData[key].toFixed(2)}` : dashboardData[key]}</Typography>
                        </Paper>
                    </Grid>
                ))}

                <Grid item xs={12} md={12}>
                    <Paper sx={{ p: 2, borderTop: '5px solid #36A2EB', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold' }}>ITEM WISE REPORT</Typography>

                            <FormControl style={{ width: '25%', marginRight: '10%' }} variant="outlined">
                                <InputLabel>Amount Type</InputLabel>
                                <Select
                                    value={selectedMatchResult}
                                    onChange={(e) => setSelectedMatchResult(e.target.value)}
                                    label="MATCH RESULT"
                                    fullWidth
                                    style={{ height: '48px' }}
                                >
                                    <MenuItem value="line_total">TOTAL LINE VALUE</MenuItem>
                                    <MenuItem value="total_taxable_value">TAXABLE VALUE</MenuItem>
                                    <MenuItem value="total_non_taxable_value">NON TAXABLE VALUE</MenuItem>
                                    <MenuItem value="total_igst">IGST</MenuItem>
                                    <MenuItem value="total_cgst">CGST</MenuItem>
                                    <MenuItem value="total_sgst">SGST</MenuItem>
                                    <MenuItem value="total_cess">CESS</MenuItem>
                                </Select>
                            </FormControl>


                            <div>
                                <Tooltip title="Export to PDF">
                                    <IconButton onClick={exportToPDF}>
                                        <DownloadForOfflineIcon color='primary' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
                                    <IconButton onClick={toggleFullScreen}>
                                        {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Tooltip title="Pie Chart">
                                <IconButton
                                    style={graphType === 'PieChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('PieChart')}>
                                    <PieChartIcon style={{ color: '#FF5733' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bar Chart">
                                <IconButton
                                    style={graphType === 'BarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('BarChart')}>
                                    <BarChartIcon style={{ color: '#36A2EB' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Line Chart">
                                <IconButton
                                    style={graphType === 'LineChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('LineChart')}>
                                    <ShowChartIcon style={{ color: '#8884d8' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bubble Chart">
                                <IconButton
                                    style={graphType === 'BubbleChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('BubbleChart')}>
                                    <BubbleChartIcon style={{ color: '#FFCE56' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Doughnut Chart">
                                <IconButton
                                    style={graphType === 'DoughnutChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('DoughnutChart')}>
                                    <DonutLargeIcon style={{ color: '#4BC0C0' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Radial Chart">
                                <IconButton
                                    style={graphType === 'RadialBarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('RadialBarChart')}>
                                    <DonutSmallIcon style={{ color: '#FF9F40' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Tree Map">
                                <IconButton
                                    style={graphType === 'TreeMap' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('TreeMap')}>
                                    <AccountTreeIcon style={{ color: '#FF33A1' }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Rader Chart">
                                <IconButton
                                    style={graphType === 'RadarChart' ? { borderWidth: '1px', borderStyle: 'solid', borderColor: '#007FFF' } : {}}
                                    size='large' onClick={() => setGraphType('RadarChart')}>
                                    <WaterfallChartIcon style={{ color: '#FF5733' }} />
                                </IconButton>
                            </Tooltip>



                        </Box>
                        <ResponsiveContainer width="100%" height={300} id="chart-container">
                            {renderGraph()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

            </Grid> : renderTable())

                : <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>}
        </Container>
    )
}

export default ItemWiseReportContainer
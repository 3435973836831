import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TDSRecon.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { green } from '@mui/material/colors';
import Tooltip from "@mui/material/Tooltip";

import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Divider from "@mui/material/Divider";
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectField from "../../../../components/ui/basicSelectInput";



import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import DashboardBarChart from "./Dashboard/dashboardBarChart";

import CircularProgress from "@material-ui/core/CircularProgress";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});




export default function Form26ASTDSRecon() {
    let history = useHistory();

    const [newReconHistoryTable, setNewReconHistoryTable] = useState([]);

    const [errorMessageForBoxWrapper, setErrorMessageForBoxWrapper] = useState("");

    const [closeBoxWrapper, setCloseBoxWrapper] = useState(false);

    const [loaderForTable, setLoaderForTable] = useState(true);

    const [lastReconRunDatetime, setLastReconRunDatetime] = useState('')

    const [open, setOpen] = React.useState(false);

    const [itemToLock, setItemToLock] = useState(null);

    const [loadingButton, setLoadingButton] = useState(false)

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

    const [responseMessage, setResponseMessage] = useState("");

    const [message, setMessage] = useState('');

    const [chartData, setChartData] = useState([]);

    const [newReconFormOpen, setNewReconFormOpen] = useState(false);

    const [financialYearsList, setFinancailYearsList] = useState([])

    const [exportloading, setExportLoading] = useState(false)

    // const [newReconFormData, setNewReconFormData] = useState({});

    const [fromYear, setFromYear] = useState('')

    const [toYear, setToYear] = useState('')

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const getTdsReconDetails = () => {
        toast.configure();
        axios
            .get(
                `/form26/get_tds_recon_details/${localStorage.getItem(
                    "business_entity_id"
                )}`
            )
            .then((res) => {
                setLoaderForTable(false);
                if (res.data) {
                    const records = res?.data?.records.map(record => ({
                        ...record,
                        excel_loading: false
                    }));
                    setNewReconHistoryTable(records);
                    setLastReconRunDatetime(res.data.recent_recon_date_time)
                }
            })
            .catch((error) => {
                setLoaderForTable(false);

                toast.error(error?.response?.data?.message, {
                    autoClose: 3000,
                    progress: 0,
                });
            });
    };

    useEffect(() => {
        getTdsReconDetails();
    }, []);

    useEffect(() => {
        const dataForChart = [
            {
                name: "Total TDS As Per Books",
                data: newReconHistoryTable.map(item => parseFloat(item.tds_as_per_books))
            },
            {
                name: "Total TDS As Per 26AS",
                data: newReconHistoryTable.map(item => parseFloat(item.tds_as_per_26as))
            },
            {
                name: "Difference",
                data: newReconHistoryTable.map(item => parseFloat(item.difference))
            }
        ];
        setChartData(dataForChart);
        const financial_years = newReconHistoryTable.map(item => ({ id: item.financial_year, year: item.financial_year }));
        setFinancailYearsList(financial_years)
    }, [newReconHistoryTable]);

    const updatedShowDetails = (item) => {
        const pairId = item.pair_id ? item.pair_id : 0
        localStorage.setItem("financial_year_26_as", `${item.financial_year}`);
        localStorage.setItem("locked_status", `${item.locked}`);
        localStorage.setItem("max_pair_id", `${parseInt(pairId)}`);
        localStorage.setItem("lastClickedIndex", null);
        history.push({
            pathname: `/26ASReport`,
        });
    };

    const onSumbitLock = () => {
        setLoadingButton(true)

        const lockStatus = itemToLock ? ((!itemToLock.locked || itemToLock.locked === "N") ? "Y" : "N") : ''

        const lokedData = {
            "business_entity_id": localStorage.getItem("business_entity_id"),
            "financial_year": itemToLock ? itemToLock.financial_year : '',
            "locked": lockStatus
        }

        axios.post('/form26/update_lockstatus', lokedData)
            .then((res) => {

                setLoadingButton(false)
                getTdsReconDetails()
                handleClose()

            })
            .catch((err) => {

            })

    }

    const handleClickOpen = (lock_item) => {
        if (lock_item.is_valid === "N") {
            handleSnackbarOpen("Recon has been invalidated due to data upload. Please rerun the recon.", 'warning');
        } else {
            const index = newReconHistoryTable.findIndex(item => item === lock_item)
            const locked_record_list = newReconHistoryTable.slice(0, index).filter(item => !item.locked || item.locked !== 'Y' && item.is_valid !== "N")
            const unlocked_record_list = newReconHistoryTable.slice(index + 1).filter(item => item.locked && item.locked !== 'N' && item.is_valid !== "N")
            const lockStatus = lock_item ? ((!lock_item.locked || lock_item.locked === "N") ? "Y" : "N") : ''

            if (lockStatus != "Y") {
                if (unlocked_record_list.length === 0) {
                    setItemToLock(lock_item);
                    setOpen(true);
                } else {
                    handleSnackbarOpen("Before unlocking this record, please ensure that future financial year records are unlocked.", 'warning');
                    handleClose()
                    setLoadingButton(false)
                }
            } else {
                if (locked_record_list.length == 0) {
                    setItemToLock(lock_item);
                    setOpen(true);

                } else {
                    handleSnackbarOpen("Before locking this record, please ensure that previous financial year records are locked.", 'warning');
                    handleClose()
                    setLoadingButton(false)
                }


            }
        }

    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleDownloadReport = (item) => {
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `TDS Receivables Reconciliation Report.xlsx`;
        setNewReconHistoryTable(prevRecords =>
            prevRecords.map(record =>
                record.id === item.id ? { ...record, excel_loading: true } : record
            )
        );
        axios
            .post(`/form26asreport`, {
                business_entity_id: localStorage.getItem("business_entity_id"),
                financial_year: item.financial_year,
                entity_type: localStorage.getItem("entity_type")
            })
            .then((res) => {
                downloadBase64File(contentType, res.data.file, filename);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setNewReconHistoryTable(prevRecords =>
                    prevRecords.map(record =>
                        record.id === item.id ? { ...record, excel_loading: false } : record
                    )
                );
            })
    }

    const renderLockConfirmationPopup = () => {
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}

                    aria-describedby="alert-dialog-slide-description"

                    PaperProps={{
                        style: {
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '20px',
                            justifyContent: 'space-around'
                        },
                    }}



                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {itemToLock && itemToLock.status === "Pass" && (
                            itemToLock.locked === "N" || !itemToLock.locked ? (
                                <LockRoundedIcon
                                    className="action-icon-button showIcon"
                                    style={{ fontSize: '100px', marginBottom: '40px', pointerEvents: 'none' }}
                                    color="action"

                                />
                            ) : (
                                <LockOpenOutlinedIcon
                                    className="action-icon-button showIcon"
                                    style={{ fontSize: '100px', marginBottom: '40px', pointerEvents: 'none' }}
                                    color="action"
                                />
                            )
                        )}


                    </div>

                    <div>
                        <DialogTitle>{"Are you sure you want to " + (itemToLock && itemToLock.locked != "Y" ? "lock" : "unlock") + ` the record with financial year (${itemToLock && itemToLock.financial_year})?`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {`If you ${itemToLock && itemToLock.locked != "Y" ? "lock" : "unlock"} this record, then you ${itemToLock && itemToLock.locked != "Y" ? "won't be able to" : "can"} upload any 26AS related data.`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            {/* <Button loading={loadingButton} variant="contained" onClick={() => onSumbitLock(itemToLock)}></Button> : */}
                            <LoadingButton
                                size="small"
                                onClick={() => onSumbitLock(itemToLock)}
                                loading={loadingButton}
                                variant="contained"
                                style={{ height: '36px', width: '90px' }}
                            >
                                <span>{itemToLock && itemToLock.locked != "Y" ? "Lock" : "Unlock"}</span>
                            </LoadingButton>
                        </DialogActions>
                    </div>

                </Dialog>

            </React.Fragment>
        );
    }


    const handleClose = () => {
        setOpen(false);
    };

    const showFailReason = (item) => {
        handleSnackbarOpen(item.fail_reason, 'warning');
    }

    const historyTableBody = () => {



        if (loaderForTable) {
            console.log("Loader");
            return (
                <tr>
                    <td
                        style={{ color: "black", textAlign: "right", fontWeight: "bold" }}
                        colspan={4}
                    >
                        Loading…Please wait
                    </td>
                </tr>
            );
        }

        else if (newReconHistoryTable && newReconHistoryTable.length > 0) {
            return newReconHistoryTable.map((item, index) => {

                return (
                    <tr>
                        <td >{item.financial_year}</td>

                        <td>{item.tds_as_per_books}</td>
                        <td>{item.tds_as_per_26as}</td>
                        <td>{item.difference}</td>
                        <td>{item.carry_forward}</td>
                        <td>{item.matched_in_next_fy}</td>
                        {process.env.REACT_APP_MODE === "DEBUG" && <td>{item.pair_id}</td>}
                        <td>{item.status}</td>

                        {/* <Chip variant="outlined" size="small" label={item.status} color={item.status === "Pass" ? "success" :
                            item.status === "Fail" ? "error" :
                                "warning"} sx={{ width: (item.status === "In Progress" || item.status === "Initiated") ? '95px' : '60px', }} /> */}

                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            {item.status === "Pass" && (

                                item.is_valid !== "N" ? (<VisibilityOutlinedIcon
                                    className="action-icon-button showIcon"
                                    onClick={() => updatedShowDetails(item)}
                                    style={{ marginTop: '2px' }}
                                    color="primary"
                                />) : (
                                    <Tooltip title="Recon is Invalid">
                                        <VisibilityOutlinedIcon
                                            className="action-icon-button deleteIcon"
                                            color="disabled"
                                            style={{ marginTop: '2px' }}
                                        />
                                    </Tooltip>
                                )
                            )}

                            {item.status === "Fail" && (
                                <VisibilityOutlinedIcon
                                    className="action-icon-button deleteIcon"
                                    style={{ marginTop: '2px' }}
                                    onClick={() => showFailReason(item)}
                                />
                            )}
                            {item.status === "In Progress" && (
                                <VisibilityOutlinedIcon
                                    className="action-icon-button deleteIcon"
                                    style={{ marginTop: '2px' }}
                                    color="disabled"
                                // onClick={() => showFailReason(item)}
                                />
                            )}

                            {(!item.status || item.status === "Initiated") && (
                                <VisibilityOutlinedIcon
                                    className="action-icon-button deleteIcon"
                                    color="disabled"
                                    style={{ pointerEvents: 'none', marginTop: '2px' }}
                                />
                            )}

                            {/* {item.status && <Divider
                                orientation="vertical"
                                flexItem
                                style={{ borderColor: "black", height: '20px', marginTop: '2px', marginLeft: '6.5%', marginRight: '6.5%' }}
                            />} */}



                            {item.status === "Pass" && (
                                item.locked === "Y" ? (

                                    <LockOpenOutlinedIcon
                                        className="action-icon-button showIcon"
                                        onClick={() => handleClickOpen(item)}
                                        style={{

                                            marginLeft: '5px'
                                        }}
                                        color={item.is_valid !== "N" ? "primary" : "disabled"}
                                    />

                                ) : (

                                    <LockRoundedIcon
                                        className="action-icon-button showIcon"

                                        onClick={() => handleClickOpen(item)}
                                        style={{

                                            marginLeft: '5px'
                                        }}
                                        color={item.is_valid !== "N" ? "primary" : "disabled"}
                                    />
                                )

                            )}

                            {item.status === "Fail" && (

                                <LockRoundedIcon
                                    className="action-icon-button showIcon"
                                    // onClick={() => handleClickOpen(item)}
                                    style={{

                                        marginLeft: '5px'
                                    }}
                                    color={"disabled"}
                                />)}

                            {item.status === "In Progress" && (

                                <LockRoundedIcon
                                    className="action-icon-button showIcon"
                                    // onClick={() => handleClickOpen(item)}
                                    style={{

                                        marginLeft: '5px'
                                    }}
                                    color={"disabled"}
                                />)}

                            {(!item.status || item.status === "Initiated") && (
                                <LockRoundedIcon
                                    className="action-icon-button deleteIcon"
                                    color="disabled"
                                    style={{ pointerEvents: 'none', marginLeft: '5px' }}
                                />
                            )}

                            {
                                (item.status === "Pass") ? (
                                    item.is_valid !== "N" ?

                                        (
                                            (!item.excel_loading ? <img onClick={() => handleDownloadReport(item)} style={{ marginLeft: '5px', height: '23px', width: '25px', cursor: 'pointer' }} src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg" />
                                                : <>
                                                    <CircularProgress
                                                        size={20}
                                                        style={{ color: "green", marginLeft: '5px', marginTop: '2px' }}
                                                    />
                                                </>)
                                        )


                                        :

                                        (
                                            <Tooltip title="Recon is Invalid">
                                                <img style={{ marginLeft: '5px', height: '23px', width: '25px' }} src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg" />
                                            </Tooltip>
                                        )
                                ) : (



                                    <img style={{ marginLeft: '5px', height: '23px', width: '25px', cursor: 'pointer' }} src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg" />

                                )
                            }



                        </td>
                    </tr>
                );
            });
        }
        else {
            return (
                <tr>
                    <td style={{ color: "red" }} colspan={8}>
                        <img src="https://res.cloudinary.com/dyivs1j8u/image/upload/v1713335965/Screenshot_2024-04-17_120750-removebg-preview_tdhay3.png" />
                    </td>
                </tr>
            );
        }
    };

    const onClickRunTdsRecon = () => {

        const financial_year_list = newReconHistoryTable
            .filter(item => item.locked !== 'Y')
            .map(item => item.financial_year);
        const reconData = {
            business_entity_id: localStorage.getItem("business_entity_id"),
            financial_year_list: financial_year_list
        }
        if (financial_year_list.length >= 1) {
            axios.post('/form26/run_tds', reconData)
                .then((res) => {
                    getTdsReconDetails()
                })
                .catch((err) => {

                })
        } else {
            if (newReconHistoryTable.length >= 1) {
                handleSnackbarOpen("Please unlock records to run the Recon.", 'warning');

            } else {
                handleSnackbarOpen('There are no records available to run the Recon.', 'warning');
            }

        }
    }

    const refreshUploadHistoryTable = () => {
        getTdsReconDetails();
    };

    const FunForcloseBoxWapper = () => {
        setCloseBoxWrapper(false);
    };

    const handleExport = () => {
        setExportLoading(true)
        const validFinancialYears = newReconHistoryTable
            .filter((item) => item.is_valid !== "N" && item.financial_year)
            .map((item) => item.financial_year);

        const filteredFinancialYears = validFinancialYears
            .filter(item => item >= fromYear && item <= toYear)

        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `TDS Receivables Reconciliation Report.xlsx`;
        axios
            .post(`/form26asreport_for_multiyear`, {
                business_entity_id: localStorage.getItem("business_entity_id"),
                financial_year: filteredFinancialYears,
                entity_type: localStorage.getItem("entity_type")
            })
            .then((res) => {
                setExportLoading(false)
                setNewReconFormOpen(false);
                downloadBase64File(contentType, res.data.file, filename);
            })
            .catch((error) => {
                console.log(error);
            });

    }



    const newReconFormModalOpen = () => {
        setNewReconFormOpen(true);
    };

    const newReconFormModalClose = () => {
        setNewReconFormOpen(false);
    };

    const exportFromYearChangeHandler = (e) => {
        setFromYear(e.target.value)
    };

    const exportToYearChangeHandler = (e) => {
        setToYear(e.target.value)
    };

    const getToYear = () => {
        const sortedData = financialYearsList.sort((a, b) => {
            const [startYearA, endYearA] = a.year.split('-').map(Number);
            const [startYearB, endYearB] = b.year.split('-').map(Number);

            if (startYearA !== startYearB) {
                return startYearA - startYearB;
            }

            return endYearA - endYearB;
        });

        if (fromYear) {
            
            if (fromYear) {
                const index = sortedData.findIndex(item => item.year === fromYear);
                if (index !== -1) {
                    return sortedData.slice(index);
                }
            }
        }

        return sortedData
    }



    return (
        <>
            <div className="new-reconciliation-form" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                <button
                    className="button-outlined-primary"
                    onClick={onClickRunTdsRecon}
                >
                    Run TDS Recon
                </button>

                {lastReconRunDatetime && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        <p style={{ marginRight: '10px' }}>
                            LAST RECON RUN DATE TIME:
                        </p>
                        <Chip label={lastReconRunDatetime} style={{ marginBottom: '3px' }} variant="outlined" color="primary" size="medium" />
                    </div>
                )}
            </div>


            {closeBoxWrapper && (
                <BoxWrapper
                    boxTitle="Error"
                    cutIcon={true}
                    children={errorMessageForBoxWrapper}
                    closeBoxWapper={FunForcloseBoxWapper}
                    colorForMessage="red"
                    ErrorIcon={<ErrorOutlineOutlinedIcon />}
                />
            )}

            {/* History table */}
            <BoxWrapper
                boxTitle="26AS TDS RECON"
                // add button
                exportToExcelEnable={newReconHistoryTable.length > 1 && true}
                exportButtonLabel={'Multi Year Export'}
                exportToExcelFn={newReconFormModalOpen}
                enableIcon1={true}
                enableIcon1Value={
                    <Tooltip title="Refresh">
                        <RefreshIcon color="primary" sx={{ fontSize: '30px' }} />
                    </Tooltip>
                }
                handleIcon1ClickFunction={refreshUploadHistoryTable}
            >
                <div className="responsive-table" style={{ maxHeight: '50vh' }}>
                    <table className="custome-table">
                        <thead>
                            <tr>
                                <th>Fiscal Year</th>
                                <th>TDS as per Books</th>
                                <th>TDS as per 26AS</th>
                                <th>Difference</th>
                                <th>Carry Forward</th>
                                <th>Matched in Next FY</th>
                                {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }}>Pair ID</th>}
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>{historyTableBody()}</tbody>
                    </table>
                </div>
            </BoxWrapper>

            {(newReconHistoryTable && newReconHistoryTable.length > 0) && <BoxWrapper boxTitle="26AS TDS RECON SUMMARY">
                <div>
                    <DashboardBarChart
                        chartTitle=""
                        chartCategories={newReconHistoryTable.map(item => item.financial_year)}
                        yAxisTitle="Amount"
                        chartData={chartData}
                    />
                </div>

            </BoxWrapper>}

            {renderLockConfirmationPopup()}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000} // Adjust as needed
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Use the state variable to determine severity
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={newReconFormOpen}
                onHide={newReconFormModalClose}
                dialogClassName="modal-50w"
            >
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title className="modal-title">
                        Multi Year Export
                    </Modal.Title>
                </Modal.Header>
                <ValidatorForm
                    // ref="form"
                    onSubmit={handleExport}
                // onError={(errors) => console.log(errors)}
                >
                    <Modal.Body>
                        <div className="new-recon-form-body">
                            <Grid container spacing={3}>
                                <Grid item sm>
                                    <SelectField
                                        SelectLabel="From Year"
                                        validator={["required"]}
                                        requiredField="*"
                                        errorMsg={["This field is required"]}
                                        choice_id="id"
                                        choice_name="year"
                                        choices={financialYearsList}
                                        name="fromYear"
                                        value={fromYear}
                                        textOnchangeFunction={exportFromYearChangeHandler}
                                    />
                                </Grid>
                                <Grid item sm>
                                    <SelectField
                                        SelectLabel="To Year"
                                        validator={["required"]}
                                        requiredField="*"
                                        errorMsg={["This field is required"]}
                                        choice_id="id"
                                        choice_name="year"
                                        choices={financialYearsList.length >= 1 && getToYear()}
                                        name="toYear"
                                        value={toYear}
                                        textOnchangeFunction={exportToYearChangeHandler}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="button-primary"
                            type="submit"
                            style={{ marginRight: "10px" }}
                        >
                            {!exportloading ? 'Export' : 'Exporting....'}
                        </button>
                        <button
                            type="button"
                            className="button-outlined-secondary"
                            onClick={newReconFormModalClose}
                        >
                            Close
                        </button>
                    </Modal.Footer>
                </ValidatorForm>
            </Modal>
        </>
    );
}

import React, { Component } from "react";
import classes from "./BusinessEntityDetails.module.css";
import axios from "../../axios-order";
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import Drawer from "@material-ui/core/Drawer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberSystem from "../../components/ui/indianRupesSystem";
import Grid from "@material-ui/core/Grid";
import InputField from "../../components/ui/inputField";
import SelectField from "../../components/ui/select";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import residential_status from "../../asserts/jsonData/residential_status.json";
import DatePicker from "../../components/ui/DatePicker";
import DatePickerFiscalYear from "../../components/ui/DatePickerFiscalYear";
import TextField from "@material-ui/core/TextField";
import State from "../../asserts/jsonData/state.json";
import PromoterCategories from "../../asserts/jsonData/Promoter_categories.json";
import AccountType from "../../asserts/jsonData/typeOfAccount.json";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ConnectionDetailsComp from "../BusinessEntityDetails/ConnectionDetails/connectionDetails";
import MailConnectionDetails from "./MailConnectionDetails/MailConnection";
import AgeingBucket from "./AgeingBucket/AgeingBucket";
import DueBucket from "./AgeingBucket/DueBucket/DueBucket";
import Tdsltds from "./TDSLtds/tds_ltds";
import TdsExemption from "./TDSExemption/exemption";
import MsmeInterest from "./MSMEInterest/msmeInterest";
import BoxWrapper from "../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RightDrawer from "../../components/ui/RightDrawer";

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL



const checkImage = (value) => {
  if (String(value.split(":")[1].split("/")[0]) === "image") {
    return true;
  } else {
    return false;
  }
};

class BusinessEntityDetails extends Component {
  constructor(props) {
    super(props);
    const businessEntityId = localStorage.getItem('business_entity_id');
    const gst = localStorage.getItem('gst');
    const pan = localStorage.getItem('pan');

    this.state = {

      showInputBox: false,
      secondsLeft: 600,
      business_entity_id: localStorage.getItem("business_entity_id"),
      entity_data: {},
      total_percentage: 0,
      owner_data: [],
      promoter_data: [],
      bank_data: [],
      balancesheet_data: [],
      finance_data: [],
      gst_data: [],
      files_data: [],
      connectionDetails: [],
      openShowDrawer: false,
      openPromoterShowDrawer: false,
      openBankShowDrawer: false,
      openBalanceSheetShowDrawer: false,
      openFinanceShowDrawer: false,
      openFilesShowDrawer: false,
      showOwnerdata: {},
      showPromoterdata: {},
      showBankdata: {},
      showBalanceSheetdata: {},
      showFinancedata: {},
      showFileData: {},
      responseData: {
        entity_id: '',
        org_gstin: '',
        username: '',
      },
      responseData: [],
      responseMessage: '',
      responseOTP: '',
      addDrawer: false,
      openEditDrawer: false,
      openEditPromoterDrawer: false,
      openEditBankDrawer: false,
      openEditBalanceSheetDrawer: false,
      openEditFinanceDrawer: false,
      openEditFilesDrawer: false,
      loading: true,
      showInput: false,
      otp: '',
      //Trial 

      form26AsTableData: {},
      entities: [],
      mainEntities: [],
      entityOptions: [],
      currentDate: "",

      urlTextField: localStorage.getItem("urlForTally"),

      addOwnerData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      editOwnerData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addPromoterData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      editPromoterData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addBankData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      editBankData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addBalanceSheetData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },

      editBalanceSheetData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addFinanceData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      editFinanceData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addFilesData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      editFilesData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
      addAutogstSheetData: {
        org_gstin: gst || '',
        username: '',
        entity_id: businessEntityId || '',
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        )
      },
      addAuto26asSheetData: {
        pan: null,
        password: null,
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
        source: null
      },
      usernameError: '',

      addPromoterOpen: false,
      addBankOpen: false,
      addBalanceSheetOpen: false,
      addFinanceOpen: false,
      addFilesOpen: false,
      addGSTOpen: false,
      add26ASOpen: false,
      addBranchOpne: false,
      sitemap: JSON.parse(localStorage.getItem("sitemap")),
    };
    ValidatorForm.addValidationRule('isUsernameNotEmpty', (value) => {
      return value.trim() !== '';
    });
    this.handleAddFilesInputChange = this.handleAddFilesInputChange.bind(this);
    this.countdown = null;

  }


  currentDate = () => {
    const CurrentDate = new Date();
    const pad = "00";
    const yy = CurrentDate.getFullYear().toString();
    const mm = (CurrentDate.getMonth() + 1).toString();
    const dd = CurrentDate.getDate().toString();
    this.setState({
      currentDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  componentDidMount = () => {
    toast.configure();
    this.getting_complete_data();
    this.get26AsAutoDownloadData()
    this.getFilesData();
    this.currentDate();
    this.fecthMainEntites()
    this.getRelatedEntites()
  };

  getRelatedEntites = () => {
    axios.get(`/get_related_entites/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        this.setState({ entities: res.data.records })
      }).catch((err) => {

      })
  }


  dateFormat = (value) => {
    if (value) {
      let old_date = String(value).split("-");
      return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
    }
  };



  getFilesData = () => {
    axios
      .get(`/get_files/${this.state.business_entity_id}`)
      .then((res) => {

        this.setState({
          files_data: res.data.data,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  getting_complete_data = () => {
    axios
      .get(`/get_complete_entity_data/${this.state.business_entity_id}`)
      .then((res) => {

        let total_percentage = 0;
        res.data.owner.map((v, i) => {
          return (total_percentage += parseFloat(v.percentage));
        });
        this.setState({
          owner_data: res.data.owner,
          entity_data: res.data.Entity,
          promoter_data: res.data.promoter,
          bank_data: res.data.bank,
          balancesheet_data: res.data.balancesheet,
          finance_data: res.data.financial,
          gst_data: res.data.Gst,
          total_percentage: total_percentage,
          loading: false,
        });
        console.log(this.state.finance_data);
        console.log(this.state.gst_data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  ownerTableHeader() {
    const tableHeader = [
      "first Name",
      "last name",
      "date of Birth",
      "mobile",
      "pan",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  ownerTableData() {
    if (this.state.owner_data.length >= 1) {
      return this.state.owner_data.map((tableData, index) => {
        const {
          first_name,
          last_name,
          dob,
          mobile,
          owner_pan,
          title,
          company_name,
          cin,
          percentage,
          telephone,
          father_spouse_name,
          nationality,
          designation,
          residential_status,
          din,
          middle_name,
          aadhaar_number,
          nationality_value,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{first_name}</td>
            <td>{last_name}</td>
            <td>{this.dateFormat(dob)}</td>
            <td>{mobile}</td>
            <td>{owner_pan}</td>
            <td>
              <Button
                style={{ minWidth: "0px" }}
                size="small"
                onClick={() =>
                  this.showOwnerHandler(
                    first_name,
                    last_name,
                    dob,
                    mobile,
                    owner_pan,
                    title,
                    company_name,
                    cin,
                    percentage,
                    telephone,
                    father_spouse_name,
                    nationality,
                    designation,
                    residential_status,
                    din,
                    middle_name,
                    aadhaar_number,
                    nationality_value
                  )
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                style={{ minWidth: "0px" }}
                size="small"
                onClick={() =>
                  this.editOwnerHandler(
                    first_name,
                    last_name,
                    dob,
                    mobile,
                    owner_pan,
                    title,
                    company_name,
                    cin,
                    percentage,
                    telephone,
                    father_spouse_name,
                    nationality,
                    designation,
                    residential_status,
                    din,
                    middle_name,
                    aadhaar_number
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() => this.deleteOwnerHandler(owner_pan)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  //---Add owner---
  handleDateOwnerChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };

  handleDateEditOwnerChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };

  handleDatePromoterChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };

  handleDateEditPromoterChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };

  handleDateTREDSPromoterChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          date_authorization_treds: `${yy}-${(pad + mm).slice(-2)}-${(
            pad + dd
          ).slice(-2)}`,
        },
      }));
    }
  };

  handleDateEditTREDSPromoterChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          date_authorization_treds: `${yy}-${(pad + mm).slice(-2)}-${(
            pad + dd
          ).slice(-2)}`,
        },
      }));
    }
  };

  handleDateBalanceSheetChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addBalanceSheetData: {
          ...prevState.addBalanceSheetData,
          as_of_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };



  handleDateEditBalanceSheetChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        editBalanceSheetData: {
          ...prevState.editBalanceSheetData,
          as_of_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };


  handleDateFinanceChange = (v) => {
    // alert(v);
    const yy = v.getFullYear().toString();
    // alert(yy);
    this.setState((prevState) => ({
      addFinanceData: {
        ...prevState.addFinanceData,
        fiscal_year: yy,
      },
    }));
  };

  handleDateEditFinanceChange = (v) => {
    const yy = v.getFullYear().toString();

    this.setState((prevState) => ({
      editFinanceData: {
        ...prevState.editFinanceData,
        fiscal_year: yy,
      },
    }));
  };

  addOwnerData = () => {
    this.setState({ addDrawer: true });
  };

  handleAddOwnerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "percentage") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "title") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "nationality") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "residential_status") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: String(value),
        },
      }));
    }
  };

  addOwnerSubmitHandler = () => {
    axios
      .post(`/post_entity_owner`, this.state.addOwnerData)
      .then((res) => {
        console.log(res)
        this.getting_complete_data();
        this.setState({
          addDrawer: false,
          addOwnerData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  addPromoterSubmitHandler = () => {
    axios
      .post(`/post_entity_promoter`, this.state.addPromoterData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({
          addPromoterOpen: false,
          addPromoterData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };



  editPromoterSubmitHandler = () => {
    axios
      .put(`/put_entity_promoter`, this.state.editPromoterData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({ openEditPromoterDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  addBankSubmitHandler = () => {
    axios
      .post(`/post_entity_bank`, this.state.addBankData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({
          addBankOpen: false,
          addBankData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  editBankSubmitHandler = () => {
    axios
      .put(`/put_entity_bank`, this.state.editBankData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({ openEditBankDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  addBalanceSheetSubmitHandler = () => {
    axios
      .post(`/post_entity_balance_sheet`, this.state.addBalanceSheetData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({
          addBalanceSheetOpen: false,
          addBalanceSheetData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  editBalanceSheetSubmitHandler = () => {
    axios
      .put(`/put_entity_balance_sheet`, this.state.editBalanceSheetData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({ openEditBalanceSheetDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };







  addFinanceSubmitHandler = () => {
    if (
      parseInt(this.state.addFinanceData.gross_profit) <
      parseInt(this.state.addFinanceData.net_profit) ||
      parseInt(this.state.addFinanceData.gross_profit) >
      parseInt(this.state.addFinanceData.turnover)
    ) {
      alert(
        "Please validate the Inputs, Gross Profit should be higher than Net Profit & Turnover should be higher than Gross Profit"
      );
      return false;
    }
    axios
      .post(`/post_entity_Financials`, this.state.addFinanceData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({
          addFinanceOpen: false,
          addFinanceData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  editFinanceSubmitHandler = () => {
    if (
      parseInt(this.state.editFinanceData.gross_profit) <
      parseInt(this.state.editFinanceData.net_profit) ||
      parseInt(this.state.editFinanceData.gross_profit) >
      parseInt(this.state.editFinanceData.turnover)
    ) {
      alert(
        "Please validate the Inputs, Gross Profit should be higher than Net Profit & Turnover should be higher than Gross Profit"
      );
      return false;
    }
    axios
      .put(`/put_entity_Financials`, this.state.editFinanceData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({ openEditFinanceDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  addFilesSubmitHandler = () => {
    if (this.state.invalidImage) {
      return false;
    }
    axios
      .post(
        `/post_files/${this.state.business_entity_id}`,
        this.state.addFilesData
      )
      .then((res) => {
        this.getFilesData();
        this.setState({
          addFilesOpen: false,
          addFilesData: {
            business_entity_id: parseInt(
              localStorage.getItem("business_entity_id")
            ),
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  editFilesSubmitHandler = () => {
    if (this.state.invalidImage) {
      return false;
    }
    axios
      .put(`/put_entity_files`, this.state.editFilesData)
      .then((res) => {
        this.getFilesData();
        this.setState({ openEditFilesDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  drawerToggleClose = () => {
    this.setState({
      addDrawer: false,
      addOwnerData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };

  drawerBankToggleClose = () => {
    this.setState({
      addBankOpen: false,
      addBankData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };

  drawerBankEditToggleClose = () => {
    this.setState({ openEditBankDrawer: false });
  };

  drawerBalanceSheetToggleClose = () => {
    this.setState({
      addBalanceSheetOpen: false,
      addBalanceSheetData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };

  drawerBalanceSheetEditToggleClose = () => {
    this.setState({ openEditBalanceSheetDrawer: false });
  };

  drawerFinanceToggleClose = () => {
    this.setState({
      addFinanceOpen: false,
      addFinanceData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };

  drawerFinanceEditToggleClose = () => {
    this.setState({ openEditFinanceDrawer: false });
  };

  drawerFilesToggleClose = () => {
    this.setState({
      addFilesOpen: false,
      addFilesData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };
  drawerGSTToggleClose = () => {
    this.setState({
      addGSTOpen: false
    })
  }

  drawer26ASToggleClose = () => {
    this.setState({
      add26ASOpen: false
    })
  }

  drawerBranchToggleClose = () => {
    this.setState({
      addBranchOpne: false
    })
  }



  drawerFilesEditToggleClose = () => {
    this.setState({ openEditFilesDrawer: false });
  };

  drawerPromoterToggleClose = () => {
    this.setState({ addPromoterOpen: false });
    this.setState({
      addPromoterData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    });
  };

  drawerPromoterEditToggleClose = () => {
    this.setState({ openEditPromoterDrawer: false });
  };

  editDrawerClose = () => {
    this.setState({ openEditDrawer: false });
  };
  //---Edit Owner---
  editOwnerHandler = (
    first_name,
    last_name,
    dob,
    mobile,
    owner_pan,
    title,
    company_name,
    cin,
    percentage,
    telephone,
    father_spouse_name,
    nationality,
    designation,
    residential_status,
    din,
    middle_name,
    aadhaar_number
  ) => {
    // alert(dob);
    this.setState((prevState) => ({
      editOwnerData: {
        ...prevState.editOwnerData,
        first_name: first_name,
        last_name: last_name,
        dob: dob,
        mobile: mobile,
        owner_pan: owner_pan,
        title: title,
        company_name: company_name,
        cin: cin,
        percentage: percentage,
        telephone: telephone,
        father_spouse_name: father_spouse_name,
        nationality: nationality,
        designation: designation,
        residential_status: residential_status,
        din: din,
        middle_name: middle_name,
        aadhaar_number: aadhaar_number,
      },
    }));
    this.setState({ openEditDrawer: true });
  };

  editPromoterHandler = (
    promoter_pan,
    title,
    first_name,
    middle_name,
    last_name,
    email,
    residential_status,
    din,
    nationality_id,
    dob,
    father_spouse_title,
    father_spouse_first_name,
    father_spouse_middle_name,
    father_spouse_last_name,
    roles_assigned_treds,
    date_authorization_treds,
    category_id,
    women_enterpreneur,
    designation,
    country_id,
    state_id,
    city,
    district,
    telephone_number,
    mobile_number,
    address_line1,
    address_line2,
    pincode,
    experience_years,
    experience_months,
    qualification,
    aadhaar,
    percentage_share
  ) => {
    this.setState((prevState) => ({
      editPromoterData: {
        ...prevState.editPromoterData,
        promoter_pan: promoter_pan,
        title: title,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        email: email,
        residential_status: residential_status,
        din: din,
        nationality_id: nationality_id,
        dob: dob,
        father_spouse_title: father_spouse_title,
        father_spouse_first_name: father_spouse_first_name,
        father_spouse_middle_name: father_spouse_middle_name,
        father_spouse_last_name: father_spouse_last_name,
        roles_assigned_treds: roles_assigned_treds,
        date_authorization_treds: date_authorization_treds,
        category_id: category_id,
        women_enterpreneur: women_enterpreneur,
        designation: designation,
        country_id: country_id,
        state_id: state_id,
        city: city,
        district: district,
        telephone_number: telephone_number,
        mobile_number: mobile_number,
        address_line1: address_line1,
        address_line2: address_line2,
        pincode: pincode,
        experience_years: experience_years,
        experience_months: experience_months,
        qualification: qualification,
        aadhaar: aadhaar,
        percentage_share: percentage_share,
      },
    }));
    this.setState({ openEditPromoterDrawer: true });
  };

  editBankHandler = (
    ifsc_code,
    bank_name,
    account_type,
    branch_name,
    account_number,
    designation_transaction_account,
    branch_address1,
    branch_address2,
    pincode,
    city,
    district,
    state_id,
    country_id,
    branch_email_id
  ) => {
    this.setState((prevState) => ({
      editBankData: {
        ...prevState.editBankData,
        ifsc_code: ifsc_code,
        bank_name: bank_name,
        account_type: account_type,
        branch_name: branch_name,
        account_number: account_number,
        designation_transaction_account: designation_transaction_account,
        branch_address1: branch_address1,
        branch_address2: branch_address2,
        pincode: pincode,
        city: city,
        district: district,
        state_id: state_id,
        country_id: country_id,
        branch_email_id: branch_email_id,
      },
    }));
    this.setState({ openEditBankDrawer: true });
  };

  editBalanceSheetHandler = (
    as_of_date,
    net_worth,
    long_term_borrowings,
    short_term_borrowings,
    fixed_assets,
    non_current_investments,
    current_investments,
    trade_receivables,
    inventory,
    current_liabilities,
    overdue_govt,
    other_assets,
    other_liabilities
  ) => {
    this.setState((prevState) => ({
      editBalanceSheetData: {
        ...prevState.editBalanceSheetData,
        as_of_date: as_of_date,
        net_worth: net_worth,
        long_term_borrowings: long_term_borrowings,
        short_term_borrowings: short_term_borrowings,
        fixed_assets: fixed_assets,
        non_current_investments: non_current_investments,
        current_investments: current_investments,
        trade_receivables: trade_receivables,
        inventory: inventory,
        current_liabilities: current_liabilities,
        overdue_govt: overdue_govt,
        other_assets: other_assets,
        other_liabilities: other_liabilities,
      },
    }));
    this.setState({ openEditBalanceSheetDrawer: true });
  };

  editFinanceHandler = (
    fiscal_year,
    projection_actual_flag,
    turnover,
    gross_profit,
    net_profit,
    total_borrowing,
    net_worth
  ) => {
    this.setState((prevState) => ({
      editFinanceData: {
        ...prevState.editFinanceData,
        fiscal_year: fiscal_year,
        projection_actual_flag: projection_actual_flag,
        turnover: turnover,
        gross_profit: gross_profit,
        net_profit: net_profit,
        total_borrowing: total_borrowing,
        net_worth: net_worth,
      },
    }));
    this.setState({ openEditFinanceDrawer: true });
  };

  editFileHandler = (document_name, document_type, document, document_id) => {
    this.setState((prevState) => ({
      editFilesData: {
        ...prevState.editFilesData,
        document_name: document_name,
        document_type: document_type,
        document: document,
        document_id: document_id,
      },
    }));
    this.setState({ openEditFilesDrawer: true });
  };

  handleEditOwnerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "percentage") {
      let per_value = value === "" ? 0 : value;

      if (!this.checkPercentage(per_value)) return false;

      if (!this.checkPercentage(value)) return false;
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: value,
        },
      }));
    } else if (name === "title") {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "nationality") {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "residential_status") {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: String(value),
        },
      }));
    }
  };

  editOwnerSubmitHandler = () => {
    axios
      .put(`/put_entity_owner`, this.state.editOwnerData)
      .then((res) => {
        this.getting_complete_data();
        this.setState({ openEditDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  showOwnerHandler = (
    first_name,
    last_name,
    dob,
    mobile,
    owner_pan,
    title,
    company_name,
    cin,
    percentage,
    telephone,
    father_spouse_name,
    nationality,
    designation,
    residential_status,
    din,
    middle_name,
    aadhaar_number,
    nationality_value
  ) => {
    this.setState((prevState) => ({
      showOwnerData: {
        ...prevState.showOwnerData,
        first_name: first_name,
        last_name: last_name,
        dob: dob,
        mobile: mobile,
        owner_pan: owner_pan,
        title: title,
        company_name: company_name,
        cin: cin,
        percentage: percentage,
        telephone: telephone,
        father_spouse_name: father_spouse_name,
        nationality: nationality,
        designation: designation,
        residential_status: residential_status,
        din: din,
        middle_name: middle_name,
        aadhaar_number: aadhaar_number,
        nationality_value: nationality_value,
      },
    }));
    this.setState({ openShowDrawer: true });
  };

  showPromoterHandler = (
    promoter_pan,
    title_value,
    first_name,
    middle_name,
    last_name,
    email,
    residential_status_value,
    din,
    nationality_value,
    dob,
    father_spouse_title_value,
    father_spouse_first_name,
    father_spouse_middle_name,
    father_spouse_last_name,
    roles_assigned_treds,
    date_authorization_treds,
    category_value,
    women_enterpreneur_value,
    designation,
    country_value,
    state_value,
    city,
    district,
    telephone_number,
    mobile_number,
    address_line1,
    address_line2,
    pincode,
    experience_years,
    experience_months,
    qualification,
    aadhaar,
    percentage_share
  ) => {
    this.setState((prevState) => ({
      showPromoterData: {
        ...prevState.showPromoterData,
        promoter_pan: promoter_pan,
        title: title_value,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        email: email,
        residential_status: residential_status_value,
        din: din,
        nationality_id: nationality_value,
        dob: dob,
        father_spouse_title: father_spouse_title_value,
        father_spouse_first_name: father_spouse_first_name,
        father_spouse_middle_name: father_spouse_middle_name,
        father_spouse_last_name: father_spouse_last_name,
        roles_assigned_treds: roles_assigned_treds,
        date_authorization_treds: date_authorization_treds,
        category_id: category_value,
        women_enterpreneur: women_enterpreneur_value,
        designation: designation,
        country_id: country_value,
        state_id: state_value,
        city: city,
        district: district,
        telephone_number: telephone_number,
        mobile_number: mobile_number,
        address_line1: address_line1,
        address_line2: address_line2,
        pincode: pincode,
        experience_years: experience_years,
        experience_months: experience_months,
        qualification: qualification,
        aadhaar: aadhaar,
        percentage_share: percentage_share,
      },
    }));
    this.setState({ openPromoterShowDrawer: true });
  };

  showBankHandler = (
    ifsc_code,
    bank_name,
    account_type_value,
    branch_name,
    account_number,
    designation_transaction_accoun_value,
    branch_address1,
    branch_address2,
    pincode,
    city,
    district,
    state_value,
    country_value,
    branch_email_id
  ) => {
    this.setState((prevState) => ({
      showBankData: {
        ...prevState.showBankData,
        ifsc_code: ifsc_code,
        bank_name: bank_name,
        account_type: account_type_value,
        branch_name: branch_name,
        account_number: account_number,
        designation_transaction_account: designation_transaction_accoun_value,
        branch_address1: branch_address1,
        branch_address2: branch_address2,
        pincode: pincode,
        city: city,
        district: district,
        state_id: state_value,
        country_id: country_value,
        branch_email_id: branch_email_id,
      },
    }));
    this.setState({ openBankShowDrawer: true });
  };

  showBalanceSheetHandler = (
    as_of_date,
    net_worth,
    long_term_borrowings,
    short_term_borrowings,
    fixed_assets,
    non_current_investments,
    current_investments,
    trade_receivables,
    inventory,
    current_liabilities,
    overdue_govt,
    other_assets,
    other_liabilities
  ) => {
    this.setState((prevState) => ({
      showBalanceSheetData: {
        ...prevState.showBalanceSheetData,
        as_of_date: as_of_date,
        net_worth: net_worth,
        long_term_borrowings: long_term_borrowings,
        short_term_borrowings: short_term_borrowings,
        fixed_assets: fixed_assets,
        non_current_investments: non_current_investments,
        current_investments: current_investments,
        trade_receivables: trade_receivables,
        inventory: inventory,
        current_liabilities: current_liabilities,
        overdue_govt: overdue_govt,
        other_assets: other_assets,
        other_liabilities: other_liabilities,
      },
    }));
    this.setState({ openBalanceSheetShowDrawer: true });
  };

  showFinanceHandler = (
    fiscal_year,
    projection_actual_flag,
    turnover,
    gross_profit,
    net_profit,
    total_borrowing,
    net_worth
  ) => {
    this.setState((prevState) => ({
      showFinanceData: {
        ...prevState.showFinanceData,
        fiscal_year: fiscal_year,
        projection_actual_flag: projection_actual_flag,
        turnover: turnover,
        gross_profit: gross_profit,
        net_profit: net_profit,
        total_borrowing: total_borrowing,
        net_worth: net_worth,
      },
    }));
    this.setState({ openFinanceShowDrawer: true });
  };

  showFileHandler = (document_name, document_type, document) => {
    this.setState((prevState) => ({
      showFilesData: {
        ...prevState.showFilesData,
        document: document,
        document_name: document_name,
        document_type: document_type,
      },
    }));
    this.setState({ openFilesShowDrawer: true });
  };

  showDrawerClose = () => {
    this.setState({ openShowDrawer: false });
  };

  showPromoterDrawerClose = () => {
    this.setState({ openPromoterShowDrawer: false });
  };

  showBankDrawerClose = () => {
    this.setState({ openBankShowDrawer: false });
  };

  showBalanceSheetDrawerClose = () => {
    this.setState({ openBalanceSheetShowDrawer: false });
  };

  showFinanceDrawerClose = () => {
    this.setState({ openFinanceShowDrawer: false });
  };

  showFilesDrawerClose = () => {
    this.setState({ openFilesShowDrawer: false });
  };

  deletePromoterHandler = (promoter_pan) => {
    axios
      .delete(
        `/delete_entity_promoter/${this.state.business_entity_id}/${promoter_pan}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  deleteOwnerHandler = (owner_pan) => {
    axios
      .delete(
        `/delete_entity_owner/${this.state.business_entity_id}/${owner_pan}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  deleteBankHandler = (ifsc_code, account_number) => {
    axios
      .delete(
        `/delete_entity_bank/${this.state.business_entity_id}/${ifsc_code}/${account_number}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  deleteBalanceSheetHandler = (as_of_date) => {
    axios
      .delete(
        `/delete_entity_balance_sheet/${this.state.business_entity_id}/${as_of_date}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  deleteFinanceHandler = (fiscal_year, projection_actual_flag) => {
    axios
      .delete(
        `/delete_entity_Financials/${this.state.business_entity_id}/${fiscal_year}/${projection_actual_flag}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  deleteFileHandler = (document_id) => {
    axios
      .delete(
        `/delete_entity_documents/${this.state.business_entity_id}/${document_id}`
      )
      .then((res) => {
        this.getFilesData();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  //---Owner table End---

  //---Promoter  table---
  promoterTableHeader() {
    const tableHeader = [
      "first Name",
      "last name",
      "email",
      "date of Birth",
      "mobile",
      "pan",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  promoterTableData() {
    if (this.state.promoter_data.length >= 1) {
      return this.state.promoter_data.map((tableData, index) => {
        const {
          promoter_pan,
          title,
          title_value,
          first_name,
          middle_name,
          last_name,
          email,
          residential_status,
          residential_status_value,
          din,
          nationality_id,
          nationality_value,
          dob,
          father_spouse_title,
          father_spouse_title_value,
          father_spouse_first_name,
          father_spouse_middle_name,
          father_spouse_last_name,
          roles_assigned_treds,
          date_authorization_treds,
          category_id,
          category_value,
          women_enterpreneur,
          women_enterpreneur_value,
          designation,
          country_id,
          country_value,
          state_id,
          state_value,
          city,
          district,
          telephone_number,
          mobile_number,
          address_line1,
          address_line2,
          pincode,
          experience_years,
          experience_months,
          qualification,
          aadhaar,
          percentage_share,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{first_name}</td>
            <td>{last_name}</td>
            <td>{email}</td>
            <td>{this.dateFormat(dob)}</td>
            <td>{mobile_number}</td>
            <td>{promoter_pan}</td>

            <td>
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.showPromoterHandler(
                    promoter_pan,
                    title_value,
                    first_name,
                    middle_name,
                    last_name,
                    email,
                    residential_status_value,
                    din,
                    nationality_value,
                    dob,
                    father_spouse_title_value,
                    father_spouse_first_name,
                    father_spouse_middle_name,
                    father_spouse_last_name,
                    roles_assigned_treds,
                    date_authorization_treds,
                    category_value,
                    women_enterpreneur_value,
                    designation,
                    country_value,
                    state_value,
                    city,
                    district,
                    telephone_number,
                    mobile_number,
                    address_line1,
                    address_line2,
                    pincode,
                    experience_years,
                    experience_months,
                    qualification,
                    aadhaar,
                    percentage_share
                  )
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.editPromoterHandler(
                    promoter_pan,
                    title,
                    first_name,
                    middle_name,
                    last_name,
                    email,
                    residential_status,
                    din,
                    nationality_id,
                    dob,
                    father_spouse_title,
                    father_spouse_first_name,
                    father_spouse_middle_name,
                    father_spouse_last_name,
                    roles_assigned_treds,
                    date_authorization_treds,
                    category_id,
                    women_enterpreneur,
                    designation,
                    country_id,
                    state_id,
                    city,
                    district,
                    telephone_number,
                    mobile_number,
                    address_line1,
                    address_line2,
                    pincode,
                    experience_years,
                    experience_months,
                    qualification,
                    aadhaar,
                    percentage_share
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() => this.deletePromoterHandler(promoter_pan)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ---ADD Promoter---
  handleDatePromoterChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
      }));
    }
  };

  addPromoterOpenToggleData = () => {
    this.setState({ addPromoterOpen: true });
  };

  addBankOpenToggleData = () => {
    this.setState({ addBankOpen: true });
  };

  addFinanceOpenToggleData = () => {
    this.setState({ addFinanceOpen: true });
  };

  addFilesOpenToggleData = () => {
    this.setState({ addFilesOpen: true });
  };

  addGSTOpenToggleData = () => {
    this.setState({ addGSTOpen: true })
  }

  add26ASOpenToggleData = () => {
    this.setState({ add26ASOpen: true })
    this.setState((prevState) => ({
      addAuto26asSheetData: {
        ...prevState.addAuto26asSheetData,
        pan: prevState.form26AsTableData.pan,
        password: prevState.form26AsTableData.password,
        source: prevState.form26AsTableData.source
      }
    }));

  }

  addBranchOpenToggleData = () => {
    this.setState({ addBranchOpne: true })
  }



  addBalanceSheetOpenToggleData = () => {
    this.setState({ addBalanceSheetOpen: true });
  };

  checkPercentage = (value) => {
    if (value === "") {
      return true;
    } else {
      var x = parseFloat(value);
      if (isNaN(x) || x < 0 || x > 100) {
        return false;
      }
      return true;
    }
  };

  checkYrMn = (value) => {
    var x = parseInt(value);
    if (isNaN(x) || x < 0 || x > 999) {
      return false;
    }
    return true;
  };

  handleAddOwnerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "percentage") {
      if (!this.checkPercentage(value)) return false;
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: value,
        },
      }));

    } else if (name === "title") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "nationality") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === "residential_status") {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: String(value),
        },
      }));
    }
  };
  //---Promoter table End---

  handleAddPromoterInputChange = (event) => {
    let inputIntArr = [
      "title",
      "residential_status",
      "nationality_id",
      "father_spouse_title",
      "category_id",
      "country_id",
      "state_id",
      "women_enterpreneur",
    ];
    let yrMnArr = ["experience_years", "experience_months"];
    let inputFloatArr = ["percentage_share"];
    const name = event.target.name;
    const value = event.target.value;

    if (yrMnArr.indexOf(name) !== -1) {
      let int_value = value === "" ? 0 : value;
      if (!this.checkYrMn(int_value)) return false;
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          [name]: parseInt(int_value),
        },
      }));
    } else if (inputIntArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          [name]: parseInt(value),
        },
      }));
    } else if (inputFloatArr.indexOf(name) !== -1) {
      let per_value = value === "" ? 0 : value;
      if (!this.checkPercentage(per_value)) return false;
      if (!this.checkPercentage(value)) return false;
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        addPromoterData: {
          ...prevState.addPromoterData,
          [name]: String(value),
        },
      }));
    }
  };

  handleEditPromoterInputChange = (event) => {
    let inputIntArr = [
      "title",
      "residential_status",
      "nationality_id",
      "father_spouse_title",
      "category_id",
      "country_id",
      "state_id",
      "women_enterpreneur",
    ];
    let yrMnArr = ["experience_years", "experience_months"];
    let inputFloatArr = ["percentage_share"];
    const name = event.target.name;
    const value = event.target.value;

    if (yrMnArr.indexOf(name) !== -1) {
      let int_value = value === "" ? 0 : value;
      if (!this.checkYrMn(int_value)) return false;
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          [name]: parseInt(int_value),
        },
      }));
    } else if (inputIntArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          [name]: parseInt(value),
        },
      }));
    } else if (inputFloatArr.indexOf(name) !== -1) {
      let per_value = value === "" ? 0 : value;
      if (!this.checkPercentage(per_value)) return false;
      if (!this.checkPercentage(value)) return false;
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        editPromoterData: {
          ...prevState.editPromoterData,
          [name]: String(value),
        },
      }));
    }
  };

  handleAddBankInputChange = (event) => {
    let inputIntArr = [
      "account_type",
      "country_id",
      "state_id",
      "designation_transaction_account",
    ];
    let inputFloatArr = ["percentage_share"];
    const name = event.target.name;
    const value = event.target.value;
    if (inputIntArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        addBankData: {
          ...prevState.addBankData,
          [name]: parseInt(value),
        },
      }));
    } else if (inputFloatArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        addBankData: {
          ...prevState.addBankData,
          [name]: parseFloat(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        addBankData: {
          ...prevState.addBankData,
          [name]: String(value),
        },
      }));
    }
  };

  handleEditBankInputChange = (event) => {
    let inputIntArr = [
      "account_type",
      "country_id",
      "state_id",
      "designation_transaction_account",
    ];
    let inputFloatArr = ["percentage_share"];
    const name = event.target.name;
    const value = event.target.value;
    if (inputIntArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        editBankData: {
          ...prevState.editBankData,
          [name]: parseInt(value),
        },
      }));
    } else if (inputFloatArr.indexOf(name) !== -1) {
      this.setState((prevState) => ({
        editBankData: {
          ...prevState.editBankData,
          [name]: parseFloat(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        editBankData: {
          ...prevState.editBankData,
          [name]: String(value),
        },
      }));
    }
  };

  numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  numberFormat = (value) => {
    if (value == "") {
      return "";
    } else {
      console.log(value);
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    }
  };

  handleAddBalanceSheetInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "as_of_date") {
      this.setState((prevState) => ({
        addBalanceSheetData: {
          ...prevState.addBalanceSheetData,
          [name]: String(value),
        },
      }));
    } else {
      var numb = value.match(/\d/g);
      if (numb && numb.length > 10) return false;
      numb = numb ? numb.join("") : 0;
      this.setState((prevState) => ({
        addBalanceSheetData: {
          ...prevState.addBalanceSheetData,
          [name]: parseFloat(numb),
        },
      }));
    }
  };



  handleAddGSTAuto = (event, fieldName) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      addAutogstSheetData: {
        ...prevState.addAutogstSheetData,
        [fieldName]: value,
      },
    }));
  }


  handleAdd26ASAuto = (event, fieldName) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      addAuto26asSheetData: {
        ...prevState.addAuto26asSheetData,
        [fieldName]: value,
      },
    }));
  }









  componentDidMount() {
    ValidatorForm.addValidationRule('isNonEmpty', (value) => value.trim() !== '');
  }

  addGSTSubmitHandler = () => {
    if (!this.state.addAutogstSheetData.username) {
      this.setState({ usernameError: 'Please Enter the Username *' });
      return;
    } else {
      this.setState({ usernameError: '' }); // Clear the error message if the field is not empty
    }

    const { entity_id, org_gstin, username } = this.state.addAutogstSheetData;

    const formattedPayload = `entity_id=${entity_id}&org_gstin=${org_gstin}&username=${username}`;

    // Close the toggle immediately after clicking the save button
    this.drawerGSTToggleClose();

    axios
      .post(`${apiUrl}/gstDownload/create_client/`, formattedPayload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      })
      .then((response) => {
        // Handle the API response data
        // console.log('API response:', response.data);
        this.getting_complete_data();
        this.setState({
          responseData: [response.data], // Assuming the response data is a single object
        });
      })
      .catch((error) => {
        // Handle the error
        console.error('API error:', error);
      });
  }

  get26AsAutoDownloadData = () => {
    axios.get(`/auto_download_user_list_26as/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        this.setState({ form26AsTableData: res.data.records })
      })
      .catch((err) => {

      })
  }

  add26ASSubmitHandler = () => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    const { pan, password, source } = this.state.addAuto26asSheetData;

    if (!pan || !password) {
      toast.error("PAN and Password are required", {
        autoClose: 3000,
        progress: 0,
      });
      return;
    }

    if (!panRegex.test(pan)) {
      toast.error("Invalid PAN format. PAN should be in the Proper format", {
        autoClose: 3000,
        progress: 0,
      });
      return;
    }

    axios.post('/save_26as_auto_download_user', this.state.addAuto26asSheetData)
      .then((res) => {
        this.drawer26ASToggleClose();
        toast.success(res?.data?.message || "Data saved successfully!", {
          autoClose: 3000,
          progress: 0,
        });
        this.get26AsAutoDownloadData()
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "An error occurred while saving data.", {
          autoClose: 3000,
          progress: 0,
        });
      });

  };


  addBranchSubmitHandler = () => {
    if (this.state.mainEntities.length < 1) {
      this.drawerBranchToggleClose()
      toast.error("Please select the main entities before adding them to the group.", {
        autoClose: 3000,
        progress: 0,
      });
      return
    }
    axios
      .post('/add_main_entity_to_group', { business_entity_id: localStorage.getItem("business_entity_id"), entities: this.state.mainEntities, is_uploads: localStorage.getItem("is_uploads") })
      .then((response) => {
        this.getRelatedEntites();
        this.drawerBranchToggleClose()
        toast.success(response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      })
      .catch((error) => {
      });
  }



  drawerGSTToggleClose = () => {
    this.setState({
      addGSTOpen: false
    });
  }










  handleEditBalanceSheetInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "as_of_date") {
      this.setState((prevState) => ({
        editBalanceSheetData: {
          ...prevState.editBalanceSheetData,
          [name]: String(value),
        },
      }));
    } else {
      var numb = value.match(/\d/g);
      if (numb && numb.length > 10) return false;
      numb = numb ? numb.join("") : 0;
      this.setState((prevState) => ({
        editBalanceSheetData: {
          ...prevState.editBalanceSheetData,
          [name]: parseFloat(numb),
        },
      }));
    }
  };

  handleEntityChange = (event) => {
    console.log(event.target.value)
    this.setState({
      ...this.state.mainEntities,
      mainEntities: event.target.value,

    });
  };

  fecthMainEntites = () => {
    axios.post('/fetch_main_entities_not_in_groups', { business_entity_id: localStorage.getItem("business_entity_id") })
      .then((response) => {
        const data = response.data;
        if (data.status) {
          console.log(data.entity_names)
          this.setState({ entityOptions: data.entity_names })
        }
      })
      .catch((error) => {

      })
  }

  getEntityName = (id) => {
    const { entityOptions } = this.state;
    const entity = entityOptions.find(entity => entity.id === id);

    return entity ? entity.name : '';
  }

  handleAddFinanceInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "as_of_date" || name === "projection_actual_flag") {
      this.setState((prevState) => ({
        addFinanceData: {
          ...prevState.addFinanceData,
          [name]: String(value),
        },
      }));
    } else {
      var numb = value.match(/\d/g);
      if (numb && numb.length > 10) return false;
      numb = numb ? numb.join("") : 0;

      this.setState((prevState) => ({
        addFinanceData: {
          ...prevState.addFinanceData,
          [name]: parseFloat(numb),
        },
      }));
    }
  };

  handleEditFinanceInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "as_of_date" || name === "projection_actual_flag") {
      this.setState((prevState) => ({
        editFinanceData: {
          ...prevState.editFinanceData,
          [name]: String(value),
        },
      }));
    } else {
      var numb = value.match(/\d/g);
      if (numb && numb.length > 10) return false;
      numb = numb ? numb.join("") : 0;
      this.setState((prevState) => ({
        editFinanceData: {
          ...prevState.editFinanceData,
          [name]: parseFloat(numb),
        },
      }));
    }
  };

  handleAddFilesInputChange = (event) => {
    const name = event.target.name;

    if (name === "document") {
      // const dis = this;
      this.setState((prevState) => ({
        invalidImage: null,
        addFilesData: {
          ...prevState.addFilesData,
          document: null,
        },
      }));
      const files = event.target.files[0];

      if (!files) {
        this.setState({ invalidImage: "Please select image." });
        return false;
      }

      if (!files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
        this.setState({
          invalidImage:
            "Please select valid file. Only jpg|jpeg|png|pdf is supported",
        });
        return false;
      }

      const fileSize = files.size / 1024 / 1024; // in MiB
      if (fileSize > 3) {
        this.setState({
          invalidImage: "Please add file less than 3 MB",
        });
        return false;
      }

      const p1 = new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = (event) => {
          resolve(event.target.result);
        };
      });

      Promise.all([p1])
        .then((values) => {
          this.setState((prevState) => ({
            addFilesData: {
              ...prevState.addFilesData,
              [name]: values[0],
            },
          }));
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            autoClose: 3000,
            progress: 0,
          })
        );
    } else {
      const value = event.target.value;

      this.setState((prevState) => ({
        addFilesData: {
          ...prevState.addFilesData,
          [name]: String(value),
        },
      }));
    }
  };

  handleEditFilesInputChange = (event) => {
    const name = event.target.name;

    if (name === "document") {
      // const dis = this;

      this.setState((prevState) => ({
        invalidImage: null,
        editFilesData: {
          ...prevState.editFilesData,
          document: null,
        },
      }));
      const files = event.target.files[0];

      if (!files) {
        this.setState({ invalidImage: "Please select image." });
        return false;
      }

      if (!files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
        this.setState({
          invalidImage:
            "Please select valid file. Only jpg|jpeg|png|pdf is supported",
        });
        return false;
      }

      const p1 = new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = (event) => {
          resolve(event.target.result);
        };
      });

      Promise.all([p1])
        .then((values) => {
          this.setState((prevState) => ({
            editFilesData: {
              ...prevState.editFilesData,
              [name]: values[0],
            },
          }));
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            autoClose: 3000,
            progress: 0,
          })
        );
    } else {
      const value = event.target.value;
      this.setState((prevState) => ({
        editFilesData: {
          ...prevState.editFilesData,
          [name]: String(value),
        },
      }));
    }
  };

  //---Bank Details  table---
  bankTableHeader() {
    const tableHeader = [
      "bank Name",
      "branch name",
      "branch email id",
      "ifsc code",
      "account type",
      "account number",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  bankTableData() {
    if (this.state.bank_data.length >= 1) {
      return this.state.bank_data.map((tableData, index) => {
        const {
          ifsc_code,
          bank_name,
          account_type,
          account_type_value,
          branch_name,
          account_number,
          designation_transaction_account,
          designation_transaction_accoun_value,
          branch_address1,
          branch_address2,
          pincode,
          city,
          district,
          state_id,
          state_value,
          country_id,
          country_value,
          branch_email_id,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{bank_name}</td>
            <td>{branch_name}</td>
            <td>{branch_email_id}</td>
            <td>{ifsc_code}</td>
            <td>{account_type_value}</td>
            <td>{account_number}</td>
            <td>
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.showBankHandler(
                    ifsc_code,
                    bank_name,
                    account_type_value,
                    branch_name,
                    account_number,
                    designation_transaction_accoun_value,
                    branch_address1,
                    branch_address2,
                    pincode,
                    city,
                    district,
                    state_value,
                    country_value,
                    branch_email_id
                  )
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.editBankHandler(
                    ifsc_code,
                    bank_name,
                    account_type,
                    branch_name,
                    account_number,
                    designation_transaction_account,
                    branch_address1,
                    branch_address2,
                    pincode,
                    city,
                    district,
                    state_id,
                    country_id,
                    branch_email_id
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.deleteBankHandler(ifsc_code, account_number)
                }
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  //---Bank table End---

  //---Banlancesheet Details  table---
  balancesheetTableHeader() {
    const tableHeader = [
      "as of date",
      "trade receivables",
      "short term borrowings",
      "net worth",
      "current investments",
      "non current investments",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  balancesheetTableData() {
    if (this.state.balancesheet_data.length >= 1) {
      return this.state.balancesheet_data.map((tableData, index) => {
        const {
          as_of_date,
          net_worth,
          long_term_borrowings,
          short_term_borrowings,
          fixed_assets,
          non_current_investments,
          current_investments,
          trade_receivables,
          inventory,
          current_liabilities,
          overdue_govt,
          other_assets,
          other_liabilities,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>
              {this.dateFormat(as_of_date)}
            </td>
            <td>
              <NumberSystem value={trade_receivables} />
            </td>
            <td>
              <NumberSystem value={short_term_borrowings} />
            </td>
            <td>
              <NumberSystem value={net_worth} />
            </td>
            <td>
              <NumberSystem value={current_investments} />
            </td>
            <td>
              <NumberSystem value={non_current_investments} />
            </td>
            <td>
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.showBalanceSheetHandler(
                    as_of_date,
                    net_worth,
                    long_term_borrowings,
                    short_term_borrowings,
                    fixed_assets,
                    non_current_investments,
                    current_investments,
                    trade_receivables,
                    inventory,
                    current_liabilities,
                    overdue_govt,
                    other_assets,
                    other_liabilities
                  )
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.editBalanceSheetHandler(
                    as_of_date,
                    net_worth,
                    long_term_borrowings,
                    short_term_borrowings,
                    fixed_assets,
                    non_current_investments,
                    current_investments,
                    trade_receivables,
                    inventory,
                    current_liabilities,
                    overdue_govt,
                    other_assets,
                    other_liabilities
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() => this.deleteBalanceSheetHandler(as_of_date)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  form26AsTableHeader() {
    return (
      <div className="responsive-table">
        <table className="custome-table">
          <thead>
            <tr>
              <th>Source</th>
              <th>PAN</th>
              <th>Password</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.form26AsTableData && this.state.form26AsTableData.pan && this.state.form26AsTableData.password ? (
              <tr>
                <td>{this.state.form26AsTableData.source}</td>
                <td>{this.state.form26AsTableData.pan}</td>
                <td>{this.state.form26AsTableData.password}</td>
                <td><Button
                  size="small"
                  style={{ minWidth: "0px" }}
                  onClick={() =>
                    this.add26ASOpenToggleData()
                  }
                >
                  <EditOutlinedIcon className="editIcon" />
                </Button></td>
              </tr>
            ) : (
              <tr>
                <td colSpan="4" style={{ color: "red", textAlign: "center" }}>
                  No data found!
                </td>
              </tr>
            )}

          </tbody>
        </table>
      </div>
    );
  }



  GstTableHeader() {
    const header = [
      "UserName",
      "Entity_ID",
      "GSTIN",
      "Generate Token" // Add the new header
    ];

    const handleGenerateToken = () => {
      axios
        .get(`${apiUrl}/gstDownload/orgauth/`)
        .then((response) => {
          console.log(response.data);
          this.setState({ responseMessage: response.data }); // Set the response message in state
          alert(response.data);
          setTimeout(() => {
            this.setState({ responseMessage: '' })
          }, 5000)
        })
        .catch(error => {
          console.error('Error:', error);
        });
      console.log('Generate Token clicked!');
    };




    const handleGenerateOTP = () => {
      const entity_id = localStorage.getItem("business_entity_id");
      console.log("entity_id", entity_id);
      const url = `${apiUrl}/gstDownload/getorgotp/`;

      // Create a new FormData instance
      const formData = new FormData();
      formData.append('entity_id', entity_id);

      // Perform a POST request to the backend with the FormData
      axios
        .post(url, formData)
        .then(response => {
          // Handle the response from the backend
          console.log(response);
          if (response?.data?.message_type === 'success') {
            toast.success(response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
            this.setState({ showInputBox: true });

            if (this.countdown) {
              clearInterval(this.countdown);
              this.setState({ secondsLeft: 600 });
            }

            this.countdown = setInterval(() => {
              this.setState(prevState => ({
                secondsLeft: prevState.secondsLeft - 1,
              }), () => {
                if (this.state.secondsLeft === 0) {
                  clearInterval(this.countdown);
                  this.setState({ showInputBox: false });
                }
              });

              const minutes = Math.floor(this.state.secondsLeft / 60);
              const seconds = this.state.secondsLeft % 60;

              // Update the display on the screen
              const displayTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
              console.log(displayTime);

            }, 1000);
          } else {
            toast.error(response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.log(error);
        });
    };


    // const handleGenerateOTP = () => {
    //   const entity_id = localStorage.getItem("business_entity_id");
    //   console.log('entity_id',entity_id);
    //   const url = 'http://192.168.100.158:8000/gstDownload/getorgotp/';

    //   // Create a new FormData instance
    //   const formData = new FormData();
    //   formData.append('entity_id', entity_id);

    //   // Perform a POST request to the backend with the FormData
    //   axios.post(url, formData)
    //     .then(response => {
    //       // Handle the response from the backend
    //       console.log(response);
    //       this.setState({ showInput: true });
    //     })
    //     .catch(error => {
    //       // Handle any errors that occurred during the request
    //       console.log(error);
    //     });

    //   this.setState(prevState => ({
    //     showInputBox: !prevState.showInputBox,
    //   }));

    //   setTimeout(()=>{
    //       this.setState({showInputBox:false})
    //   },4000)

    // };




    // const handleOTPChange = (event) => {
    //   this.setState({ otp: event.target.value });
    // };
    const handleOTPChange = (event) => {
      const otp = event.target.value;
      const regex = /^\d{0,6}$/;

      if (regex.test(otp)) {
        this.setState({ otp });
      }
    };






    const handleSubmitOTP = () => {
      const otpValue = this.state.otp;
      const businessEntityId = this.state.business_entity_id;
      // const businessEntityId = this.state.business_entity_id;
      const url = `${apiUrl}/gstDownload/inputorgotp/`;

      const formData = new FormData();
      formData.append('input_otp', otpValue);
      formData.append('entity_id', businessEntityId);

      // Perform a POST request to the backend with the FormData
      axios.post(url, formData)
        .then(response => {
          this.setState({ responseOTP: response.data.data });
          // Handle the response from the backend
          // Example: show a success message or perform additional actions
        })
        .catch(error => {
          // Handle any errors that occurred during the request
        });
      clearInterval(this.countdown);
      // Reset the state
      this.setState({
        otp: '',
        showInputBox: false,
      });
    };




    const handleHideSubmitButton = () => {
      this.setState({
        showInputBox: false,
        otp: '',

      });

    }


    return header.map((key, index) => {
      if (key === "Generate Token") {
        return (
          <th key={index}>
            {/* <button onClick={handleGenerateToken} style={{ borderRadius: '6px',border:'1px solid black', color: '#18183b',height:'30px', backgroundColor: 'rgb(196, 208, 217)',transition: 'background-color 0.3s ease',width:"120px",color: '#121212', fontFamily: 'Arial, Helvetica, sans-serif' }}
              onMouseEnter={(e) => { e.target.style.backgroundColor = '#3d2d6c'; e.target.style.color = 'white'; }}
              onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgb(196, 208, 217)'; e.target.style.color = '#18183b'; }}
            >Auth  Token</button> */}



            {/* &nbsp;&nbsp;&nbsp;&nbsp;{ ' '}
             <button onClick={handleGenerateOTP}style={{ borderRadius: '6px',border:'1px solid black ', color: '#18183b',height:'30px', backgroundColor: 'rgb(196, 208, 217)' ,transition: 'background-color 0.3s ease',width:"120px",color: '#121212', fontFamily: 'Arial, Helvetica, sans-serif'}}
              onMouseEnter={(e) => { e.target.style.backgroundColor = '#3d2d6c'; e.target.style.color = 'white'; }}
              onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgb(196, 208, 217)'; e.target.style.color = '#18183b'; }}>Generate OTP</button> */}


            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
              <button
                onClick={handleGenerateToken}
                style={{
                  borderRadius: '6px',
                  border: '1px solid black',
                  color: '#18183b',
                  height: '30px',
                  backgroundColor: 'rgb(196, 208, 217)',
                  transition: 'background-color 0.3s ease',
                  width: '120px',
                  color: '#121212',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#3d2d6c';
                  e.target.style.color = 'white';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'rgb(196, 208, 217)';
                  e.target.style.color = '#18183b';
                }}
              >
                Auth Token
              </button>

              <button
                onClick={handleGenerateOTP}
                style={{
                  borderRadius: '6px',
                  border: '1px solid black',
                  color: '#18183b',
                  height: '30px',
                  backgroundColor: 'rgb(196, 208, 217)',
                  transition: 'background-color 0.3s ease',
                  width: '120px',
                  color: '#121212',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#3d2d6c';
                  e.target.style.color = 'white';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'rgb(196, 208, 217)';
                  e.target.style.color = '#18183b';
                }}
              >
                Generate OTP
              </button>
            </div>



            {this.state.showInputBox && (
              <span style={{ marginLeft: '10px' }}>
                {Math.floor(this.state.secondsLeft / 60)}:
                {this.state.secondsLeft % 60 < 10 ? '0' : ''}
                {this.state.secondsLeft % 60}
              </span>
            )}

            {this.state.showInputBox && (
              <div>


                <input type="text" placeholder=" - - - - - - " value={this.state.otp} onChange={handleOTPChange} style={{ height: '30px', letterSpacing: '10px', width: '180px', textAlign: 'left', marginRight: '20px' }} />
                {/* <button onClick={handleSubmitOTP} style={{ borderRadius: '3px', color: 'white', height: '40px', width: '60px', marginRight: '10px', backgroundColor: 'rgb(196, 208, 217)' }}>Submit</button> */}
                {this.state.otp.length === 6 && (
                  <button
                    onClick={handleSubmitOTP}
                    style={{
                      borderRadius: '3px',
                      color: 'white',
                      height: '30px',
                      width: '48px',
                      marginRight: '10px',
                      backgroundColor: 'rgb(196, 208, 217)',
                    }}
                  >
                    Submit
                  </button>
                )}
                <button onClick={handleHideSubmitButton} style={{ borderRadius: '3px', color: 'white', height: '30px', width: '45px', backgroundColor: 'rgb(196, 208, 217)' }}>Hide</button>
              </div>

            )}
          </th>
        );
      } else {
        return <th key={index}>{key.toUpperCase()}</th>;
      }
    });
  }




  ///value gst auto 
  // GstTableData() {
  //   const { responseData } = this.state;

  //   if (responseData) {
  //     return responseData.map((data, index) => (
  //       <tr key={index}>
  //         <td>{data.username}</td>
  //         <td>{data.entity_id}</td>
  //         <td>{data.org_gstin}</td>
  //       </tr>
  //     ));
  //   } else {
  //     return null;
  //   }
  // }


  //---Banlancesheet table End---

  //---Finance Details  table---
  financeTableHeader() {
    const tableHeader = [
      "Fiscal Year",
      "Projected/Actual",
      "Turnover",
      "Gross Profit",
      "Net Profit",
      "Total Borrowings",
      "Net Worth",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }


  financeTableData() {
    if (this.state.finance_data.length >= 1) {
      return this.state.finance_data.map((tableData, index) => {
        const {
          fiscal_year,
          projection_actual_flag,
          turnover,
          gross_profit,
          net_profit,
          total_borrowing,
          net_worth,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{`${fiscal_year - 1
              }-${fiscal_year}`}</td>
            <td>{projection_actual_flag}</td>
            <td>
              <NumberSystem value={turnover} />
            </td>
            <td>
              <NumberSystem value={gross_profit} />
            </td>
            <td>
              <NumberSystem value={net_profit} />
            </td>
            <td>
              <NumberSystem value={total_borrowing} />
            </td>
            <td>
              <NumberSystem value={net_worth} />
            </td>
            <td>
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.showFinanceHandler(
                    fiscal_year,
                    projection_actual_flag,
                    turnover,
                    gross_profit,
                    net_profit,
                    total_borrowing,
                    net_worth
                  )
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.editFinanceHandler(
                    fiscal_year,
                    projection_actual_flag,
                    turnover,
                    gross_profit,
                    net_profit,
                    total_borrowing,
                    net_worth
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.deleteFinanceHandler(fiscal_year, projection_actual_flag)
                }
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  //---Finance table End---

  //---Document Details  table---
  filesTableHeader() {
    const tableHeader = [
      "Document Name",
      "Document Type",
      "Document",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  filesTableData() {
    if (this.state.files_data.length >= 1) {
      return this.state.files_data.map((tableData, index) => {
        const { document_name, document_type, document, document_id } =
          tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{document_name}</td>
            <td>{document_type}</td>
            <td>
              {checkImage(document) ? (
                <img
                  src={document}
                  alt="Loading"
                  style={{ width: "80px", height: "80px" }}
                />
              ) : (
                <FileCopyIcon />
              )}
            </td>

            <td>
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.showFileHandler(document_name, document_type, document)
                }
              >
                <VisibilityOutlinedIcon className="showIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() =>
                  this.editFileHandler(
                    document_name,
                    document_type,
                    document,
                    document_id
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
              |
              <Button
                size="small"
                style={{ minWidth: "0px" }}
                onClick={() => this.deleteFileHandler(document_id)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  //---Finance table End---
  //  Trial Details
  filesTableHead() {
    const tableHeader = [
      "GSTIN",
      "UserName",
      "OTP",
      "Session ID"
    ]
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>
    })
  }

  // GstTableData() {
  //    if(this.state.gst_data.length >= 1){
  //      return this.state.gst_data.map((table,index)=>{
  //         const {org_gstin,username,entity_id} = table;
  //         return (
  //           <tr key={index}>
  //             <td>{org_gstin}</td>
  //             <td>{username}</td>
  //             <td>{entity_id}</td>
  //           </tr>
  //         )
  //      })


  //    }
  // }
  // GstTableData() {
  //   const { gst_data } = this.state;

  //   if (gst_data && gst_data.length >= 1) {
  //     return gst_data.map((table, index) => {
  //       const { org_gstin, username, entity_id } = table;
  //       return (
  //         <tr key={index}>
  //           <td>{username}</td>
  //           <td>{entity_id}</td>
  //           <td>{org_gstin}</td>
  //         </tr>
  //       );
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td style={{ color: "red" }} colSpan={3}>
  //           No data found!
  //         </td>
  //       </tr>
  //     );
  //   }
  // }

  GstTableData() {
    const { responseData } = this.state;
    console.log('responseData:', responseData);

    if (Object.keys(responseData).length > 0) {
      return (
        <tr>
          <td>{responseData.username}</td>
          <td>{responseData.entity_id}</td>
          <td>{responseData.org_gstin}</td>

        </tr>
      );
    } else {
      return (
        <tr>
          {/* <td style={{ color: "red" }} colSpan={3}>
          No data found!
          <td><button>Click on Me</button></td>
        </td> */}
          {/* <td style={{ color: "red", display: "flex", justifyContent: "flex-end",paddingLeft:"560px" }} colSpan={3}>
  <span>No data found!</span>
  <button>Click on Me</button>
</td> */}

        </tr>
      );
    }
  }





  sendUrlInApi = () => {
    axios
      .post(`cws_tally_uploads/post_url/`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        url: this.state.urlTextField,
      })
      .then((res) => {
        if (res.data.status === true) {
          localStorage.setItem("UploadTallyStatus", res.data.status);
          localStorage.setItem("urlForTally", res.data.url);
          window.location.reload();
        } else {
          localStorage.setItem("UploadTallyStatus", res.data.status);
          localStorage.setItem("urlForTally", res.data.url);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { responseMessage } = this.state;
    const { responseOTP } = this.state;
    const { showInputBox, secondsLeft } = this.state;





    return (
      <>
        {/* ---Entity Details--- */}
        <div>
          <BoxWrapper boxTitle="Entity Details">
            {this.state.entity_data ? (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{ paddingRight: "0px", border: "none" }}
                          >
                            Entity name :
                          </TableCell>
                          <TableCell
                            style={{
                              paddingLeft: "0px",
                              textTransform: "capitalize",
                              border: "none",
                            }}
                          >
                            {this.state.entity_data.entity_name}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingRight: "0px", border: "none" }}
                          >
                            Contact Person :
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "0px", border: "none" }}
                          >
                            {this.state.entity_data.contact_person}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingRight: "0px", border: "none" }}
                          >
                            Entity Email id :
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "0px", border: "none" }}
                          >
                            {this.state.entity_data.contact_email_id}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{
                              minWidth: 100,
                              paddingRight: "0px",
                              border: "none",
                            }}
                          >
                            Business Sector :
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "0px", border: "none" }}
                          >
                            {this.state.entity_data.business_sector_id
                              ? this.state.entity_data.business_sector_id
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{
                              minWidth: 100,
                              paddingRight: "0px",
                              border: "none",
                            }}
                          >
                            Entity Address :
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "0px", border: "none" }}
                          >
                            {this.state.entity_data.entity_address}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              minWidth: 180,
                              paddingRight: "0px",
                              border: "none",
                            }}
                          >
                            Mobile Number :
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "0px", border: "none" }}
                          >
                            {this.state.entity_data.contact_number}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6}>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ border: "none" }}>
                            GSTIN :{" "}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {" "}
                            {this.state.entity_data.gst}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ border: "none" }}>
                            PAN :{" "}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {" "}
                            {this.state.entity_data.pan}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ minWidth: 180, border: "none" }}>
                            CIN :{" "}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {" "}
                            {this.state.entity_data.cin}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ border: "none" }}>
                            Constitution :
                          </TableCell>
                          <TableCell style={{ minWidth: 180, border: "none" }}>
                            {this.state.entity_data.constitution_id
                              ? this.state.entity_data.constitution_id
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ minWidth: 180, border: "none" }}>
                            MSME Reference :
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {this.state.entity_data.msme_flag}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ minWidth: 150, border: "none" }}>
                            Date of Incorporation :
                          </TableCell>
                          <TableCell style={{ minWidth: 200, border: "none" }}>
                            {this.dateFormat(
                              this.state.entity_data.date_incorporation
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              "No Data Found"
            )}
          </BoxWrapper>
        </div>

        {/* ---Owner table--- */}
        <BoxWrapper
          boxTitle="Owner"
          enableAddButton={true}
          addButtonLabel={"Add Owner"}
          addButtonHandlerFn={() => this.addOwnerData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.ownerTableHeader()}</tr>
                {this.ownerTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>

        {/* ---ADD OWNER--- */}

        <RightDrawer
          anchor="right"
          open={this.state.addDrawer}
          onClose={this.drawerToggleClose}
          drawerName="Add Owner"
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addOwnerSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Title"
              name="title"
              value={this.state.addOwnerData.title ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="First Name"
              name="first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.first_name ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Middle Name"
              name="middle_name"
              value={this.state.addOwnerData.middle_name ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Last Name"
              name="last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.last_name ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Birth
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="dob"
                value={this.state.addOwnerData.dob ?? ""}
                maxDateForDatepicker={this.state.currentDate}
                onChange={(v) => this.handleDateOwnerChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <InputField
              inputType="text"
              textLabel="Mobile"
              name="mobile"
              requiredField="*"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid mobile number"]}
              value={this.state.addOwnerData.mobile ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="nationality_name"
              SelectLabel="Nationality"
              name="nationality"
              value={this.state.addOwnerData.nationality ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
              choices={[{ id: 1, nationality_name: "Indian" }]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="resident_status"
              SelectLabel="Residential Status"
              name="residential_status"
              value={this.state.addOwnerData.residential_status ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
              choices={residential_status}
            />

            <InputField
              inputType="text"
              textLabel="Designation"
              name="designation"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.designation ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="PAN"
              name="owner_pan"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
              ]}
              errorMsg={["This field is required", "Invalid PAN Number"]}
              value={this.state.addOwnerData.owner_pan ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="aadhaar Number"
              requiredField="*"
              name="aadhaar_number"
              validator={["required", "matchRegexp:^[0-9]{12}$"]}
              errorMsg={["This field is required", "Invalid Aadhar number."]}
              value={this.state.addOwnerData.aadhaar_number ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="DIN"
              name="din"
              validator={["matchRegexp:^[0-9]{8}$"]}
              errorMsg={["Invalid DIN Number"]}
              value={this.state.addOwnerData.din ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="CIN"
              name="cin"
              validator={[
                "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
              ]}
              errorMsg={["Invalid CIN"]}
              value={this.state.addOwnerData.cin ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Company Name"
              name="company_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.company_name ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Name"
              name="father_spouse_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.father_spouse_name ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Landline Number"
              name="telephone"
              validator={["matchRegexp:^[0-9]{10}$"]}
              errorMsg={["Invalid telephone number."]}
              value={this.state.addOwnerData.telephone ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="% Share"
              name="percentage"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addOwnerData.percentage ?? ""}
              textOnchangeFunction={this.handleAddOwnerInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---EDIT OWNER--- */}
        <RightDrawer
          anchor="right"
          open={this.state.openEditDrawer}
          onClose={this.editDrawerClose}
          drawerName={"Edit  Owner  Details"}
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editOwnerSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Title"
              name="title"
              value={this.state.editOwnerData.title ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="First Name"
              name="first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.first_name ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Middle Name"
              name="middle_name"
              value={this.state.editOwnerData.middle_name ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Last Name"
              name="last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.last_name ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Birth
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="dob"
                value={this.state.editOwnerData.dob ?? ""}
                onChange={(v) => this.handleDateEditOwnerChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <InputField
              inputType="text"
              textLabel="Mobile"
              name="mobile"
              requiredField="*"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid Mobile number"]}
              value={this.state.editOwnerData.mobile ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            {/* <InputField
                  inputType="text"
                  textLabel="Nationality"
                  name="nationality"
                  requiredField="*"
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  value={this.state.editOwnerData.nationality ?? ""}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                /> */}
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="nationality"
              SelectLabel="Nationality"
              name="nationality"
              value={this.state.editOwnerData.nationality ?? 1}
              textOnchangeFunction={this.handleEditOwnerInputChange}
              choices={[{ id: 1, nationality: "Indian" }]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="resident_status"
              SelectLabel="Residential Status"
              name="residential_status"
              value={this.state.editOwnerData.residential_status ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
              choices={residential_status}
            />

            <InputField
              inputType="text"
              textLabel="Designation"
              name="designation"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.designation ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="PAN"
              name="owner_pan"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
              ]}
              errorMsg={["This field is required", "Invalid PAN number"]}
              value={this.state.editOwnerData.owner_pan ?? ""}
              inputProps={{ readOnly: true }}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="aadhaar Number"
              requiredField="*"
              name="aadhaar_number"
              validator={["required", "matchRegexp:^[0-9]{12}$"]}
              errorMsg={["This field is required", "Invalid Aadhar number."]}
              value={this.state.editOwnerData.aadhaar_number ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="DIN"
              name="din"
              validator={["matchRegexp:^[0-9]{8}$"]}
              errorMsg={["Invalid DIN Number"]}
              value={this.state.editOwnerData.din ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="CIN"
              name="cin"
              validator={[
                "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
              ]}
              errorMsg={["Invalid CIN"]}
              value={this.state.editOwnerData.cin ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Company Name"
              name="company_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.company_name ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Name"
              name="father_spouse_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.father_spouse_name ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Landline Number"
              name="telephone"
              validator={["matchRegexp:^[0-9]{10}$"]}
              errorMsg={["Invalid telephone number."]}
              value={this.state.editOwnerData.telephone ?? ""}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <InputField
              inputType="text"
              textLabel="% Share"
              name="percentage"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editOwnerData.percentage ?? 0}
              textOnchangeFunction={this.handleEditOwnerInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.editDrawerClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---Show owner--- */}
        <RightDrawer
          anchor="right"
          open={this.state.openShowDrawer}
          onClose={this.showDrawerClose}
          drawerName={"Owner Details"}
        >
          {this.state.showOwnerData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="First Name"
                value={this.state.showOwnerData.first_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Middle Name"
                value={
                  this.state.showOwnerData.middle_name
                    ? this.state.showOwnerData.middle_name
                    : ""
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Last Name"
                value={this.state.showOwnerData.last_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Date Of Birth"
                value={this.dateFormat(this.state.showOwnerData.dob)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Mobile"
                value={this.state.showOwnerData.mobile}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Nationality"
                value={this.state.showOwnerData.nationality_value}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Father/Spouse Name"
                value={this.state.showOwnerData.father_spouse_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Company Name"
                value={this.state.showOwnerData.company_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Designation"
                value={this.state.showOwnerData.designation}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Landline Number"
                value={this.state.showOwnerData.telephone}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="PAN"
                value={this.state.showOwnerData.owner_pan}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="DIN"
                value={this.state.showOwnerData.din}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="CIN"
                value={this.state.showOwnerData.cin}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="% Share"
                value={this.state.showOwnerData.percentage}
              />
            </>
          ) : (
            "No Data Found"
          )}

          <button
            type="button"
            className="button-outlined-secondary"
            onClick={this.showDrawerClose}
          >
            cancel
          </button>
        </RightDrawer>
        {/*---Owner Table End--- */}

        {/* ---promoter table--- */}
        <BoxWrapper
          boxTitle="Promoter"
          enableAddButton={true}
          addButtonLabel={" Add Promoter"}
          addButtonHandlerFn={() => this.addPromoterOpenToggleData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.promoterTableHeader()}</tr>
                {this.promoterTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>

        {/* ---Promoter Add--- */}
        <RightDrawer
          open={this.state.addPromoterOpen}
          onClose={this.drawerPromoterToggleClose}
          drawerName="Add Promoter"
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.addPromoterSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <InputField
              inputType="text"
              textLabel="PAN"
              name="promoter_pan"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
              ]}
              errorMsg={["This field is required", "Invalid PAN Number"]}
              value={this.state.addPromoterData.promoter_pan ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Title"
              name="title"
              value={this.state.addPromoterData.title ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="First Name"
              name="first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.first_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Middle Name"
              name="middle_name"
              value={this.state.addPromoterData.middle_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Last Name"
              name="last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.last_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Email"
              name="email"
              requiredField="*"
              validator={["required", "isEmail"]}
              errorMsg={["This field is required", "Email is not Valid"]}
              value={this.state.addPromoterData.email ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="resident_status"
              SelectLabel="Residential Status"
              name="residential_status"
              value={this.state.addPromoterData.residential_status ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={residential_status}
            />

            <InputField
              inputType="text"
              textLabel="DIN"
              name="din"
              validator={["matchRegexp:^[0-9]{8}$"]}
              errorMsg={["Invalid DIN Number"]}
              value={this.state.addPromoterData.din ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="nationality_name"
              SelectLabel="Nationality"
              name="nationality_id"
              value={this.state.addPromoterData.nationality_id ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={[{ id: 1, nationality_name: "Indian" }]}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Birth
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="dob"
                value={this.state.addPromoterData.dob ?? ""}
                maxDateForDatepicker={this.state.currentDate}
                onChange={(v) => this.handleDatePromoterChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Father/Spouse Title"
              name="father_spouse_title"
              value={this.state.addPromoterData.father_spouse_title ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse First Name"
              name="father_spouse_first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.father_spouse_first_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Middle Name"
              name="father_spouse_middle_name"
              value={this.state.addPromoterData.father_spouse_middle_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Last Name"
              name="father_spouse_last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.father_spouse_last_name ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Role Assigned TREDS"
              name="roles_assigned_treds"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.roles_assigned_treds ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Authorization TREDS
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="date_authorization_treds"
                value={
                  this.state.addPromoterData.date_authorization_treds ?? ""
                }
                onChange={(v) => this.handleDateTREDSPromoterChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="name"
              SelectLabel="Category"
              name="category_id"
              value={this.state.addPromoterData.category_id ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={PromoterCategories}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Women Entrepreneur"
              name="women_enterpreneur"
              value={this.state.addPromoterData.women_enterpreneur ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Yes",
                },
                {
                  id: 2,
                  title: "No",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Designation"
              name="designation"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.designation ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Country"
              name="country_id"
              value={this.state.addPromoterData.country_id ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "India",
                },
              ]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="State"
              name="state_id"
              value={this.state.addPromoterData.state_id ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
              choices={State}
            />

            <InputField
              inputType="text"
              textLabel="City"
              requiredField="*"
              name="city"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.city ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="District"
              name="district"
              value={this.state.addPromoterData.district ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Telephone Number"
              requiredField="*"
              name="telephone_number"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid Telephone Number"]}
              value={this.state.addPromoterData.telephone_number ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Mobile Number"
              requiredField="*"
              name="mobile_number"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid Mobile number."]}
              value={this.state.addPromoterData.mobile_number ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Address Line 1"
              requiredField="*"
              name="address_line1"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.address_line1 ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Address Line2"
              name="address_line2"
              value={this.state.addPromoterData.address_line2 ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Pincode"
              requiredField="*"
              name="pincode"
              validator={["required", "matchRegexp:^[0-9]{6}$"]}
              errorMsg={["This field is required", "Invalid Pincode."]}
              value={this.state.addPromoterData.pincode ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Experience(Years)"
              requiredField="*"
              name="experience_years"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.experience_years ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Experience(Months)"
              requiredField="*"
              name="experience_months"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.experience_months ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Qualification"
              requiredField="*"
              name="qualification"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.qualification ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="aadhaar Number"
              requiredField="*"
              name="aadhaar"
              validator={["required", "matchRegexp:^[0-9]{12}$"]}
              errorMsg={["This field is required", "Invalid Aadhar number."]}
              value={this.state.addPromoterData.aadhaar ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="% Share"
              requiredField="*"
              name="percentage_share"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addPromoterData.percentage_share ?? ""}
              textOnchangeFunction={this.handleAddPromoterInputChange}
            />
            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerPromoterToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---Promoter Edit--- */}
        <RightDrawer
          open={this.state.openEditPromoterDrawer}
          onClose={this.drawerPromoterEditToggleClose}
          drawerName="Edit Promoter Details"
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editPromoterSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <InputField
              inputType="text"
              textLabel="PAN"
              name="promoter_pan"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
              ]}
              errorMsg={["This field is required", "Invalid PAN Number"]}
              value={this.state.editPromoterData.promoter_pan ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              inputProps={{ readOnly: true }}
            />
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Title"
              name="title"
              value={this.state.editPromoterData.title ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="First Name"
              name="first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.first_name ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Middle Name"
              name="middle_name"
              value={this.state.editPromoterData.middle_name ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Last Name"
              name="last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.last_name ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Email"
              name="email"
              requiredField="*"
              validator={["required", "isEmail"]}
              errorMsg={["This field is required", "Email is not Valid"]}
              value={this.state.editPromoterData.email ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="resident_status"
              SelectLabel="Residential Status"
              name="residential_status"
              value={this.state.editPromoterData.residential_status ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={residential_status}
            />

            <InputField
              inputType="text"
              textLabel="DIN"
              name="din"
              validator={["matchRegexp:^[0-9]{8}$"]}
              errorMsg={["Invalid DIN Number"]}
              value={this.state.editPromoterData.din ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="nationality_name"
              SelectLabel="Nationality"
              name="nationality_id"
              value={this.state.editPromoterData.nationality_id ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={[{ id: 1, nationality_name: "Indian" }]}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Birth
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="dob"
                value={this.state.editPromoterData.dob ?? ""}
                onChange={(v) => this.handleDateEditPromoterChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Father/Spouse Title"
              name="father_spouse_title"
              value={this.state.editPromoterData.father_spouse_title ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Mr.",
                },
                {
                  id: 2,
                  title: "Ms.",
                },
                {
                  id: 3,
                  title: "Mrs.",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse First Name"
              name="father_spouse_first_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.father_spouse_first_name ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Middle Name"
              name="father_spouse_middle_name"
              value={
                this.state.editPromoterData.father_spouse_middle_name ?? ""
              }
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Father/Spouse Last Name"
              name="father_spouse_last_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.father_spouse_last_name ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Role Assigned TREDS"
              name="roles_assigned_treds"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.roles_assigned_treds ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <label>
                Date Of Authorization TREDS
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="date_authorization_treds"
                value={
                  this.state.editPromoterData.date_authorization_treds ?? ""
                }
                onChange={(v) => this.handleDateEditTREDSPromoterChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="name"
              SelectLabel="Category"
              name="category_id"
              value={this.state.editPromoterData.category_id ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={PromoterCategories}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Women Entrepreneur"
              name="women_enterpreneur"
              value={this.state.editPromoterData.women_enterpreneur ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "Yes",
                },
                {
                  id: 2,
                  title: "No",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Designation"
              name="designation"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.designation ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Country"
              name="country_id"
              value={this.state.editPromoterData.country_id ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={[
                {
                  id: 1,
                  title: "India",
                },
              ]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="State"
              name="state_id"
              value={this.state.editPromoterData.state_id ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
              choices={State}
            />

            <InputField
              inputType="text"
              textLabel="City"
              requiredField="*"
              name="city"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.city ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="District"
              name="district"
              value={this.state.editPromoterData.district ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Telephone Number"
              requiredField="*"
              name="telephone_number"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid Telephone Number"]}
              value={this.state.editPromoterData.telephone_number ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Mobile Number"
              requiredField="*"
              name="mobile_number"
              validator={["required", "matchRegexp:^[0-9]{10}$"]}
              errorMsg={["This field is required", "Invalid Mobile number."]}
              value={this.state.editPromoterData.mobile_number ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Address Line 1"
              requiredField="*"
              name="address_line1"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.address_line1 ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Address Line2"
              name="address_line2"
              value={this.state.editPromoterData.address_line2 ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Pincode"
              requiredField="*"
              name="pincode"
              validator={["required", "matchRegexp:^[0-9]{6}$"]}
              errorMsg={["This field is required", "Invalid Pincode."]}
              value={this.state.editPromoterData.pincode ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Experience(Years)"
              requiredField="*"
              name="experience_years"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.experience_years ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Experience(Months)"
              requiredField="*"
              name="experience_months"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.experience_months ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Qualification"
              requiredField="*"
              name="qualification"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.qualification ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="aadhaar Number"
              requiredField="*"
              name="aadhaar"
              validator={["required", "matchRegexp:^[0-9]{12}$"]}
              errorMsg={["This field is required", "Invalid Aadhar number."]}
              value={this.state.editPromoterData.aadhaar ?? ""}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />

            <InputField
              inputType="text"
              textLabel="% Share"
              requiredField="*"
              name="percentage_share"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editPromoterData.percentage_share ?? 0}
              textOnchangeFunction={this.handleEditPromoterInputChange}
            />
            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerPromoterEditToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* Show Promoter */}

        <RightDrawer
          open={this.state.openPromoterShowDrawer}
          onClose={this.showPromoterDrawerClose}
          drawerName="Promoter Details"
        >
          {this.state.showPromoterData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="PAN"
                value={this.state.showPromoterData.promoter_pan}
              // defaultValue={this.state.showPromoterData.promoter_pan}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Title"
                value={this.state.showPromoterData.title}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="First Name"
                value={this.state.showPromoterData.first_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Middle Name"
                value={
                  this.state.showPromoterData.middle_name
                    ? this.state.showPromoterData.middle_name
                    : ""
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Last Name"
                value={this.state.showPromoterData.last_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Email"
                value={this.state.showPromoterData.email}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Residential Status"
                value={this.state.showPromoterData.residential_status}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="DIN"
                value={this.state.showPromoterData.din}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Nationality"
                value={this.state.showPromoterData.nationality_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Date Of Birth"
                value={this.dateFormat(this.state.showPromoterData.dob)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Mobile Number"
                value={this.state.showPromoterData.mobile_number}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Father/Spouse Title"
                value={this.state.showPromoterData.father_spouse_title}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Father/Spouse First Name"
                value={this.state.showPromoterData.father_spouse_first_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Father/Spouse Middle Name"
                value={
                  this.state.showPromoterData.father_spouse_middle_name
                    ? this.state.showPromoterData.father_spouse_middle_name
                    : ""
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Father/Spouse Last Name"
                value={this.state.showPromoterData.father_spouse_last_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Role Assigned TREDS"
                value={this.state.showPromoterData.roles_assigned_treds}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Date Authorization TREDS"
                value={this.dateFormat(
                  this.state.showPromoterData.date_authorization_treds
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Category"
                value={this.state.showPromoterData.category_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Women Entrepreneur"
                value={this.state.showPromoterData.women_enterpreneur}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Designation"
                value={this.state.showPromoterData.designation}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Country"
                value={this.state.showPromoterData.country_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="State"
                value={this.state.showPromoterData.state_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="City"
                value={this.state.showPromoterData.city}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="District"
                value={this.state.showPromoterData.district}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Telephone No"
                value={this.state.showPromoterData.telephone_number}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Mobile No"
                value={this.state.showPromoterData.mobile_number}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Address Line1"
                value={this.state.showPromoterData.address_line1}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Address Line2"
                value={this.state.showPromoterData.address_line2}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Pincode"
                value={this.state.showPromoterData.pincode}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Experience(Years)"
                value={this.state.showPromoterData.experience_years}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Experience(Months)"
                value={this.state.showPromoterData.experience_months}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Qualification"
                value={this.state.showPromoterData.qualification}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Aadhaar"
                value={this.state.showPromoterData.aadhaar}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="% Share"
                value={this.state.showPromoterData.percentage_share}
              />
            </>
          ) : (
            "No Data Found"
          )}

          <button
            type="button"
            style={{ marginTop: "20px" }}
            className="button-outlined-secondary"
            onClick={this.showPromoterDrawerClose}
          >
            Close
          </button>
        </RightDrawer>

        {/* Bank Details */}

        {/* ---Bank table--- */}
        <BoxWrapper
          boxTitle="Bank Details"
          enableAddButton={true}
          addButtonLabel={"ADD Bank"}
          addButtonHandlerFn={() => this.addBankOpenToggleData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.bankTableHeader()}</tr>
                {this.bankTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>

        {/* ---Bank Add--- */}
        <RightDrawer
          open={this.state.addBankOpen}
          onClose={this.drawerBankToggleClose}
          drawerName="Add Bank Details"
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.addBankSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <InputField
              inputType="text"
              textLabel="IFSC Code"
              name="ifsc_code"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$",
              ]}
              errorMsg={["This field is required", "IFSC code is not valid"]}
              value={this.state.addBankData.ifsc_code ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Bank Name"
              name="bank_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addBankData.bank_name ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Account Type"
              name="account_type"
              value={this.state.addBankData.account_type ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
              choices={AccountType}
            />

            <InputField
              inputType="text"
              textLabel="Branch Name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              name="branch_name"
              value={this.state.addBankData.branch_name ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Account Number"
              requiredField="*"
              validator={["required", "matchRegexp:^[0-9]{9,18}$"]}
              errorMsg={[
                "This field is required",
                "The bank number should have 9-18 digits",
              ]}
              name="account_number"
              value={this.state.addBankData.account_number ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Designation Transaction Account"
              name="designation_transaction_account"
              value={
                this.state.addBankData.designation_transaction_account ?? ""
              }
              textOnchangeFunction={this.handleAddBankInputChange}
              choices={[
                {
                  id: 1,
                  value: "Yes",
                },
                {
                  id: 2,
                  value: "No",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Branch Address1"
              name="branch_address1"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addBankData.branch_address1 ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Branch Address2"
              name="branch_address2"
              value={this.state.addBankData.branch_address2 ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Pincode"
              requiredField="*"
              name="pincode"
              validator={["required", "matchRegexp:^[0-9]{6}$"]}
              errorMsg={["This field is required", "Invalid Pincode Number"]}
              value={this.state.addBankData.pincode ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="City"
              name="city"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addBankData.city ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="District"
              name="district"
              value={this.state.addBankData.district ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Country"
              name="country_id"
              value={this.state.addBankData.country_id ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
              choices={[
                {
                  id: 1,
                  title: "India",
                },
              ]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="State"
              name="state_id"
              value={this.state.addBankData.state_id ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
              choices={State}
            />

            <InputField
              inputType="text"
              textLabel="Branch Email ID"
              name="branch_email_id"
              requiredField="*"
              validator={["required", "isEmail"]}
              errorMsg={["This field is required", "Email is not Valid"]}
              value={this.state.addBankData.branch_email_id ?? ""}
              textOnchangeFunction={this.handleAddBankInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerBankToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---Bank Edit--- */}
        <RightDrawer
          open={this.state.openEditBankDrawer}
          onClose={this.drawerBankEditToggleClose}
          drawerName="Edit Bank Details"
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editBankSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <InputField
              inputProps={{ readOnly: true }}
              inputType="text"
              textLabel="IFSC Code"
              name="ifsc_code"
              requiredField="*"
              validator={[
                "required",
                "matchRegexp:^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$",
              ]}
              errorMsg={["This field is required", "IFSC Code is not valid"]}
              value={this.state.editBankData.ifsc_code ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Bank Name"
              name="bank_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editBankData.bank_name ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Account Type"
              name="account_type"
              value={this.state.editBankData.account_type ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
              choices={AccountType}
            />

            <InputField
              inputType="text"
              textLabel="Branch Name"
              name="branch_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editBankData.branch_name ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Account Number"
              name="account_number"
              validator={["required", "matchRegexp:^[0-9]{9,18}$"]}
              errorMsg={[
                "This field is required",
                "The bank number should have 9-18 digits",
              ]}
              inputProps={{ readOnly: true }}
              value={this.state.editBankData.account_number ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Designation Transaction Account"
              name="designation_transaction_account"
              value={
                this.state.editBankData.designation_transaction_account ?? ""
              }
              textOnchangeFunction={this.handleEditBankInputChange}
              choices={[
                {
                  id: 1,
                  value: "Yes",
                },
                {
                  id: 2,
                  value: "No",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Branch Address1"
              name="branch_address1"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editBankData.branch_address1 ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Branch Address2"
              name="branch_address2"
              value={this.state.editBankData.branch_address2 ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Pincode"
              requiredField="*"
              name="pincode"
              validator={["required", "matchRegexp:^[0-9]{6}$"]}
              errorMsg={["This field is required", "Invalid Pincode Number"]}
              value={this.state.editBankData.pincode ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="City"
              name="city"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editBankData.city ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <InputField
              inputType="text"
              textLabel="District"
              name="district"
              value={this.state.editBankData.district ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Country"
              name="country_id"
              value={this.state.editBankData.country_id ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
              choices={[
                {
                  id: 1,
                  title: "India",
                },
              ]}
            />

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="State"
              name="state_id"
              value={this.state.editBankData.state_id ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
              choices={State}
            />

            <InputField
              inputType="text"
              textLabel="Branch Email ID"
              name="branch_email_id"
              requiredField="*"
              validator={["required", "isEmail"]}
              errorMsg={["This field is required", "Email is not Valid"]}
              value={this.state.editBankData.branch_email_id ?? ""}
              textOnchangeFunction={this.handleEditBankInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerBankEditToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---Show Bank--- */}
        <RightDrawer
          open={this.state.openBankShowDrawer}
          onClose={this.showBankDrawerClose}
          drawerName="Bank Details"
        >
          {this.state.showBankData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="IFSC Code"
                value={this.state.showBankData.ifsc_code}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Bank Name"
                value={this.state.showBankData.bank_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Account Type"
                value={this.state.showBankData.account_type}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Branch Name"
                value={this.state.showBankData.branch_name}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Account Number"
                value={this.state.showBankData.account_number}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Designation Transaction Account"
                value={this.state.showBankData.designation_transaction_account}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Branch Address1"
                value={this.state.showBankData.branch_address1}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Branch Address2"
                value={this.state.showBankData.branch_address2}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Pincode"
                value={this.state.showBankData.pincode}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="City"
                value={this.state.showBankData.city}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="District"
                value={this.state.showBankData.district}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="State"
                value={this.state.showBankData.state_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Country"
                value={this.state.showBankData.country_id}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Branch Email Id"
                value={this.state.showBankData.branch_email_id}
              />
            </>
          ) : (
            "No Data Found"
          )}
          <button
            style={{ marginTop: "20px" }}
            type="button"
            className="button-outlined-secondary"
            onClick={this.showBankDrawerClose}
          >
            Close
          </button>
        </RightDrawer>

        {/* ---Balance Sheet table--- */}
        <BoxWrapper
          boxTitle="Balancesheet Details"
          enableAddButton={true}
          addButtonLabel={"ADD Balancesheet"}
          addButtonHandlerFn={() => this.addBalanceSheetOpenToggleData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.balancesheetTableHeader()}</tr>
                {this.balancesheetTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>


        {/* ---Balance Sheet Add--- */}
        <RightDrawer
          open={this.state.addBalanceSheetOpen}
          onClose={this.drawerBalanceSheetToggleClose}
          drawerName="Add Balance Sheet"
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addBalanceSheetSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                As of Date
                <span style={{ color: "red" }}>*</span>:
              </label>
              <DatePicker
                name="as_of_date"
                value={this.state.addBalanceSheetData.as_of_date ?? ""}
                onChange={(v) => this.handleDateBalanceSheetChange(v)}
                validators={["required"]}
                errorMessages={["Date is required"]}
              />
            </div>

            <InputField
              inputType="text"
              textLabel="Net Worth"
              name="net_worth"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.net_worth
                  ? this.numberFormat(this.state.addBalanceSheetData.net_worth)
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              textLabel="Long Term Borrowings"
              name="long_term_borrowings"
              value={
                this.state.addBalanceSheetData.long_term_borrowings
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.long_term_borrowings
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Short Term Borrowings"
              name="short_term_borrowings"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.short_term_borrowings
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.short_term_borrowings
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              requiredField="*"
              textLabel="Fixed Assets"
              name="fixed_assets"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.fixed_assets
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.fixed_assets
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Non-Current Investments"
              name="non_current_investments"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.non_current_investments
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.non_current_investments
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              textLabel="Current Investments"
              name="current_investments"
              value={
                this.state.addBalanceSheetData.current_investments
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.current_investments
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Trade Receivables"
              name="trade_receivables"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.trade_receivables
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.trade_receivables
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Inventory"
              name="inventory"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.inventory
                  ? this.numberFormat(this.state.addBalanceSheetData.inventory)
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Current Liablilities"
              name="current_liabilities"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.current_liabilities
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.current_liabilities
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Overdue Government"
              name="overdue_govt"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.overdue_govt
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.overdue_govt
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Other Assets"
              name="other_assets"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.other_assets
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.other_assets
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Other Liablities"
              name="other_liabilities"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addBalanceSheetData.other_liabilities
                  ? this.numberFormat(
                    this.state.addBalanceSheetData.other_liabilities
                  )
                  : ""
              }
              textOnchangeFunction={this.handleAddBalanceSheetInputChange}
            />

            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerBalanceSheetToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* ---Balance Sheet Edit--- */}
        <RightDrawer
          open={this.state.openEditBalanceSheetDrawer}
          onClose={this.drawerBalanceSheetEditToggleClose}
          drawerName="Edit Balance Sheet"
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editBalanceSheetSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <TextField
              className={classes.showTextField}
              requiredField="*"
              inputProps={{ readOnly: true }}
              fullWidth
              label="As of Date"
              defaultValue={this.dateFormat(
                this.state.editBalanceSheetData.as_of_date
              )}
            />
            <InputField
              inputType="text"
              textLabel="Net Worth"
              name="net_worth"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.net_worth
                  ? this.numberFormat(this.state.editBalanceSheetData.net_worth)
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Long Term Borrowings"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              name="long_term_borrowings"
              value={
                this.state.editBalanceSheetData.long_term_borrowings
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.long_term_borrowings
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Short Term Borrowings"
              name="short_term_borrowings"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.short_term_borrowings
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.short_term_borrowings
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Fixed Assets"
              name="fixed_assets"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.fixed_assets
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.fixed_assets
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Non-Current Investments"
              name="non_current_investments"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.non_current_investments
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.non_current_investments
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Current Investments"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              name="current_investments"
              value={
                this.state.editBalanceSheetData.current_investments
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.current_investments
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Trade Receivables"
              name="trade_receivables"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.trade_receivables
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.trade_receivables
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Inventory"
              name="inventory"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.inventory
                  ? this.numberFormat(this.state.editBalanceSheetData.inventory)
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Current Liablilities"
              name="current_liabilities"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.current_liabilities
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.current_liabilities
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Overdue Government"
              name="overdue_govt"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.overdue_govt
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.overdue_govt
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Other Assets"
              name="other_assets"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.other_assets
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.other_assets
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Other Liablities"
              name="other_liabilities"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editBalanceSheetData.other_liabilities
                  ? this.numberFormat(
                    this.state.editBalanceSheetData.other_liabilities
                  )
                  : ""
              }
              textOnchangeFunction={this.handleEditBalanceSheetInputChange}
            />
            <div style={{ marginTop: "20px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerBalanceSheetEditToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* Show Balancesheet */}
        <RightDrawer
          open={this.state.openBalanceSheetShowDrawer}
          onClose={this.showBalanceSheetDrawerClose}
          drawerName="Balance Sheet Details"
        >
          {this.state.showBalanceSheetData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="As of Date"
                value={this.dateFormat(
                  this.state.showBalanceSheetData.as_of_date
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Net Worth"
                value={this.state.showBalanceSheetData.net_worth}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Long Term Borrowings"
                value={this.state.showBalanceSheetData.long_term_borrowings}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Short Term Borrowings"
                value={this.state.showBalanceSheetData.short_term_borrowings}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Fixed Assets"
                value={this.state.showBalanceSheetData.fixed_assets}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Non-Current Investments"
                value={this.state.showBalanceSheetData.non_current_investments}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Current Investments"
                value={this.state.showBalanceSheetData.current_investments}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Trade Receivables"
                value={this.state.showBalanceSheetData.trade_receivables}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Inventory"
                value={this.state.showBalanceSheetData.inventory}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Current Liabilities"
                value={this.state.showBalanceSheetData.current_liabilities}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Overdue Government"
                value={this.state.showBalanceSheetData.overdue_govt}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Other Assets"
                value={this.state.showBalanceSheetData.other_assets}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Other Liablities"
                value={this.state.showBalanceSheetData.other_liabilities}
              />
            </>
          ) : (
            "No Data Found"
          )}
          <button
            type="button"
            className="button-outlined-secondary mt-20"
            onClick={this.showBalanceSheetDrawerClose}
          >
            Close
          </button>
        </RightDrawer>

        {/* ---Finance --- */}
        <BoxWrapper
          boxTitle="Financial Details"
          enableAddButton={true}
          addButtonLabel={"ADD Finance"}
          addButtonHandlerFn={() => this.addFinanceOpenToggleData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.financeTableHeader()}</tr>
                {this.financeTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>

        {/* --- Add--- */}
        <RightDrawer
          open={this.state.addFinanceOpen}
          onClose={this.drawerFinanceToggleClose}
          drawerName="Add Finance"
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addFinanceSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                Fiscal Year
                <span style={{ color: "red" }}>*</span>:
              </label>
              <ul style={{ listStyle: "none", marginTop: "0px" }}>
                <li style={{ display: "inline-block", width: "50px" }}>
                  <TextValidator
                    value={
                      this.state.addFinanceData.fiscal_year
                        ? `${this.state.addFinanceData.fiscal_year - 1} - 
                              `
                        : ""
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    placeholder="YYYY-"
                  />
                </li>
                <li style={{ display: "inline-block", width: "160px" }}>
                  <DatePickerFiscalYear
                    name="fiscal_year"
                    value={this.state.addFinanceData.fiscal_year ?? ""}
                    onChange={(v) => this.handleDateFinanceChange(v)}
                    validators={["required"]}
                    errorMessages={["Date is required"]}
                    views={["year"]}
                    placeholder="YYYY"
                    format="yyyy"
                    InputVariantForDate="inline"
                  />
                </li>
              </ul>
            </div>

            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Projected/Actual"
              name="projection_actual_flag"
              value={this.state.addFinanceData.projection_actual_flag ?? ""}
              textOnchangeFunction={this.handleAddFinanceInputChange}
              choices={[
                {
                  id: "Projected",
                  title: "Projected",
                },
                {
                  id: "Actual",
                  title: "Actual",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Turnover"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              name="turnover"
              value={
                this.state.addFinanceData.turnover
                  ? this.numberFormat(this.state.addFinanceData.turnover)
                  : ""
              }
              textOnchangeFunction={this.handleAddFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Gross Profit"
              name="gross_profit"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addFinanceData.gross_profit
                  ? this.numberFormat(this.state.addFinanceData.gross_profit)
                  : ""
              }
              textOnchangeFunction={this.handleAddFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Net Profit"
              requiredField="*"
              name="net_profit"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addFinanceData.net_profit
                  ? this.numberFormat(this.state.addFinanceData.net_profit)
                  : ""
              }
              textOnchangeFunction={this.handleAddFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Total Borrowings"
              name="total_borrowing"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.addFinanceData.total_borrowing
                  ? this.numberFormat(this.state.addFinanceData.total_borrowing)
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleAddFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Net Worth"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              name="net_worth"
              value={
                this.state.addFinanceData.net_worth
                  ? this.numberFormat(this.state.addFinanceData.net_worth)
                  : ""
              }
              textOnchangeFunction={this.handleAddFinanceInputChange}
            />

            <div className="mt-20">
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerFinanceToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        <RightDrawer
          drawerName="Edit Finance"
          open={this.state.openEditFinanceDrawer}
          onClose={this.drawerFinanceEditToggleClose}
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editFinanceSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Fiscal Year"
              defaultValue={`${this.state.editFinanceData.fiscal_year - 1}-${this.state.editFinanceData.fiscal_year
                }`}
            />

            <SelectField
              choice_id="id"
              inputProps={{ readOnly: true }}
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="title"
              SelectLabel="Projection/Actual"
              name="projection_actual_flag"
              value={this.state.editFinanceData.projection_actual_flag ?? ""}
              textOnchangeFunction={this.handleEditFinanceInputChange}
              choices={[
                {
                  id: "Projected",
                  title: "Projected",
                },
                {
                  id: "Actual",
                  title: "Actual",
                },
              ]}
            />

            <InputField
              inputType="text"
              textLabel="Turnover"
              name="turnover"
              requiredField="*"
              value={
                this.state.editFinanceData.turnover
                  ? this.numberFormat(this.state.editFinanceData.turnover)
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleEditFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Gross Profit"
              name="gross_profit"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editFinanceData.gross_profit
                  ? this.numberFormat(this.state.editFinanceData.gross_profit)
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleEditFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Net Profit"
              requiredField="*"
              name="net_profit"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editFinanceData.net_profit
                  ? this.numberFormat(this.state.editFinanceData.net_profit)
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleEditFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Total Borrowings"
              name="total_borrowing"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={
                this.state.editFinanceData.total_borrowing
                  ? this.numberFormat(
                    this.state.editFinanceData.total_borrowing
                  )
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleEditFinanceInputChange}
            />

            <InputField
              inputType="text"
              textLabel="Net Worth"
              requiredField="*"
              name="net_worth"
              value={
                this.state.editFinanceData.net_worth
                  ? this.numberFormat(this.state.editFinanceData.net_worth)
                  : this.numberFormat(0)
              }
              textOnchangeFunction={this.handleEditFinanceInputChange}
            />

            <div className="mt-20">
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerFinanceEditToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* Show  */}
        <RightDrawer
          drawerName="Finance Details"
          open={this.state.openFinanceShowDrawer}
          onClose={this.showFinanceDrawerClose}
        >
          {this.state.showFinanceData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Fiscal Year"
                value={`${this.state.showFinanceData.fiscal_year - 1}-${this.state.showFinanceData.fiscal_year
                  }`}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Projection/Actual"
                value={this.state.showFinanceData.projection_actual_flag}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Turnover"
                value={this.state.showFinanceData.turnover}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Gross Profit"
                value={this.state.showFinanceData.gross_profit}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Net Profit"
                value={this.state.showFinanceData.net_profit}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Total Borrowings"
                value={this.state.showFinanceData.total_borrowing}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Net Worth"
                value={this.state.showFinanceData.net_worth}
              />
            </>
          ) : (
            "No Data Found"
          )}
          <button
            type="button"
            className="button-outlined-secondary mt-20"
            onClick={this.showFinanceDrawerClose}
          >
            close
          </button>
        </RightDrawer>

        {/* ---Document Details--- */}
        <BoxWrapper
          boxTitle="Document Details"
          enableAddButton={true}
          addButtonLabel={"ADD Document"}
          addButtonHandlerFn={() => this.addFilesOpenToggleData()}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.filesTableHeader()}</tr>
                {this.filesTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>

        {/* ---Files Add--- */}
        <RightDrawer
          drawerName="Add Document"
          open={this.state.addFilesOpen}
          onClose={this.drawerFilesToggleClose}
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.addFilesSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ color: "red", width: "300px" }}>
              {this.state.addFilesData.document_type === "COMPANY PROFILE" &&
                "Please ensure to upload Balance Sheet, Profit & Loss A/C, Cash Flow Statement documents for last 3 years. "}
              Please split the document, if Document size is more than 3 MB.
            </div>
            <InputField
              inputType="text"
              textLabel="Document Name"
              name="document_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.addFilesData.document_name ?? ""}
              textOnchangeFunction={this.handleAddFilesInputChange}
            />
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Document Type"
              name="document_type"
              value={this.state.addFilesData.document_type ?? ""}
              textOnchangeFunction={this.handleAddFilesInputChange}
              choices={[
                {
                  id: "PAN",
                  value: "PAN",
                },
                {
                  id: "GSTIN",
                  value: "GSTIN",
                },
                {
                  id: "COMPANY PROFILE",
                  value: "COMPANY PROFILE",
                },
                {
                  id: "OTHERS",
                  value: "OTHERS",
                },
              ]}
            />
            <div>
              {this.state.addFilesData.document && (
                <div style={{ marginTop: "5px", textAlign: "center" }}>
                  <img
                    alt="doc image"
                    style={{ width: "230px", height: "200px" }}
                    src={this.state.addFilesData.document ?? ""}
                  />
                </div>
              )}
              <InputField
                inputType="file"
                textLabel="Document"
                name="document"
                requiredField="*"
                textOnchangeFunction={this.handleAddFilesInputChange}
              />
              {this.state.invalidImage && (
                <div style={{ color: "red" }}>{this.state.invalidImage}</div>
              )}
            </div>

            <div className="mt-20">
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerFilesToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        <RightDrawer
          drawerName="Edit Document"
          open={this.state.openEditFilesDrawer}
          onClose={this.drawerFilesEditToggleClose}
        >
          {/* date_incorporation, msme */}
          <ValidatorForm
            ref="form"
            onSubmit={this.editFilesSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <InputField
              inputType="text"
              textLabel="Document Name"
              name="document_name"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              value={this.state.editFilesData.document_name ?? ""}
              textOnchangeFunction={this.handleEditFilesInputChange}
            />
            <SelectField
              choice_id="id"
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
              choice_name="value"
              SelectLabel="Document Type"
              name="document_type"
              value={this.state.editFilesData.document_type ?? ""}
              textOnchangeFunction={this.handleEditFilesInputChange}
              choices={[
                {
                  id: "PAN",
                  value: "PAN",
                },
                {
                  id: "GSTIN",
                  value: "GSTIN",
                },
                {
                  id: "COMPANY PROFILE",
                  value: "COMPANY PROFILE",
                },
                {
                  id: "OTHERS",
                  value: "OTHERS",
                },
              ]}
            />
            <div>
              {this.state.editFilesData.document && (
                <div style={{ marginTop: "5px", textAlign: "center" }}>
                  <img
                    alt="doc image"
                    style={{ width: "230px", height: "200px" }}
                    src={this.state.editFilesData.document ?? ""}
                  />
                </div>
              )}

              <InputField
                inputType="file"
                textLabel="Document"
                name="document"
                requiredField="*"
                textOnchangeFunction={this.handleEditFilesInputChange}
              />
              {this.state.invalidImage && (
                <div style={{ color: "red" }}>{this.state.invalidImage}</div>
              )}
            </div>

            <div className="mt-20">
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.drawerFilesEditToggleClose}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* Show Files */}
        <RightDrawer
          drawerName="Document Details"
          open={this.state.openFilesShowDrawer}
          onClose={this.showFilesDrawerClose}
        >
          {this.state.showFilesData ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Document Type"
                value={this.state.showFilesData.document_type}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Document Name"
                value={this.state.showFilesData.document_name}
              />

              <img
                alt="Loading..."
                style={{ width: "230px", height: "200px" }}
                src={this.state.showFilesData.document ?? ""}
              />
            </>
          ) : (
            "No Data Found"
          )}
          <br></br>
          <button
            type="button"
            className="button-outlined-secondary mt-20"
            onClick={this.showFilesDrawerClose}
          >
            close
          </button>
        </RightDrawer>


        {/* {   GST  Details} */}



        <BoxWrapper
          boxTitle="GST Auto Download"
          enableAddButton={true}
          addButtonLabel={"ADD GST"}
          addButtonHandlerFn={() => this.addGSTOpenToggleData()}
        >

          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.GstTableHeader()}</tr>
                {/* {this.GstTableData()}  */}

              </tbody>
              <div style={{
                color: '#ff0000',
                paddingLeft: '400px',
                marginLeft: '600px',
                maxWidth: '300px',
                maxHeight: '500px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',


              }}>{responseMessage}</div>
              <div style={{
                color: '#ff0000',
                paddingLeft: '400px',
                marginLeft: '600px',
                maxWidth: '300px',
                maxHeight: '500px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>{responseOTP}</div>
            </table>
          </div>


        </BoxWrapper>

        <BoxWrapper
          boxTitle="26AS Auto Download"
          enableAddButton={true}
          addButtonLabel={"ADD 26AS"}
          addButtonHandlerFn={() => this.add26ASOpenToggleData()}
        >

          {this.form26AsTableHeader()}

        </BoxWrapper>


        <BoxWrapper
          boxTitle="Related Entities"
          enableAddButton={(localStorage.getItem('entity_type') === "Group" && this.state.entityOptions.length > 0) && true}
          addButtonLabel='Add Entity'
          addButtonHandlerFn={() => this.addBranchOpenToggleData()}
        >

          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>
                  <th>Entity Name</th>
                  <th>PAN</th>
                  <th>Entity Type</th>
                  <th>Main/Branch Name</th>
                  <th>Entity Address</th>
                </tr>
              </thead>
              <tbody>
                {this.state.entities && this.state.entities.map((entity) => (
                  <tr key={entity.business_entity_id}>
                    <td>{entity.entity_name}</td>
                    <td>{entity.pan}</td>
                    <td>{entity.entity_type}</td>
                    <td>{entity.branch_name}</td>
                    <td>{entity.entity_address}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </BoxWrapper>



        <RightDrawer
          drawerName="Add Entity"
          open={this.state.addBranchOpne}
          onClose={this.drawerBranchToggleClose}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addBranchSubmitHandler}
            onError={(errors) => console.log(errors)}

          >
            <div>
              <InputField
                inputType="text"
                textLabel="PAN"
                name="document_name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.entity_data.pan}
              />

              <Grid style={{ marginTop: '2%' }}>

                <label variant="standard">Select Entities <span style={{ color: 'red' }}>*</span>:</label>

                <Select
                  style={{ width: '100%', height: '40px' }}
                  multiple
                  value={this.state.mainEntities}
                  onChange={this.handleEntityChange}
                  renderValue={(selected) => {
                    const displayedEntities = selected
                      .map((value) => this.getEntityName(value))
                      .join(', ');

                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {displayedEntities}
                      </div>
                    );
                  }}
                >
                  {this.state.entityOptions.length > 0 ? (
                    this.state.entityOptions.map((entity) => (
                      <MenuItem key={entity.id} value={entity.id}>
                        <Checkbox checked={this.state.mainEntities.indexOf(entity.id) > -1} />
                        <ListItemText primary={entity.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      <Typography variant="body2" style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                        No options available
                      </Typography>
                    </MenuItem>
                  )}
                </Select>



              </Grid>

            </div>
            <div className="mt-20" style={{ display: "flex", justifyContent: "flex-start" }}>
              <div type="button" className="button-outlined-secondary" onClick={this.addBranchSubmitHandler}>Save</div>
              <div type="button" className="button-outlined-secondary" onClick={this.drawerBranchToggleClose}>Cancel</div>
            </div>
          </ValidatorForm>
        </RightDrawer>


        <RightDrawer
          drawerName="Add GST"
          open={this.state.addGSTOpen}
          onClose={this.drawerGSTToggleClose}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addGSTSubmitHandler}
            onError={(errors) => console.log(errors)}

          >
            <div>
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="document_name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addAutogstSheetData.org_gstin ?? ""}


              // textOnchangeFunction={(event) => this.handleAddGSTAuto(event, 'org_gstin')}

              />
              <InputField
                inputType="text"
                textLabel="UserName"
                name="document_name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addAutogstSheetData.username ?? ""}
                // textOnchangeFunction={(event) => this.handleAddGSTAuto(event, 'username')}
                textOnchangeFunction={(event) =>
                  this.setState({
                    addAutogstSheetData: { ...this.state.addAutogstSheetData, username: event.target.value },
                    usernameError: '',
                  })
                }

              />
              <div style={{ color: 'red' }}>
                {this.state.usernameError}
              </div>
              <InputField
                inputType="text"
                textLabel="Entity ID"
                name="document_name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addAutogstSheetData.entity_id ?? ""}

              // textOnchangeFunction={(event) => this.handleAddGSTAuto(event, 'entity_id')}
              />
            </div>
            <div className="mt-20" style={{ display: "flex", justifyContent: "flex-start" }}>
              <div type="button" className="button-outlined-secondary" onClick={this.addGSTSubmitHandler}>Save</div>
              <div type="button" className="button-outlined-secondary" onClick={this.drawerGSTToggleClose}>Cancel</div>
            </div>
          </ValidatorForm>
        </RightDrawer>




        <RightDrawer
          drawerName="Add 26AS"
          open={this.state.add26ASOpen}
          onClose={this.drawer26ASToggleClose}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.add26ASSubmitHandler}
            onError={(errors) => console.log(errors)}

          >
            <div>
              <SelectField
                choice_id="id"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="value"
                SelectLabel="SOURCE"
                name="source"
                value={this.state.addAuto26asSheetData.source ?? ""}
                textOnchangeFunction={(event) => this.handleAdd26ASAuto(event, 'source')}
                choices={[
                  {
                    id: "itr",
                    value: "ITR",
                  },
                  {
                    id: "traces",
                    value: "TRACES",
                  }
                ]}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                value={this.state.addAuto26asSheetData.pan ?? ""}
                requiredField="*"
                validator={[
                  "required",
                  "matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$",
                ]}
                errorMsg={["This field is required", "Invalid PAN Number"]}

                textOnchangeFunction={(event) => this.handleAdd26ASAuto(event, 'pan')}

              />
              <InputField
                inputType="text"
                textLabel="Password"
                name="password"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addAuto26asSheetData.password ?? ""}
                textOnchangeFunction={(event) => this.handleAdd26ASAuto(event, 'password')}
              />
            </div>
            <div className="mt-20" style={{ display: "flex", justifyContent: "flex-start" }}>
              <div type="button" className="button-outlined-secondary" onClick={this.add26ASSubmitHandler}>Save</div>
              <div type="button" className="button-outlined-secondary" onClick={this.drawer26ASToggleClose}>Cancel</div>
            </div>
          </ValidatorForm>
        </RightDrawer>










        {/* connection details container */}
        {localStorage.getItem("role") === "ADMIN" ? (
          <>
            <ConnectionDetailsComp />
          </>
        ) : (
          ""
        )}

        <MailConnectionDetails />

        {localStorage.getItem("role") === "ADMIN" && (
          <BoxWrapper boxTitle="URL to upload tally backup">
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <label>Paste The URL:</label>
                <input
                  type="text"
                  className={classes.InputField}
                  value={this.state.urlTextField}
                  onChange={(e) =>
                    this.setState({
                      urlTextField: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <button
                  className="button-outlined-primary"
                  onClick={this.sendUrlInApi}
                >
                  Submit
                </button>
              </Grid>
            </Grid>
          </BoxWrapper>
        )}

        {/* ageing and due bucket containes */}
        {localStorage.getItem("showReport") === "true" && (
          <>
            <AgeingBucket />
            <DueBucket />
          </>
        )}

        {this.state.sitemap &&
          this.state.sitemap["ARC RECONCILLIATION"] &&
          this.state.sitemap["ARC RECONCILLIATION"].PURCHASE &&
          this.state.sitemap["ARC RECONCILLIATION"].PURCHASE.includes(
            "TDS PAYABLE RECONCILLATION"
          ) && (
            <>
              {/* .........Tds Payables...... */}
              <div
                style={{ borderBottom: "2px solid #ddd", marginBottom: "2px" }}
              >
                <h4
                  style={{
                    fontWight: "800",
                    textTransform: "uppercase",
                    marginTop: "20px",
                  }}
                >
                  TDS
                </h4>
              </div>

              <TdsExemption />

              <Tdsltds />

              <MsmeInterest />

            </>
          )}
      </>
    );
  }
}

export default BusinessEntityDetails;











import React, { useState, useEffect, useRef } from "react";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Grid from "@material-ui/core/Grid";
import { Button, Typography, Paper } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import MonthDatePicker from "../../../../components/ui/monthWiseDatePicker";
import axios from "../../../../axios-order";
import CircularProgress from "@mui/material/CircularProgress";
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { toast } from "react-toastify";
import SelectField from "../../../../components/ui/basicSelectInput";





export default function RCMReport() {
    const [businessEntityId, setBusinessEntityId] = useState(localStorage.getItem("business_entity_id"))
    const [orgGstin, setOrgGstin] = useState(localStorage.getItem('gst'))
    const [loading, setLoading] = useState(false)
    const [handleFromDate, setHandleFromDate] = useState("");
    const [handleToDate, setHandleToDate] = useState("");
    const [handleFromDateToDisplay, setHandleFromDateToDisplay] = useState(null);
    const [handleToDateToDisplay, setHandleToDateToDisplay] = useState(null);
    const [reportData, setReportData] = useState([])
    const [relatedEntitesData, setRelatedEntitesData] = useState([])
    




    const functionToHandleFromDate = (v) => {
        const pad = "00";
        const yy = v.getFullYear().toString();
        const mm = (v.getMonth() + 1).toString();

        setHandleFromDate(`${(pad + mm).slice(-2)}${yy}`);
        setHandleFromDateToDisplay(v);
    };

    const numberFormat = (value) => {

        if (value === "(Blank)" || value === null) {
            return "₹ 0";
        }

        return new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            maximumSignificantDigits: 10,
        }).format(value);
    };

    useEffect(() => {
        getRelatedEntitiesData()
    }, [])

    //..................function To handle To Date...........
    const functionToHandleToDate = (v) => {
        const pad = "00";
        const year = v.getFullYear().toString();
        const month = (v.getMonth() + 1).toString();

        var lastDay = new Date(v.getFullYear(), v.getMonth() + 1, 0);
        const lastDateOfMonth = lastDay.getDate().toString();
        setHandleToDate(`${(pad + month).slice(-2)}${year}`);
        setHandleToDateToDisplay(v);
    };

    const getRelatedEntitiesData = () => {
        axios.get(`/multimonth_get_api/${businessEntityId}`)
            .then((res) => {
                const entityChoices = res?.data?.map((entity) => ({
                    value: entity.gstin,
                    label: entity.entity_name,
                    business_entity_id : entity.business_entity_id
                }));
                setRelatedEntitesData(entityChoices);
                console.log("entityChoices.....................",entityChoices)
                if (entityChoices.length > 0) {
                    const [entity] = entityChoices;  // Destructure the first item
                    setBusinessEntityId(entity.business_entity_id);
                    setOrgGstin(entity.value);
                  }
                
                

            }).catch((err) => {

            })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        console.log("submited");

        const body = {
            "from_month": handleFromDate,
            "to_month": handleToDate,
            "org_gstin": orgGstin,
            "org_code": businessEntityId
        };

        axios.post('/rcm_sumary_report', body)
            .then((res) => {
                console.log('API Response:', res?.data);
                if (res?.data?.status) {
                    const data = res?.data?.summary_of_rcm;
                    console.log("Data from API:", res?.data?.message); // Log data before setting state
                    setReportData(data); // Set the data to the state
                } else {
                    toast.error(res?.data?.message, {
                        autoClose: 3000,
                        progress: 0,
                    });
                }
            })
            .catch((err) => {
                console.error('Error fetching data:', err);
                let errorMessage = "An error occurred while fetching data.";
                if (err.response) {
                    if (err.response.status === 404) {
                        errorMessage = err.response?.data?.message || "Requested resource not found (404).";
                        setReportData('');
                    } else if (err.response.data?.message) {
                        errorMessage = err.response.data.message;
                    }
                } else if (err.request) {
                    errorMessage = "No response received from the server.";
                }

                toast.error(errorMessage, {
                    autoClose: 3000,
                    progress: 0,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };


    const handleExportExcel = () => {
        const payLoadData = {
            "from_month": handleFromDate,
            "to_month": handleToDate,
            "org_gstin": orgGstin,
            "org_code": businessEntityId
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `Rcm Report -.xlsx`;

        axios.post('/rcm_sumary_report_excel', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);
            }).catch((err) => {

            })
    }

    const onSelectEntity = (gstin) =>{
        const item = relatedEntitesData.find((item)=>item.value === gstin)
        setOrgGstin(gstin)
        setBusinessEntityId(item.business_entity_id)
    }





    return (
        <Grid container spacing={2}>

            <Grid
                item
                xs={12}
                style={{
                    display: "flex",
                    alignItems: "center", // Align items vertically
                    justifyContent: "space-between", // Push button left & text center
                }}
                mb={8}
            >


                {/* Typography Centered */}
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: "center",
                        letterSpacing: "0.04rem",
                        fontFamily: `'Montserrat', sans-serif`,
                        textTransform: "uppercase",
                        flex: 1, // Allow it to take up available space
                        display: "flex",
                        justifyContent: "center", // Center text inside Typography
                    }}
                >
                    <span style={{ fontWeight: "bold", color: "#1D3557" }}>RCM</span>
                    <span style={{ fontWeight: "normal", color: "#212121" }}> Report</span>
                </Typography>
            </Grid>

            <Grid item xs={12} container spacing={1} justifyContent="space-around" alignItems="center" mb={6} mt={3}>

                <ValidatorForm onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>


                    <Grid item sm={3}>
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                From Period
                            </Typography>
                            <MonthDatePicker
                                placeholder="Month & Year"
                                errorMessages={["This field is required"]}
                                validators={["required"]}
                                value={handleFromDateToDisplay}
                                onChange={(v) => functionToHandleFromDate(v)}
                            />
                        </div>
                    </Grid>


                    <Grid item sm={3}>
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                To Period
                            </Typography>
                            <MonthDatePicker
                                minDateForDatepicker={handleFromDateToDisplay}
                                placeholder="Month & Year"
                                // minDate={handleFromDateToDisplay}
                                errorMessages={["This field is required"]}
                                validators={["required"]}
                                value={handleToDateToDisplay}
                                onChange={(v) => functionToHandleToDate(v)}
                            />
                        </div>
                    </Grid>

                    {localStorage.getItem("entity_type") === "Group" && (
                        <Grid item xs={3} sm={3} md={3} style={{ marginTop: '0.1%' }}>
                            <div>
                            <Typography variant="subtitle1" gutterBottom>
                                Select Entity
                            </Typography>
                            <SelectField
                                // SelectLabel="Main Branch"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_id="value"
                                choice_name="label"
                                choices={relatedEntitesData}
                                name="main_branch"
                                value={orgGstin || ""}
                                textOnchangeFunction={(e) =>
                                    onSelectEntity(e.target.value)
                                }
                            />
                            </div>
                            
                        </Grid>
                    )}

                    <Grid item xs={2} sm={2} md={2} style={{ marginTop: '2.6%' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            endIcon={<DonutSmallIcon />}
                        >
                            Generate
                        </Button>
                    </Grid>
                </ValidatorForm>
            </Grid>
            {
                loading ? (
                    <React.Fragment>
                        <svg width={0} height={0}>
                            <defs>
                                <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#e01cd5" />
                                    <stop offset="100%" stopColor="#1CB5E0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <Grid container justifyContent="center" alignItems="center" style={{ height: '45vh' }}>
                            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
                        </Grid>
                    </React.Fragment>
                ) :
                    ((reportData && reportData?.length >= 1) &&
                        <BoxWrapper boxTitle='RCM Report' exportToExcelEnable={true} exportToExcelFn={handleExportExcel}>


                            <div className="responsive-table" style={{ maxHeight: '45vh' }}>
                                <table className="summary-tab-single-year-table-css">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{ position: 'sticky', top: 0, zIndex: 10, width: '6%' }}>Month</th>
                                            <th colSpan={3} style={{ position: 'sticky', top: 0, zIndex: 10 }}>RCM as per books</th>
                                            <th colSpan={3} style={{ position: 'sticky', top: 0, zIndex: 10 }}>GSTR-3B RCM Paid</th>
                                            <th colSpan={3} style={{ position: 'sticky', top: 0, zIndex: 10 }}>ITC Taken from RCM</th>
                                            <th colSpan={3} style={{ position: 'sticky', top: 0, zIndex: 10 }}>Net RCM Balance C/F</th>
                                            <th colSpan={3} style={{ position: 'sticky', top: 0, zIndex: 10 }}>Difference RCM-Books vs GSTR-3B</th>
                                        </tr>
                                        <tr>
                                            {/* <th>Month</th> */}
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9 }}>IGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>CGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, whiteSpace: 'nowrap' }}>SGST</th>

                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, whiteSpace: 'nowrap' }}>IGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>CGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>SGST</th>

                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>IGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>CGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>SGST</th>

                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>IGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>CGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>SGST</th>

                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>IGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, }}>CGST</th>
                                            <th style={{ fontSize: "15px", position: "sticky", top: '40px', zIndex: 9, paddingRight: "4px" }}>SGST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData && reportData?.length > 0 ? (
                                            reportData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.month}</td>
                                                    {/* RCM as per books */}
                                                    <td>{numberFormat(item.gstr2b_integrated_tax)}</td>
                                                    <td>{numberFormat(item.gstr2b_central_tax)}</td>
                                                    <td>{numberFormat(item.gstr2b_state_tax)}</td>

                                                    {/* GSTR-3B RCM Paid */}
                                                    <td>{numberFormat(item.gstr3b_outward_integrated_tax)}</td>
                                                    <td>{numberFormat(item.gstr3b_outward_central_tax)}</td>
                                                    <td>{numberFormat(item.gstr3b_outward_state_tax)}</td>

                                                    {/* ITC Taken from RCM */}
                                                    <td>{numberFormat(item.gstr3b_itc_integrated_tax)}</td>
                                                    <td>{numberFormat(item.gstr3b_itc_central_tax)}</td>
                                                    <td>{numberFormat(item.gstr3b_itc_state_tax)}</td>

                                                    {/* Net RCM Balance C/F */}
                                                    <td>{numberFormat(item.diff_outward_itc_integrated_tax)}</td>
                                                    <td>{numberFormat(item.diff_outward_itc_central_tax)}</td>
                                                    <td>{numberFormat(item.diff_outward_itc_state_tax)}</td>

                                                    {/* Difference RCM-Books vs GSTR-3B */}
                                                    <td>{numberFormat(item.diff_gstr2b_outward_integrated_tax)}</td>
                                                    <td>{numberFormat(item.diff_gstr2b_outward_central_tax)}</td>
                                                    <td>{numberFormat(item.diff_gstr2b_outward_state_tax)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={15}>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </BoxWrapper>)

            }
        </Grid>
    );
}


